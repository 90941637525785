import React, {useEffect, useState} from 'react'

import {Button} from "../../components/common/Button/Button";
import {useFetcher} from "../../components/services/Fetcher/Fetcher";
import {TeamData} from "./Data/TeamData";
import {Card} from "../../components/common/Card/Card";
import {NoData} from "../../components/common/Messages/NoData/NoData";
import {Skeleton} from "../../components/common/Loader/Skeleton/Skeleton";
import {ContextMenu} from "../../components/common/ContextMenu/ContextMenu";
import {Link, useOutletContext} from 'react-router-dom';
import {Dialog} from "../../components/common/Dialog/Dialog";
import {Dropdown} from "../../components/common/Fields/Dropdown/Dropdown";
import DirectFetcher from "../../components/services/Fetcher/DirectFetcher";
import {toast} from 'react-toastify'

export const Teams = props => {

    const [routeData] = useOutletContext();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [teamListData, setTeamListData] = useState([]);

    // Team Data
    const [selectedTeamData, setSelectedTeamData] = useState({});
    const [teamDataVisible, setTeamDataVisible] = useState(false);

    // Export Team Dialog
    const [exportDialogVisible, setExportDialogVisible] = useState(false);
    const [exportProcessing, setExportProcessing] = useState(false);
    const [exportFormat, setExportFormat] = useState('PDF');

    const exportData = () => {

        if(exportFormat) {

            setExportProcessing(true);
            new DirectFetcher().fetch(
                `/teams/export/${String(exportFormat).toLowerCase()}`,
                'GET',
                null,
                true,
                null,
                true,
                false,
                'file'
            ).then(res => {

                if (res !== false) {

                    toast.info('Le squadre sono state esportate')
                }

                setExportProcessing(false);
                setExportDialogVisible(false);
            })
        }
    }

    const closeExportDialog = () => {
        setExportDialogVisible(false)
    }

    const toggleTeamDetail = (row) => {

        if(row) {
            setSelectedTeamData(row);
        }else{
            setSelectedTeamData({
                name: null,
                subscription_fee_amount: null,
                players_count: 0,
                color: null
            })
        }
        setTeamDataVisible(true);
    }

    const deleteTeam = teamToDeleteId => {

        const teamDataTmp = [];

        if(teamListData.length > 0) {

            teamListData.map(team => {
                if(team.team_id !== teamToDeleteId) {
                    teamDataTmp.push(team);
                }
            })
        }

        setTeamListData(teamDataTmp);
    }

    useEffect(() => {

        if(data.length > 0) {
            setTeamListData(data);
        }
    }, [data]);

    useEffect(() => {

        if(teamDataVisible) {
            document.getElementsByTagName('body')[0].classList.add('no-overflow');
        }else{
            document.getElementsByTagName('body')[0].classList.remove('no-overflow');
        }
    }, [teamDataVisible]);

    useEffect(() => {

        if(routeData.appReady) {

            setLoading(true);

            new DirectFetcher().fetch(
                '/teams',
                'GET',
                null,
                true
            ).then(res => {

                if(res !== false) {
                    setData(res);
                }

                setLoading(false);
            })
        }
    }, [routeData.appReady]);

    return (
        <React.Fragment>
            <div className={'wg-pageHeader'}>
                <div className={'wg-pageTitle'}>
                    Squadre
                </div>
                <div className={'wg-pageActions'}>

                    <Button
                        type={'secondary'}
                        icon={'dots_horizontal'}
                        label={'Altro'}
                        hasChildren={true}
                    >
                        <ContextMenu width={230}>
                            <ul>
                                <li>
                                    <Link to={'#'} onClick={() => setExportDialogVisible(true)}>
                                        <i className={'wi-download'} aria-hidden={"true"} /> Esporta Squadre
                                    </Link>
                                </li>
                            </ul>
                        </ContextMenu>
                    </Button>

                    <Button label={'Nuova squadra'} handleClick={toggleTeamDetail} />

                </div>
            </div>

            {loading ?
                <div className={'wg-teamList'}>
                    <Card>
                        <div className={'wg-teamCard'}>
                            <div className={'wg-teamCardJersey'}>

                                <div className={'wg-teamCardJerseyBackground skeleton'}
                                     style={{backgroundColor: ''}}>
                                    <Skeleton type={'avatar'} />
                                </div>
                            </div>
                            <div className={'wg-teamCardInfo skeleton'}>
                                <span className={'wg-teamCardName'}>
                                    <Skeleton />
                                </span>
                                <span className={'wg-teamCardPlayers'}>
                                    <Skeleton />
                                </span>
                            </div>
                        </div>
                    </Card>
                    <Card>
                        <div className={'wg-teamCard'}>
                            <div className={'wg-teamCardJersey'}>

                                <div className={'wg-teamCardJerseyBackground skeleton'}
                                     style={{backgroundColor: ''}}>
                                    <Skeleton type={'avatar'} />
                                </div>
                            </div>
                            <div className={'wg-teamCardInfo skeleton'}>
                                <span className={'wg-teamCardName'}>
                                    <Skeleton />
                                </span>
                                <span className={'wg-teamCardPlayers'}>
                                    <Skeleton />
                                </span>
                            </div>
                        </div>
                    </Card>
                    <Card>
                        <div className={'wg-teamCard'}>
                            <div className={'wg-teamCardJersey'}>

                                <div className={'wg-teamCardJerseyBackground skeleton'}
                                     style={{backgroundColor: ''}}>
                                    <Skeleton type={'avatar'} />
                                </div>
                            </div>
                            <div className={'wg-teamCardInfo skeleton'}>
                                <span className={'wg-teamCardName'}>
                                    <Skeleton />
                                </span>
                                <span className={'wg-teamCardPlayers'}>
                                    <Skeleton />
                                </span>
                            </div>
                        </div>
                    </Card>
                    <Card>
                        <div className={'wg-teamCard'}>
                            <div className={'wg-teamCardJersey'}>

                                <div className={'wg-teamCardJerseyBackground skeleton'}
                                     style={{backgroundColor: ''}}>
                                    <Skeleton type={'avatar'} />
                                </div>
                            </div>
                            <div className={'wg-teamCardInfo skeleton'}>
                                <span className={'wg-teamCardName'}>
                                    <Skeleton />
                                </span>
                                <span className={'wg-teamCardPlayers'}>
                                    <Skeleton />
                                </span>
                            </div>
                        </div>
                    </Card>
                </div>
            : teamListData.length > 0 ?
                <div className={'wg-teamList'}>
                    {teamListData.map((team, i) => {

                        const baseColor = team.color ? team.color : '#121212';
                        const decorationColor = '#123456';

                        return (
                            <Card
                                key={`team_card_${i}`}
                                handleClick={() => toggleTeamDetail(team)}
                            >
                                {!loading ?
                                    <div className={'wg-teamCard'}>
                                        <div className={'wg-teamCardJersey'}>
                                            <svg width="52" height="62" viewBox="0 0 282 333" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M55.8004 310.178L54.415 319.972C54.415 319.972 84.4315 332.285 140.678 332.285C196.924 332.285 227.587 319.972 227.587 319.972L226.202 310.178H55.8004Z"
                                                    fill={decorationColor}/>
                                                <path
                                                    d="M268.553 73.8463C263.29 41.4586 245.563 37.3063 245.563 37.3063L178.994 10.1781L169.946 5.74896L141.232 3.44214L111.964 5.84123L102.916 10.2703L36.3472 37.3985C36.3472 37.3985 18.7125 41.5508 13.3575 73.9386L2.83203 114.723L49.2732 129.671L54.6282 112.14C54.6282 112.14 74.1095 166.95 55.8285 310.065C55.8285 310.065 85.0042 322.153 140.678 322.153C196.352 322.153 226.174 310.065 226.174 310.065C207.893 166.95 227.374 112.14 227.374 112.14L232.729 129.671L279.171 114.723L268.553 73.8463Z"
                                                    fill={baseColor}/>
                                                <path
                                                    d="M178.767 20.1266L172.534 5.77238L141.276 3.44214L109.468 5.77238L103.235 20.1266L131.926 50.4197H150.076L178.767 20.1266Z"
                                                    fill={baseColor}/>
                                                <path
                                                    d="M47.1499 135.236C46.6924 136.598 45.2285 137.325 43.9475 136.871L2.77289 123.706C1.40041 123.252 0.66841 121.799 1.12591 120.528L2.86439 114.899L48.8884 129.607L47.1499 135.236Z"
                                                    fill={decorationColor}/>
                                                <path
                                                    d="M234.853 135.236C235.31 136.598 236.774 137.325 238.055 136.871L279.23 123.706C280.602 123.252 281.334 121.799 280.877 120.528L279.138 114.899L233.114 129.607L234.853 135.236Z"
                                                    fill={decorationColor}/>
                                                <path
                                                    d="M174.876 8.81309C174.876 8.81309 161.106 7.04584 140.171 7.04584C119.236 7.04584 105 8.81309 105 8.81309L110.583 2.58122C110.583 2.58122 120.631 1 140.171 1C159.803 1 169.387 2.58122 169.387 2.58122L174.876 8.81309Z"
                                                    fill={decorationColor}/>
                                                <path
                                                    d="M169.387 2.48821C169.387 2.48821 184.181 14.2078 176.458 26.3925C168.735 38.5772 143.893 58.11 143.893 58.11L140.171 48.5296C140.078 48.5296 182.413 23.6022 169.387 2.48821Z"
                                                    fill={decorationColor}/>
                                                <path
                                                    d="M111.631 2.52101C111.631 2.52101 96.8537 14.166 104.52 26.2732C112.185 38.3803 136.845 57.7887 136.845 57.7887L141.001 48.2694C141.001 48.2694 98.7009 23.5006 111.631 2.52101Z"
                                                    fill={decorationColor}/>
                                                <path
                                                    d="M144.686 66.0789H137.317V51.5091L141.213 48.5774H144.686V66.0789Z"
                                                    fill={decorationColor}/>
                                            </svg>

                                            <div className={'wg-teamCardJerseyBackground'}
                                                 style={team.color ? {backgroundColor: team.color} : null}></div>
                                        </div>
                                        <div className={'wg-teamCardInfo'}>
                                            <span className={'wg-teamCardName'}>{team.name}</span>
                                            <span
                                                className={'wg-teamCardPlayers'}>{`${team.players_count} giocator${team.players_count === 1 ? 'e' : 'i'}`}</span>
                                        </div>
                                    </div>
                                    : null
                                }
                            </Card>
                        )
                    })}
                </div>
                : <NoData />


            }

            {exportDialogVisible ?
                <Dialog
                    title={'Esporta Giocatori'}
                    primaryButton={{
                        label: 'Esporta',
                        disabled: exportProcessing || !exportFormat,
                        loading: exportProcessing,
                        handleClick: exportData
                    }}
                    secondaryButton={{
                        label: 'Annulla',
                        handleClick: () => setExportDialogVisible(false)
                    }}
                    dialogVisible={exportDialogVisible}
                    setDialogVisible={closeExportDialog}
                    width={300}
                >
                    <Dropdown
                        label={'Scegli il formato'}
                        items={[
                        {
                            key: 'pdf',
                            value: 'PDF'
                        },
                        {
                            key: 'excel',
                            value: 'Excel'
                        }
                    ]}
                        resetSelection={false}
                        filterable={false}
                        defaultValue={exportFormat}
                        placeholder={'Scegli'}
                        handleChange={selectedItem => setExportFormat(selectedItem.value)}
                    />
                </Dialog>
                :
                null
            }

            <TeamData
                data={selectedTeamData}
                listData={teamListData}
                setListData={setTeamListData}
                visible={teamDataVisible}
                setVisible={setTeamDataVisible}
                deleteTeam={deleteTeam}
            />
        </React.Fragment>
    );
}