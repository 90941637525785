import React, {useEffect} from 'react'
import {Textfield} from "../../../../components/common/Fields/Textfield/Textfield";
import FieldValidation from "../../../../components/utility/Validation/Validation";
import {Dropdown} from "../../../../components/common/Fields/Dropdown/Dropdown";
import CodiciCatastali from "../../../../components/utility/CodiciCatastali/CodiciCatastali";
import {Badge} from "../../../../components/common/Badge/Badge";

export const PlayerHealth = (props) => {

    const {
        data: playerData = {},
        setData: setPlayerData,
        savePlayerData,
        fieldsErrors,
        setFieldsErrors
    } = props;

    const getExpirationYears = () => {
        const max = new Date().getFullYear() - 1
        const min = max + 2

        const years = [];

        for(let i = max; i <= min; i++ ) {
            years.push(i);
        }

        return years;
    }

    const handleChange = event => {

        if(event) {
            const {name, value} = event.target;

            let validation = '';

            if(value && event.target.getAttribute('data-validation')) {
                validation = FieldValidation.field(event.target.getAttribute('data-validation'), value);
            }else{
                validation = {value: value, error: null};
            }

            setFieldsErrors(oldFieldErrors => ({
                ...oldFieldErrors,
                [name]: validation.error
            }))

            setPlayerData(playerDataOld => ({
                ...playerDataOld,
                [name]: value
            }))
        }
    }

    return(
        <React.Fragment>
            <div className={'wg-detailPanelDataFieldsContainer'}>

                <div className={'wg-detailPanelDataFieldsContainerTitle'}>Scadenza certificato medico</div>

                <div className={'wg-detailPanelDataFields'}>
                    <div className={'wg-detailPanelDataFieldsRow'}>
                        <div className={'wg-detailPanelDataFieldsRowThree'}>
                            <Textfield
                                id={'certificate_expiration'}
                                required={true}
                                fill={true}
                                label={false}
                                placeholder={'gg/mm/aaaa'}
                                defaultValue={playerData.certificate_expiration}
                                error={fieldsErrors.certificate_expiration}
                                maxLength={10}
                                validationType={'date'}
                                handleChange={e => handleChange(e)}
                                handleKeyDown={savePlayerData}
                            />
                            <div style={{width: '100%'}}/>
                        </div>
                    </div>

                    {/*<div>*/}
                    {/*    {*/}
                    {/*        newCertificateExpiration.day && newCertificateExpiration.month && newCertificateExpiration.year ?*/}
                    {/*            <Badge*/}
                    {/*                status={'positive'}*/}
                    {/*                label={'Data del certificato valida'}*/}
                    {/*            />*/}
                    {/*        :*/}
                    {/*            <Badge*/}
                    {/*                status={'negative'}*/}
                    {/*                label={'Data del certificato non valida'}*/}
                    {/*            />*/}
                    {/*    }*/}
                    {/*</div>*/}
                </div>
            </div>
        </React.Fragment>
    )
}