import React from 'react'

export const Tab = (props) => {

    const {
        items = [],
        active = 0,
        handleClick
    } = props;

    const handleTabClick = (tabIndex) => {

        if(handleClick) {
            handleClick(tabIndex);
        }
    }

    return(
        <React.Fragment>
            <div className={'wg-tabContainer'}>
                {items.length > 0 ?
                    items.map((tab, tabIndex) => {

                      return(
                          <div className={`wg-tab ${tabIndex === active ? 'active' : ''}`}
                               key={`tab_${tabIndex}`}
                               onClick={() => handleTabClick(tabIndex)}
                          >
                              {tab}
                          </div>
                      )
                    })
                :
                    null
                }
            </div>
        </React.Fragment>
    )
}