import {useEffect, useState} from 'react'
import Cookies from "../../utility/Cookies/Cookies";
import {toast} from "react-toastify";
import CookieManager from "../../utility/Cookies/Cookies";
import DirectFetcher from "./DirectFetcher";



export const useFetcher = (url, method = 'GET', body = null, season = false, teamId = null, appReady = false) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    //const [error, setError] = useState(null);

    let fetchProperties = {
        url: process.env.REACT_APP_API_BASE_PATH + '/WeasyGoal/api/public' + url,
        options: {
            'method': method,
            'headers': {
                'Content-Type': 'application/json',
                //'X-Authorization': Cookies.getCookie('LOGGEDIN'),
            },
            credentials: 'include'
        }
    }

    if(body) {
        fetchProperties.body = JSON.stringify(body);
    }

    if(season) {
        fetchProperties.options.headers['X-Season'] = localStorage.getItem("season");
    }

    if(teamId) {
        fetchProperties.options.headers['X-TeamId'] = teamId;
    }

    useEffect(() => {

        if(Cookies.getCookie('LOGGEDIN')) {

            setLoading(true)
            fetch(fetchProperties.url, fetchProperties.options )
                .then(res => {
                    return res.json()
                })
                .then(data => {

                    if(data.message) {
                        toast.error(data.message);
                        return false;
                    }

                    setData(data);
                })
                .catch(err => {
                    toast.error(err.message);
                })
                .finally(() => setLoading(false))
        }

    }, [fetchProperties.url]);

    return [data, loading]
}