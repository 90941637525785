import React, {useEffect, useRef, useState} from "react"
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {Button} from "../../Button/Button";

export const FileUploader = props => {

    const {
        children,
        width = null,
        multiple = false,
        supported = ["*"],
        maxSize = null,
        fileUploadProgress = 0,
        handleChange = null,
        handleDeletedFile = null
    } = props;

    const [files, setFiles] = useState(null);

    const inputFileRef = useRef();

    const checkFileRequirements = (files) => {

        if(files) {
            if(files.length > 0) {

                let wrongFiles = 0;

                Array(files).map(file => {

                    if((file[0].size) / 1024 > Number(maxSize)) {
                        wrongFiles += 1;
                    }
                })

                if(wrongFiles <= 0) {
                    return true;
                }else{
                    toast.error("Il file è troppo grande");
                    return false;
                }
            }
        }
    }

    const handleDragOver = event => {

        if(event) {
            event.preventDefault();
            event.currentTarget.classList.add("onDrop");
        }
    }

    const handleDragLeave = event => {

        if(event) {
            event.preventDefault();
            event.currentTarget.classList.remove("onDrop");
        }
    }

    const handleDrop = event => {

        if(event) {
            event.preventDefault(event.dataTransfer.files);

            if(checkFileRequirements(event.dataTransfer.files)) {
                setFiles(event.dataTransfer.files);

                if(handleChange) {
                    handleChange(event.dataTransfer.files);
                }
            }

            event.currentTarget.classList.remove("onDrop");
        }
    }

    const onChange = event => {

        if(event) {

            if(checkFileRequirements(event.target.files)) {

                setFiles(event.target.files);

                if(handleChange) {
                    handleChange(event.target.files);
                }
            }
        }
    }

    const deleteFile = () => {
        setFiles(null);

        if(handleDeletedFile) {
            handleDeletedFile();
        }

    }

    useEffect(() => {
        setFiles(null);
    }, []);

    return(
        <React.Fragment>
            <div className={"wg-fileUploader"}>
                { !files || multiple ?
                    <React.Fragment>
                        <div
                            className={"wg-fileUploaderArea"}
                            onClick={() => inputFileRef.current.click()}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            style={width ? {width: width} : null}
                        >
                            <input
                                type={"file"}
                                hidden={true}
                                multiple={multiple}
                                accept={supported && supported.length > 0 ? supported.map(s => "." + s + ",") : "*"}
                                onChange={e => onChange(e)}
                                ref={inputFileRef}
                            />
                            <i className={"wi-import"} aria-hidden={true} />
                            <span><Link to={"#"} className={"wg-decoration"} onClick={() => inputFileRef.current.click()}>Scegli un file</Link> o rilasciane uno qui</span>
                        </div>

                        <div className={"wg-fileUploaderMetaData"}>
                            <div className={"wg-supportedExtensions"}>
                                Formati supportati: <span>{supported.length > 0 ? supported.map((s, i) => i !== supported.length - 1 ? s + ", " : s) : null}</span>
                            </div>
                            <div className={"wg-maxSize"}>
                                {maxSize ? "Dimensioni massime: " + (Number(maxSize).toFixed(2) / 1000) + "MB" : null}
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    null
                }

                {files && files.length > 0 ?
                    <div className={"wg-fileList"}>
                        {Array(files).map((file, index) => {

                            return(
                              <React.Fragment key={`file_${index}`}>
                                  <div className={"wg-file"}>
                                      <div className={"wg-title"}>
                                          <div className={"wg-fileHeader"}>
                                              <i className={"wi-document"} aria-hidden={"true"} />
                                              <div className={"wg-fileMeta"}>
                                                  <span className={"wg-fileName"}>{file[0].name}</span>
                                                  <span className={"wg-fileSize"}>{(file[0].size / 1024 ** 2).toFixed(3)} MB</span>
                                              </div>
                                          </div>

                                          <div className={"wg-deleteFile"}>
                                              <Button
                                                  type={"icon"}
                                                  size={"small"}
                                                  label={false}
                                                  icon={"delete"}
                                                  handleClick={deleteFile}
                                              />
                                          </div>
                                      </div>
                                      {/*<LineChart progress={fileUploadProgress} />*/}
                                  </div>
                              </React.Fragment>
                            );
                        })}
                    </div>
                :
                    null
                }

                <div className={"wg-fileUploaderAreaChildren"}>
                    {children}
                </div>
            </div>
        </React.Fragment>
    )
}