import React, {useState} from "react"

export const Textfield = (props) => {

    const {
        id= null,
        type= "text",
        size=null,
        disabled= false,
        label= "Label",
        defaultValue= "",
        placeholder = "",
        maxLength= 64,
        required = false,
        fill = false,
        tabIndex = null,
        error= false,
        hidden= false,
        action= {},
        validationType = null,
        handleChange = null,
        handleKeyDown = null
    } = props;

    const [data, setData] = useState(defaultValue);

    const checkData = (event) => {

        if(event) {
            if(handleChange) {

                if(validationType === 'amount') {

                    const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    const removeNonNumeric = num => num.toString().replace(/[^0-9.]/g, '');

                    let formattedAmount = addCommas(removeNonNumeric(event.target.value));

                    if((/\.(\d){3}/g).test(formattedAmount)) {
                        formattedAmount = formattedAmount.substring(0, formattedAmount.length - 1);
                    }

                    event.target.value = formattedAmount;
                }

                if(validationType === 'text') {
                    event.target.value = event.target.value.replace(' ', '');
                }

                handleChange(event);
            }
        }
    }

    const checkKeyPressed = event => {

        if(!disabled) {
            if(event) {
                if(event.key) {
                    if(event.key === "Enter") {
                        if(handleKeyDown) {
                            handleKeyDown(event);
                        }
                    }
                }
            }

            if(validationType === 'date') {

                if(event.key === '/') {
                    event.target.value = event.target.value.substring(0, event.target.value.length - 1);
                }

                if(event.key !== 'Backspace') {
                    if(event.target.value.length === 2 || event.target.value.length === 5) {
                        event.target.value = event.target.value + '/'
                    }
                }
            }
        }
    }

    return (
        <React.Fragment>
            <div className={`wg-textfield ${disabled ? "disabled" : ""} ${size} ${error ? 'invalid' : ''} ${hidden ? 'hidden' : ''}`}
                 style={fill ? {width: '100%'} : null}
            >
                {label ? <label htmlFor={id}>{label} {required ? <span className={'wg-required'}>*</span> : null}</label> : null}
                <input
                    id={id}
                    name={id}
                    type={type}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    tabIndex={tabIndex}
                    disabled={disabled}
                    value={defaultValue ? defaultValue : ''}
                    data-validation={validationType}
                    onChange={
                        !disabled ?
                            (handleChange && typeof handleChange === 'function' ? event => checkData(event) : e => setData(e.target.value))
                            :
                            null
                    }
                    onKeyDown={e => checkKeyPressed(e)}
                />

                {action ?
                    <div className={`wg-textfieldAction ${action.disabled ? 'disabled' : ''}`}
                         onClick={action.handleClick ? action.handleClick : null}
                    >
                        <i className={`wi-${action.icon}`} aria-hidden={true} />
                    </div>
                :
                    null
                }
                {error ? <span className={"wg-error"}>{error}</span> : null}
            </div>
        </React.Fragment>
    )
}