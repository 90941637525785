import React, {useEffect, useState} from 'react';

// Import refs
import {Dialog} from '../../components/common/Dialog/Dialog';
import {FileUploader} from '../../components/common/Fields/FileUploader/FileUploader';
import {Button} from '../../components/common/Button/Button';
import {Link, useOutletContext} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {DataGrid} from '../../components/common/DataGrid/DataGrid';
import {Section} from '../../components/common/Section/Section';
import {ContextMenu} from "../../components/common/ContextMenu/ContextMenu";
import DirectFetcher from "../../components/services/Fetcher/DirectFetcher";
import {toast} from 'react-toastify'

import ImportFileTemplate from '../../assets/files/giocatori.xlsx'
import {PlayerData} from "./PlayerData/PlayerData";
import {Dropdown} from "../../components/common/Fields/Dropdown/Dropdown";

export const Registry = props => {

    const [routeData] = useOutletContext();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [teams, setTeams] = useState([]);

    const [playerListData, setPlayerListData] = useState([]);
    const [dropdownTeams, setDropdownTeams] = useState([]);

    const [refreshData, setRefreshData] = useState(0);

    const [playersToDelete, setPlayersToDelete] = useState([]);

    const [importDialogVisible, setImportDialogVisible] = useState(false);
    const [importDialogPrimaryDisabled, setImportDialogPrimaryDisabled] = useState(true);

    const [exportDialogVisible, setExportDialogVisible] = useState(false);
    const [exportProcessing, setExportProcessing] = useState(false);
    const [exportFormat, setExportFormat] = useState('PDF');

    const [importedFileErrorMessage, setImportedFileErrorMessage] = useState(null);
    const [importedFileErrorList, setImportedFileErrorList] = useState(null);
    const [activeErrorsArea, setActiveErrorsArea] = useState(false);

    const [importFileProcessing, setImportFileProcessing] = useState(false);
    const [fileToImport, setFileToImport] = useState(null);

    const [sendEmailProcessing, setSendEmailProcessing] = useState(false);

    // Player Data
    const [selectedPlayerData, setSelectedPlayerData] = useState({});
    const [playerDataVisible, setPlayerDataVisible] = useState(false);

    const { t, i18n } = useTranslation();

    const downloadTemplateFile = () => {

        const linkDOM = document.createElement('a');

        linkDOM.id = 'fakeLinkTemplateDownload'
        linkDOM.href = ImportFileTemplate;
        linkDOM.target = '_blank'
        linkDOM.download = 'WeasyGoal - Template giocatori'

        linkDOM.click();
        linkDOM.remove();
    }

    const getFile = files => {

        setImportDialogPrimaryDisabled(false);
        setFileToImport(files[0]);
    }
    const checkImportedFile = file => {

        if(file) {

            const importFormData = new FormData();

            importFormData.append('players', file, 'players');

            setImportFileProcessing(true)

            new DirectFetcher().fetch(
                '/players/import',
                'POST',
                importFormData,
                true,
                null,
                true,
                true
            ).then(res => {

                if(res.errors) {

                    setImportedFileErrorMessage("Il file sembra non essere corretto")
                    setImportedFileErrorList(res.errors);
                    setImportDialogPrimaryDisabled(true);

                }else{
                    if(res !== false) {
                        setImportDialogPrimaryDisabled(false);
                        toast.success('Giocatori importati con successo');
                        setImportDialogVisible(false);
                        refreshPlayerList();
                    }
                }

                setImportFileProcessing(false);
            })

        }
    }

    const refreshPlayerList = () => {

        new DirectFetcher().fetch(
            '/players',
            'POST',
            null,
            true,
            ).then(res => {
                if(res) {
                    if(res.length > 0) {
                        setPlayerListData(res);
                    }
                }
        })
    }

    const closeDialog = () => {
        setImportedFileErrorList(null);
        setImportedFileErrorMessage(null);
        setImportDialogVisible(false)
        setActiveErrorsArea(false);

    }

    const deletedFile = file => {
        setImportedFileErrorMessage(null);
        setImportedFileErrorList(null);
        setImportDialogPrimaryDisabled(true);
    }

    const togglePlayerDetail = (row) => {

        if(row) {
            setSelectedPlayerData(row);
        }else{
            setSelectedPlayerData({
                player_id: null,
                address: null,
                birth_date: null,
                birth_place: null,
                birth_province: null,
                certificate_expiration: null,
                city: null,
                email: null,
                father_name: null,
                father_surname: null,
                father_phone_number: null,
                father_email: null,
                fiscal_code: null,
                gender: null,
                identifier: null,
                legal_guardian_name: null,
                legal_guardian_surname: null,
                legal_guardian_phone_number: null,
                legal_guardian_email: null,
                mother_name: null,
                mother_surname: null,
                mother_phone_number: null,
                mother_email: null,
                name: null,
                nationality: null,
                phone_number: null,
                photo: null,
                postal_code: null,
                province: null,
                surname: null
            })
        }
        setPlayerDataVisible(true);
    }

    const sendCertificateReport = () => {

        if(!sendEmailProcessing) {

            setSendEmailProcessing(true);

            new DirectFetcher().fetch(
                '/players/certificate/expiring/sendmail',
                'GET'
            ).then(res => {

                if(res !== false) {
                    toast.info('Abbiamo inviato il report all\'e-mail dell\'amministratore');
                }

                setSendEmailProcessing(false);
            })
        }else{
            toast.error('E\' in corso un\'altra richiesta');
        }
    }

    const closeExportDialog = () => {
        setExportDialogVisible(false)
    }

    const exportData = () => {

        if(exportFormat) {

            setExportProcessing(true);
            new DirectFetcher().fetch(
                `/players/certificate/export/${String(exportFormat).toLowerCase()}`,
                'GET',
                null,
                true,
                null,
                true,
                false,
                'file'
            ).then(res => {

                if (res !== false) {

                    toast.info('I giocatori sono stati esportati')
                }

                setExportProcessing(false);
                setExportDialogVisible(false);
            })
        }
    }

    const deleteRowsData = (idsToDelete) => {

        new DirectFetcher().fetch(
            '/players/multiple-delete',
            'POST',
            {
                playerIds: idsToDelete
            },
            true
        ).then(res => {

            if(res !== false) {

                toast.success('Giocatori eliminati con successo')
            }
        })
    }

    useEffect(() => {

        if(routeData.appReady) {

            setLoading(true);

            new DirectFetcher().fetch(
                '/players',
                'POST',
                null,
                true
            ).then(res => {

                if(res !== false) {

                    setData(res);
                }


                setLoading(false);
            })

            new DirectFetcher().fetch(
                '/teams',
                'GET',
                null,
                true
            ).then(res => {

                if(res !== false) {

                    setTeams(res);
                }
            })
        }
    }, [routeData.appReady]);

    useEffect(() => {

        if(data.length > 0) {
            setPlayerListData(data);
        }else{
            setPlayerListData([]);
        }

    }, [data]);

    useEffect(() => {
        if(teams.length > 0) {

            const teamList = [];

            teams.map(team => {
                teamList.push({
                    key: team.team_id,
                    value: team.name
                });
            })

            setDropdownTeams(teamList);
        }
    }, [teams]);

    useEffect(() => {

        if(importDialogVisible || playerDataVisible) {
            document.getElementsByTagName('body')[0].classList.add('no-overflow');
        }else{
            document.getElementsByTagName('body')[0].classList.remove('no-overflow');
        }
    }, [importDialogVisible, playerDataVisible]);

    useEffect(() => {

        if(!activeErrorsArea) {
            setImportedFileErrorMessage(null);
            setImportedFileErrorList(null);
        }
    }, [activeErrorsArea]);

    useEffect(() => {

        if(playerListData.length > 0 && playersToDelete.length > 0) {

            const updatedPlayerListData = [];

            playerListData.map(player => {

                let playerToDeleteCheck = false;

                playersToDelete.map(playerIdToDelete => {

                    if(player.player_id === playerIdToDelete) {
                        playerToDeleteCheck = true;
                    }
                })

                if(!playerToDeleteCheck) {
                    updatedPlayerListData.push(player);
                }
            })

            setPlayerListData(updatedPlayerListData);
        }
    }, [playersToDelete]);

    return(
        <React.Fragment>

            <div className={'wg-pageHeader'}>
                <div className={'wg-pageTitle'}>
                    Anagrafica
                </div>
                <div className={'wg-pageActions'}>

                    <Button
                        type={'secondary'}
                        icon={'dots_horizontal'}
                        label={'Altro'}
                        hasChildren={true}
                    >
                        <ContextMenu width={230}>
                            <ul>
                                <li>
                                    <Link to={'#'} onClick={() => setExportDialogVisible(true)}>
                                        <i className={'wi-download'} aria-hidden={"true"} /> Esporta Giocatori
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'#'} onClick={() => setImportDialogVisible(!importDialogVisible)}>
                                        <i className={'wi-import'} aria-hidden={"true"} /> Importa Giocatori
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'#'} onClick={sendCertificateReport} className={`wg-contextMenuItemProcessing ${sendEmailProcessing ? 'loading' : ''}`}>
                                        <i className={`${sendEmailProcessing ? 'wi-soccer_ball' : 'wi-send_mail'}`} aria-hidden={"true"} /> Invia report certificati
                                    </Link>
                                </li>
                            </ul>
                        </ContextMenu>
                    </Button>

                    <Button label={'nuovo giocatore'} handleClick={togglePlayerDetail} />
                </div>
            </div>

            <Section
                title={'Giocatori'}
                counter={playerListData ? playerListData.length : false}
            >
                <DataGrid
                    columns={[
                        {
                            name: 'checkbox',
                            label: '',
                            type: 'checkbox'
                        },
                        {
                            name: 'photo',
                            label: '',
                            type: 'avatar'
                        },
                        {
                            name: 'name',
                            label: 'Nome',
                            type: 'group',
                            groupKeys: {
                                title: ['surname', 'name'],
                                description: ['identifier']
                            }
                        },
                        {
                            name: 'birth_date',
                            label: 'Data di Nascita',
                            type: 'date'
                        },
                        {
                            name: 'city',
                            label: 'Residenza',
                            type: 'group',
                            groupKeys: {
                                title: ['city'],
                                description: ['province', 'nationality']
                            }
                        },
                        {
                            name: 'certificate_expiration',
                            label: 'Scadenza certificato',
                            type: 'badge',
                            validation: {
                                type: 'date',
                                range: 30
                            }
                        },
                        {
                            name: 'team_names',
                            label: 'Squadre',
                            type: 'text'
                        }
                    ]}
                    filters={
                        {
                            columnsToFilter: ['name', 'surname', 'birth_date', 'city', 'certificate_expiration', 'team_names'],
                            filterByDropdown: {
                                key: 'team_names',
                                values: dropdownTeams
                            },
                            filterBySwitch: {
                                key: 'certificate_expiration',
                                label: 'Solo certificati in scadenza',
                                argument: 'state',
                                validation: {
                                    type: 'date',
                                    range: 30
                                }
                            }
                        }
                    }
                    refreshData={refreshData}
                    sortByKey={'surname'}
                    data={playerListData}
                    loading={loading}
                    idKey={'player_id'}
                    skeletonKeys={6}
                    rowsToDelete={playersToDelete}
                    setRowsToDelete={setPlayersToDelete}
                    deleteRowsCallback={deleteRowsData}
                    noData={{
                        title: 'No players found',
                        description: 'It seems there are no players in this football club'
                    }}
                    handleRowClick={togglePlayerDetail}
                />
            </Section>

            <PlayerData
                data={selectedPlayerData}
                listData={playerListData}
                setRefreshData={setRefreshData}
                setListData={setPlayerListData}
                visible={playerDataVisible}
                setVisible={setPlayerDataVisible}
                setPlayersToDelete={setPlayersToDelete}
            />

            {importDialogVisible ?
                <Dialog
                    title={'Importa giocatori'}
                    primaryButton={{
                        label: 'Importa',
                        visible: !activeErrorsArea,
                        disabled: importDialogPrimaryDisabled,
                        loading: importFileProcessing,
                        handleClick: () => checkImportedFile(fileToImport)
                    }}
                    secondaryButton={{
                        label: 'Annulla',
                        visible: !activeErrorsArea,
                        handleClick: closeDialog
                    }}
                    tertiaryButton={{
                        label: 'Riprova',
                        visible: activeErrorsArea,
                        handleClick: () => setActiveErrorsArea(false)
                    }}
                    dialogVisible={importDialogVisible}
                    setDialogVisible={closeDialog}
                    width={630}
                >
                    {!activeErrorsArea ?
                        <FileUploader
                            supported={['xls', 'xlsx', 'csv']}
                            maxSize={'1000'}
                            fileUploadProgress={0}
                            handleChange={getFile}
                            handleDeletedFile={deletedFile}
                        >
                            {importedFileErrorMessage ?
                                <div className={'wg-importError'}>
                                    <span>{importedFileErrorMessage}</span>
                                    <Link to={'#'} className={'wg-decoration'} onClick={() => setActiveErrorsArea(true)}>Verifica errori</Link>
                                </div>
                                :
                                null
                            }

                            <div className={'wg-importTemplate'}>
                                <div className={'wg-leftSide'}>
                                    <div className={'wg-title'}>
                                        <i className={'wi-excel'}>
                                            <span className={'path1'} />
                                            <span className={'path2'} />
                                            <span className={'path3'} />
                                            <span className={'path4'} />
                                            <span className={'path5'} />
                                            <span className={'path6'} />
                                            <span className={'path7'} />
                                            <span className={'path8'} />
                                            <span className={'path9'} />
                                            <span className={'path10'} />
                                        </i>
                                        Template per l'import dei giocatori
                                    </div>
                                    <div className={'wg-description'}>
                                        Scarica il template in allegato e utilizzalo come base per gestire i tuoi giocatori.
                                    </div>
                                </div>

                                <div className={'wg-rightSide'}>
                                    <Button
                                        type={'secondary'}
                                        label={'Scarica'}
                                        handleClick={downloadTemplateFile}
                                    />
                                </div>
                            </div>
                        </FileUploader>
                    :
                        <div className={'wg-importedFileErrors'}>
                            Il file sembra non essere corretto

                            <div className={'wg-importedFileErrorsList'}>
                                {importedFileErrorList && importedFileErrorList.length > 0 ?
                                    importedFileErrorList.map((error, errorIndex) => {
                                        return (
                                            <div className={'wg-importErrorLine'} key={`error_${errorIndex}`}>
                                                <span>{error.reason}</span>
                                                <span>{error.row ? `Riga${error.row}` : null}</span>
                                            </div>
                                        )
                                    })
                                    :
                                    null
                                }
                            </div>
                        </div>
                    }
                </Dialog>
            :
                null
            }

            {exportDialogVisible ?
                <Dialog
                    title={'Esporta Giocatori'}
                    primaryButton={{
                        label: 'Esporta',
                        disabled: exportProcessing || !exportFormat,
                        loading: exportProcessing,
                        handleClick: exportData
                    }}
                    secondaryButton={{
                        label: 'Annulla',
                        handleClick: () => setExportDialogVisible(false)
                    }}
                    dialogVisible={exportDialogVisible}
                    setDialogVisible={closeExportDialog}
                    width={300}
                >
                    <Dropdown
                        label={'Scegli il formato'}
                        items={[
                            {
                                key: 'pdf',
                                value: 'PDF'
                            },
                            {
                                key: 'excel',
                                value: 'Excel'
                            }
                        ]}
                        resetSelection={false}
                        filterable={false}
                        defaultValue={exportFormat}
                        placeholder={'Scegli'}
                        handleChange={selectedItem => setExportFormat(selectedItem.value)}
                    />
                </Dialog>
                :
                null
            }
        </React.Fragment>
    );
}