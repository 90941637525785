import React, {useEffect, useState} from "react"

export const Switch = props => {

    const {
        id= null,
        label = "Label",
        disabled = false,
        checked= false,
        tabIndex = null,
        fill = false,
        handleChange = null
    } = props;

    const [checkedState, setCheckedState] = useState(false);

    const onChange = (event) => {

        if(event) {
            setCheckedState(event.target.checked);
        }

        if(handleChange) {
            if(event) {
                handleChange(event);
            }else{
                handleChange();
            }
        }
    }

    useEffect(() => {
        setCheckedState(checked);
    }, [checked]);

    return(
        <React.Fragment>
            <div className={`wg-switch ${disabled ? "disabled" : ""}`}
                 style={fill ? {width: '100%'} : null}
            >
                <div className={"wg-checkArea"}>
                    <input
                        type={"checkbox"}
                        id={id}
                        checked={checkedState}
                        disabled={disabled}
                        tabIndex={tabIndex}
                        onChange={e => onChange(e)}
                    />

                </div>
                {label ? <label htmlFor={id}>{label}</label> : null}
            </div>
        </React.Fragment>
    );
}