class Validation {

    static field(type, value, event) {

        let pattern, error, errorText = null;

        if(type) {

            switch(type) {
                case "text":
                    pattern = /^[a-zA-Z]+$/;
                    errorText = "Sono ammesse solo lettere";
                    break;
                case "date":
                    pattern = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
                    errorText = "Data non valida: gg/mm/aaaa";
                    break;
                case "email":
                    pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
                    errorText = "L'email non è valida";
                    break;
                case "zip":
                    pattern = /^(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)$/;
                    errorText = "Codice postale non valido"
                    break;
                case "numeric":
                    pattern = /^[1-9]\d*(,\d+)?$/;
                    errorText = "Sono ammessi solo numeri"
                    break;
                case "phone":
                    pattern = /^[1-9]\d*(,\d+)?$/;
                    errorText = "Numero di telefono non valido";
                    break;
                case "fiscal_code":
                    pattern = /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/;
                    errorText = "Codice fiscale non valido";
                    break;
                default:
                    pattern = "";
            }

            if(pattern) {
                if(!pattern.test(value)) {
                    error = errorText;
                }
            }

        }

        return {value, error};
    }

    static format(type, value, event) {
        if(type && value) {
            switch(type) {
                case "date":
                    if(event) {
                        if(event.keyCode !== 46) {
                            if(value === 2) {
                                value = value + "/";
                            }
                            if(value === 5) {
                                value = value + "/";
                            }
                        }
                    }
                    break;
                default:
                    value = value;
                    break;
            }

            return {value};
        }
    }
}

export default Validation;