import React, {useEffect, useState} from 'react';

export const LineChart = props => {

    const {
        type = 'line',
        data
    } = props;

    const [totalData, setTotalData] = useState(0);

    useEffect(() => {


        if(type === 'line') {

            setTotalData(data.payments_count);
        }
    }, [data]);

    return(
        <React.Fragment>

            <div className={'wg-lineChartContainer'}>

                <div className={'wg-lineChart'}>
                    {
                        type === 'progress' ?
                            <div className={'wg-line'} style={{width: `${((parseFloat (data.amount) / parseFloat(data.total)) * 100)}%`}} />
                        : null
                    }
                    {
                        type === 'line' ?

                            data.types ?
                                Object.keys(data.types).length > 0 ?
                                    Object.keys(data.types).map((type, index) => {

                                        const item = data.types[type];
                                        return (
                                                <div className={`wg-line wg-lineChart_${index} ${item.percentage ? 'minWidth' : ''}`}
                                                     style={{width: `${((parseFloat (item.percentage) / totalData) * 100)}%`}}
                                                     key={`wg_widgetLegend_${type}`}
                                                />
                                            )
                                    })
                                    : null
                                : null
                        : null
                    }
                </div>
            </div>
        </React.Fragment>
    )
}