import React, {useEffect, useState} from 'react';
import {PrivateRoutes} from '../components/routes/PrivateRoutes';
import {Routes, Route, Navigate} from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify';


// Routes
import {ChangeEmail} from "./User/Settings/Security/Reset/ChangeEmail";
import {Home} from './Home/Home';
import {IncomeExpense} from "./Finance/Income-Expense/Income-Expense";
import {Login} from './Login/Login';
import {NotFound} from './NotFound/NotFound';
import {Registry} from './Registry/Registry';
import {ResetPassword} from "./User/Settings/Security/Reset/ResetPassword";
import {NewUser} from "./User/NewUser/NewUser";
import {Staff} from "./Society/Staff";
import {Settings} from "./User/Settings/Settings";
import {Teams} from "./Society/Teams";

// Third Party
import "react-toastify/dist/ReactToastify.min.css";
import Cookies from "../components/utility/Cookies/Cookies";

let checkedLoginStatus = false;


export const App = () => {

    const [appReady, setAppReady] = useState(false);
    const [layoutMode, setLayoutMode] = useState(null);
    const [theme, setTheme] = useState('light');

    async function updateLoginToken() {

        const currentPathname = window.location.pathname;

        if(currentPathname !== '/login' && currentPathname !== '/staff-new-user/') {
            if(!Cookies.getCookie('LOGGEDIN') && !checkedLoginStatus) {
                checkedLoginStatus = true;


                const loginResponse = await fetch(
                    process.env.REACT_APP_API_BASE_PATH + '/WeasyGoal/api/public/user/remember-me-login', {
                        method: 'POST',
                        credentials: 'include'
                    })

                const loginJson = await loginResponse.json();

                setAppReady(true);

                if(!loginResponse.ok || !loginJson.success) {
                    window.location.href = '/login'
                }
            }
        }

        if(Cookies.getCookie('LOGGEDIN')) {
            setAppReady(true);
        }
    }

    useEffect(() => {
        updateLoginToken();
    }, []);

    return(
        <div
            className={
            `wg-app 
            ${!layoutMode ? 
                'wg-layout-default wg-spacing-default wg-font-default wg-radius-default' 
                : 
                'wg-layout-compact wg-spacing-compact wg-font-compact wg-radius-compact'
            }
            ${
             theme ? 'wg-theme-'+theme : 'wg-theme-light'
            }
            `}
            id={'wg-app'}>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/change-email' element={<ChangeEmail />} />
                <Route path='/reset-password' element={<ResetPassword />} />
                <Route path='/staff-new-user' element={<NewUser />} />
                <Route element={<PrivateRoutes
                    appReady={appReady}
                    layoutMode={layoutMode}
                    setLayoutMode={setLayoutMode}
                    theme={theme}
                    setTheme={setTheme}
                />}>
                    <Route path='/' element={<Navigate to={'/home'} />} />
                    <Route path='/home' element={<Home />} />
                    <Route path='/registry' element={<Registry />} />
                    <Route path='/income_expense' element={<IncomeExpense />} />
                    <Route path='/staff' element={<Staff />} />
                    <Route path='/settings' element={<Settings />} />
                    <Route path='/teams' element={<Teams />} />
                    <Route path='*' element={<NotFound />} />
                </Route>
            </Routes>

            <ToastContainer
                position='bottom-center'
                autoClose={4000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                icon={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='colored'
                transition={Slide}
                className={'wg-toastContainer'}
            />
        </div>
    )
}