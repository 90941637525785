import codiciCatastali from "./codici_catastali.json";

class CodiciCatastali {

    static getProvinces(abroad = false, checkProvinces = false) {
        const provinceList = [];

        if(codiciCatastali.length > 0) {

            if(abroad) {
                codiciCatastali.map(provinces => {
                    if(provinces && Object.keys(provinces).length > 0) {

                        Object.keys(provinces).map((province, cityKey) => {

                            if(province === "EE") {
                                provinces[province].map(state => {
                                    provinceList.push({
                                        key: state[0],
                                        value: state[0]
                                    });
                                })
                            }
                        })
                    }
                })
            }else{
                codiciCatastali.map(provinces => {
                    if(provinces && Object.keys(provinces).length > 0) {

                        Object.keys(provinces).map((province, cityKey) => {

                            if(province !== "EE") {
                                provinceList.push({
                                    key: province,
                                    value: province
                                });
                            }
                        })
                    }
                })
            }
        }

        return provinceList;
    }

    static getCities(selectedProvince, dropdownKey = "birth_place") {

        let citiesList = [];

        if(codiciCatastali.length > 0) {
            codiciCatastali.map((provinces, provinceKey) => {
                if(provinces && Object.keys(provinces).length > 0) {
                    Object.keys(provinces).map(provinceToCheck => {
                        if(provinceToCheck === selectedProvince) {
                            provinces[selectedProvince].map(city => {

                                citiesList.push({
                                    key: city[0],
                                    value: city[0]
                                })
                            })
                        }
                    })
                }
            })
        }

        return citiesList.sort((a,b) => { if(a < b) {return -1} });
    }
}

export default CodiciCatastali;