import React, {useEffect, useState} from 'react'

// Import refs
import {Link, NavLink, useNavigate} from 'react-router-dom';

// Import assets
import AvatarDefault from '../../../assets/images/Avatar/Avatar-Default.png'
import Cookies from '../../utility/Cookies/Cookies';

import {useTranslation} from 'react-i18next';
import {useFetcher} from '../../services/Fetcher/Fetcher';
import {Skeleton} from '../Loader/Skeleton/Skeleton';
import DirectFetcher from "../../services/Fetcher/DirectFetcher";
import {Switch} from "../Fields/Switch/Switch";

export const Header = props => {

    const {
        appReady,
        menuCollapsed,
        setMenuCollapsed,
        setUserRole,
        layoutMode,
        setLayoutMode,
        theme,
        setTheme
    } = props;

    const navigate = useNavigate();

    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);

    const { t, i18n } = useTranslation();

    const toggleProfile = () => {

        const profileDOM = document.getElementById('wg-profileMenu');
        const fakeOverlayHeaderDOM = document.getElementById('wg-headerFakeOverlay');


        if(profileDOM) {
            profileDOM.classList.toggle('open');
            profileDOM.parentElement.classList.toggle('open');
        }

        fakeOverlayHeaderDOM.classList.toggle('visible');
    }

    const navigateToRoute = route => {
        if(route) {
            navigate(`/${route}`);
        }

        toggleProfile();
    }

    const handleLogout = () => {
        const token = Cookies.getCookie('LOGGEDIN');

        if(token) {
            new DirectFetcher().fetch(
                '/user/logout',
                'POST'
            ).then(res => {

                if(res !== false) {
                    localStorage.removeItem('season');
                    localStorage.removeItem('clubName');

                    window.location.href = 'login';
                }
            })
        }
    }

    const setCompactMode = () => {

        setLayoutMode(oldLayoutMode => !oldLayoutMode ? 'compact' : null)

        localStorage.setItem('layoutMode', !layoutMode ? 'compact' : null)


    }

    const setThemeMode = () => {

        const htmlDOM = document.getElementsByTagName('html')[0];

        if(htmlDOM) {
            if(theme === 'light') {
                htmlDOM.classList.remove('wg-theme-light');
                htmlDOM.classList.add('wg-theme-dark');
            }

            if(theme === 'dark') {
                htmlDOM.classList.remove('wg-theme-dark');
                htmlDOM.classList.add('wg-theme-light');
            }
        }

        setTheme(oldTheme => oldTheme === 'light' ? 'dark' : 'light');
        localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light');

    }

    useEffect(() => {

        if(appReady) {

            setLoading(true);

            new DirectFetcher().fetch(
                '/user',
                'GET'
            ).then(res => {

                if(res !== false) {

                    setUserData(res);
                    setUserRole(res.role);
                }

                setLoading(false);
            })
        }

    }, [appReady]);

    useEffect(() => {
        if(Object.keys(userData).length > 0) {
            if(userData.language) {
                if(String(userData.language).toLowerCase() === 'italiano') {
                    i18n.changeLanguage('it')
                }else{
                    i18n.changeLanguage('en')
                }
            }
        }
    }, [userData]);

    return(
        <React.Fragment>
            <div className={'wg-header'}>
                <div className={'wg-headerFakeOverlay'} id={'wg-headerFakeOverlay'} onClick={toggleProfile} />

                <div className={'wg-leftSide'}>
                    <div className={'wg-toggleMenu'} onClick={() => setMenuCollapsed(!menuCollapsed)}>
                        <i className={`wi-menu-${menuCollapsed ? 'closed' : 'open'}`} aria-hidden={true} />
                    </div>
                    <div className={'wg-logo'}>
                        <Link to={'/'}>
                            <svg width="133" height="32" viewBox="0 0 133 32" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M21.5322 19.6424L18.5713 19.5973L17.6993 22.4303C17.1463 22.5799 16.5673 22.6667 15.964 22.6667C15.3607 22.6667 14.782 22.5791 14.2273 22.4285L13.3567 19.5972L10.3958 19.6423C9.76756 18.6818 9.38513 17.5495 9.32515 16.3329L11.7445 14.625L10.7841 11.8194C11.5178 10.9094 12.4861 10.201 13.5946 9.77639L15.964 11.5556L18.3317 9.77708C19.4405 10.2017 20.4085 10.9104 21.1421 11.8201L20.1835 14.625L22.6028 16.3299C22.5446 17.5486 22.1632 18.6806 21.5322 19.6424ZM13.2284 15.0486L14.2963 18.2222H17.6421L18.669 15.0493L15.964 13.0868L13.2284 15.0486Z"
                                      fill="#0D0C22"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M0 8C0 3.58172 3.58172 0 8 0H23.8968C28.3151 0 31.8968 3.58172 31.8968 8V24C31.8968 28.4183 28.3151 32 23.8968 32H7.99999C3.58171 32 0 28.4183 0 24V8ZM15.9328 7.11111C11.0615 7.11111 7.08817 11.0903 7.08817 16C7.08817 20.9097 11.0615 24.8889 15.964 24.8889C20.8665 24.8889 24.8086 20.9097 24.8086 16.0312C24.8086 11.1528 20.8665 7.11111 15.9328 7.11111Z"
                                      fill="#0D0C22"/>
                                <path
                                    d="M47.9714 12.224L49.6248 20H51.8968L53.1554 14.464H53.2301L54.4568 20H56.7394L58.3928 12.224H56.5581L55.8648 16.1493L55.5448 18.208H55.4701L54.2114 12.224H52.1741L50.9474 18.1867H50.8621L50.5208 16.128L49.8061 12.224H47.9714Z"
                                    fill="#0D0C22"/>
                                <path
                                    d="M61.3124 12.224V20H66.987V18.5813H62.9764V16.7147H66.443V15.3813H62.9764V13.6427H66.9657V12.224H61.3124Z"
                                    fill="#0D0C22"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M72.1036 12.224L69.5116 20H71.2396L71.7623 18.4H74.7916L75.3143 20H77.0423L74.4503 12.224H72.1036ZM74.4076 17.1307H72.1463L72.8076 15.1467L73.2449 13.728H73.3089L73.7463 15.1467L74.4076 17.1307Z"
                                      fill="#0D0C22"/>
                                <path
                                    d="M80.6997 19.776C81.1761 19.9893 81.7628 20.096 82.4597 20.096C83.1708 20.096 83.761 20 84.2304 19.808C84.7068 19.6089 85.0624 19.3316 85.297 18.976C85.5388 18.6133 85.6597 18.2009 85.6597 17.7387C85.6597 17.2693 85.585 16.8889 85.4357 16.5973C85.2864 16.2987 85.0837 16.0676 84.8277 15.904C84.5717 15.7333 84.2873 15.6053 83.9744 15.52C83.6615 15.4347 83.3415 15.3671 83.0144 15.3173C82.5664 15.2391 82.2037 15.168 81.9264 15.104C81.649 15.0329 81.4464 14.9369 81.3184 14.816C81.1975 14.6951 81.137 14.5173 81.137 14.2827C81.137 14.0978 81.1868 13.9378 81.2864 13.8027C81.3859 13.6676 81.5317 13.5644 81.7237 13.4933C81.9157 13.4222 82.1397 13.3867 82.3957 13.3867C82.6446 13.3867 82.8686 13.4222 83.0677 13.4933C83.2668 13.5644 83.4268 13.6782 83.5477 13.8347C83.6757 13.9911 83.7432 14.1938 83.7504 14.4427H85.425C85.425 13.9804 85.3041 13.5751 85.0624 13.2267C84.8206 12.8782 84.4757 12.608 84.0277 12.416C83.5868 12.224 83.0606 12.128 82.449 12.128C81.8232 12.128 81.2828 12.224 80.8277 12.416C80.3726 12.608 80.0206 12.8818 79.7717 13.2373C79.5299 13.5858 79.409 14.0053 79.409 14.496C79.409 14.8373 79.4659 15.136 79.5797 15.392C79.6935 15.6409 79.8641 15.8542 80.0917 16.032C80.3193 16.2098 80.6037 16.3591 80.945 16.48C81.2864 16.6009 81.6775 16.6933 82.1184 16.7573C82.4739 16.8213 82.7655 16.8818 82.993 16.9387C83.2277 16.9884 83.4126 17.056 83.5477 17.1413C83.6899 17.2196 83.7895 17.3191 83.8464 17.44C83.9033 17.5609 83.9317 17.7138 83.9317 17.8987C83.9317 18.2044 83.8037 18.4356 83.5477 18.592C83.2917 18.7484 82.9504 18.8267 82.5237 18.8267C82.1539 18.8267 81.8517 18.7733 81.617 18.6667C81.3895 18.56 81.2188 18.4142 81.105 18.2293C80.9984 18.0373 80.9415 17.8133 80.9344 17.5573H79.249C79.249 18.0551 79.3699 18.496 79.6117 18.88C79.8606 19.264 80.2233 19.5627 80.6997 19.776Z"
                                    fill="#0D0C22"/>
                                <path
                                    d="M90.3916 17.0347V20H92.0556V17.0347L94.9569 12.224H93.0689L91.2663 15.4133H91.1916L89.4103 12.224H87.5116L90.3916 17.0347Z"
                                    fill="#0D0C22"/>
                                <path
                                    d="M99.0203 19.8187C99.4967 20.0036 100.037 20.096 100.642 20.096C101.218 20.096 101.74 20.0142 102.21 19.8507C102.679 19.6871 103.081 19.4524 103.415 19.1467C103.749 18.8409 104.005 18.4747 104.183 18.048C104.368 17.6142 104.46 17.1307 104.46 16.5973V15.8507H100.642V17.0347H102.774C102.745 17.3249 102.664 17.5845 102.53 17.8133C102.359 18.1049 102.114 18.3289 101.794 18.4853C101.481 18.6347 101.107 18.7093 100.674 18.7093C100.339 18.7093 100.037 18.656 99.767 18.5493C99.5038 18.4356 99.2763 18.272 99.0843 18.0587C98.8923 17.8382 98.7465 17.5644 98.6469 17.2373C98.5474 16.9102 98.4976 16.5333 98.4976 16.1067C98.4976 15.5378 98.5865 15.0649 98.7643 14.688C98.9492 14.304 99.2087 14.0196 99.543 13.8347C99.8772 13.6427 100.268 13.5467 100.716 13.5467C101.051 13.5467 101.349 13.6 101.612 13.7067C101.875 13.8062 102.092 13.952 102.263 14.144C102.441 14.336 102.558 14.5671 102.615 14.8373H104.322C104.265 14.2898 104.08 13.8133 103.767 13.408C103.461 13.0027 103.049 12.6898 102.53 12.4693C102.018 12.2418 101.413 12.128 100.716 12.128C100.126 12.128 99.5892 12.2169 99.1056 12.3947C98.6292 12.5724 98.2167 12.832 97.8683 13.1733C97.5269 13.5147 97.2603 13.9307 97.0683 14.4213C96.8834 14.912 96.7909 15.4738 96.7909 16.1067C96.7909 16.704 96.8763 17.248 97.047 17.7387C97.2247 18.2293 97.4772 18.6524 97.8043 19.008C98.1385 19.3564 98.5438 19.6267 99.0203 19.8187Z"
                                    fill="#0D0C22"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M109.657 19.8293C110.134 20.0071 110.667 20.096 111.257 20.096C111.848 20.096 112.381 20.0071 112.857 19.8293C113.334 19.6516 113.743 19.392 114.084 19.0507C114.433 18.7093 114.699 18.2933 114.884 17.8027C115.069 17.3049 115.161 16.7396 115.161 16.1067C115.161 15.4738 115.069 14.912 114.884 14.4213C114.706 13.9307 114.443 13.5147 114.095 13.1733C113.746 12.832 113.334 12.5724 112.857 12.3947C112.381 12.2169 111.848 12.128 111.257 12.128C110.667 12.128 110.134 12.2169 109.657 12.3947C109.181 12.5724 108.769 12.832 108.42 13.1733C108.079 13.5147 107.816 13.9307 107.631 14.4213C107.446 14.912 107.353 15.4738 107.353 16.1067C107.353 16.7396 107.446 17.3049 107.631 17.8027C107.816 18.2933 108.079 18.7093 108.42 19.0507C108.769 19.392 109.181 19.6516 109.657 19.8293ZM112.42 18.3893C112.093 18.5813 111.705 18.6773 111.257 18.6773C110.923 18.6773 110.621 18.624 110.351 18.5173C110.088 18.4107 109.86 18.2507 109.668 18.0373C109.476 17.824 109.327 17.5573 109.22 17.2373C109.121 16.9102 109.071 16.5333 109.071 16.1067C109.071 15.5378 109.16 15.0649 109.337 14.688C109.515 14.304 109.768 14.0196 110.095 13.8347C110.429 13.6427 110.817 13.5467 111.257 13.5467C111.592 13.5467 111.894 13.6 112.164 13.7067C112.434 13.8133 112.665 13.9769 112.857 14.1973C113.049 14.4107 113.195 14.6773 113.295 14.9973C113.401 15.3102 113.455 15.68 113.455 16.1067C113.455 16.6756 113.366 17.152 113.188 17.536C113.01 17.9129 112.754 18.1973 112.42 18.3893Z"
                                      fill="#0D0C22"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M119.843 12.224L117.251 20H118.979L119.502 18.4H122.531L123.054 20H124.782L122.19 12.224H119.843ZM122.147 17.1307H119.886L120.547 15.1467L120.985 13.728H121.049L121.486 15.1467L122.147 17.1307Z"
                                      fill="#0D0C22"/>
                                <path d="M127.635 12.224V20H132.755V18.528H129.299V12.224H127.635Z" fill="#0D0C22"/>
                            </svg>

                        </Link>
                    </div>
                </div>

                <div className={'wg-rightSide'}>
                    <div className={'wg-profile'}>

                        <div
                            className={'wg-toggleProfileMenu'}
                            onClick={toggleProfile}
                        />

                        <div className={'wg-profileMenu'} id={'wg-profileMenu'}>
                            <ul>
                                <li onClick={() => navigateToRoute('settings')}>
                                    <i className={'wi-settings'} aria-hidden={true}/>
                                    <span>Impostazioni</span>
                                </li>
                                {/*<li onClick={setCompactMode}>*/}
                                {/*    <i className={'wi-slider'} aria-hidden={true} />*/}
                                {/*    <span>Modalità Compatta</span>*/}
                                {/*    <Switch*/}
                                {/*        checked={!!layoutMode}*/}
                                {/*        label={false}*/}
                                {/*        handleClick={setCompactMode}*/}
                                {/*    />*/}
                                {/*</li>*/}
                                {/*<li onClick={setThemeMode}>*/}
                                {/*    <i className={'wi-theme'} aria-hidden={true} />*/}
                                {/*    <span>Tema Scuro</span>*/}
                                {/*    <Switch*/}
                                {/*        checked={theme === 'dark'}*/}
                                {/*        label={false}*/}
                                {/*        handleClick={setThemeMode}*/}
                                {/*    />*/}
                                {/*</li>*/}
                                <li onClick={handleLogout}>
                                    <i className={'wi-logout'} aria-hidden={true}/>
                                    <span>Logout</span>
                                </li>
                            </ul>
                        </div>

                        <div className={'wg-avatar'}>
                            {
                                loading ?
                                    <Skeleton type={'avatar'}/>
                                    :
                                    <img src={userData.avatar ? userData.avatar : AvatarDefault} alt={'User Avatar'}
                                         id={'wg-userData_avatar'}/>
                            }
                        </div>
                        <div className={'wg-userInfo'}>
                            <span className={'wg-username'}>
                                <span className={'wg-userName'} id={'wg-userData_name'}>
                                    {
                                        loading ?
                                            <Skeleton/>
                                            :
                                            userData.name ? userData.name : ''
                                    }
                                </span>
                                <span className={'wg-userSurname'} id={'wg-userData_surname'}>
                                    {
                                        loading ?
                                            <Skeleton/>
                                            :
                                            userData.surname ? userData.surname : ''
                                    }
                                </span>
                        </span>
                            <span className={'wg-role'}>
                                {
                                    loading ?
                                        <Skeleton/>
                                        :
                                        userData.role_description ? userData.role_description : ''
                                }
                            </span>
                        </div>

                        <i className={'wi-chevron-down'} aria-hidden={true}/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}