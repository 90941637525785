import React, {useEffect, useState} from 'react'
import {Section} from "../../../components/common/Section/Section";
import {DataGrid} from "../../../components/common/DataGrid/DataGrid";
import {useFetcher} from "../../../components/services/Fetcher/Fetcher";
import {LineChart} from "../../../components/common/Charts/LineChart/LineChart";
import {PlayerData} from "./PlayerData/PlayerData";
import DirectFetcher from "../../../components/services/Fetcher/DirectFetcher";
import {Button} from "../../../components/common/Button/Button";
import {ContextMenu} from "../../../components/common/ContextMenu/ContextMenu";
import {Link, useOutletContext} from 'react-router-dom';
import {toast} from 'react-toastify'
import {Dialog} from "../../../components/common/Dialog/Dialog";
import {Dropdown} from "../../../components/common/Fields/Dropdown/Dropdown";

export const IncomeExpense = props => {

    const [routeData] = useOutletContext();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [teams, setTeams] = useState([]);
    const [chartsDataBase, setChartsDataBase] = useState([]);

    const [playerListData, setPlayerListData] = useState([]);
    const [dropdownTeams, setDropdownTeams] = useState([]);

    const [refreshData, setRefreshData] = useState(0);

    // Team
    const [selectedTeam, setSelectedTeam] = useState(null);

    // Player Data
    const [selectedPlayerData, setSelectedPlayerData] = useState({});
    const [playerDataVisible, setPlayerDataVisible] = useState(false);

    // Charts Data
    const [chartsData, setChartsData] = useState({});
    const [totalChartData, setTotalChartData] = useState({});
    const [paymentChartsData, setPaymentChartsData] = useState({});

    // Export Team Dialog
    const [exportDialogVisible, setExportDialogVisible] = useState(false);
    const [exportProcessing, setExportProcessing] = useState(false);
    const [exportFormat, setExportFormat] = useState('PDF');

    // TODO: Check if chart updates correctly

    const togglePlayerDetail = (row) => {

        if(row) {
            setSelectedPlayerData(row);
        }else{
            setSelectedPlayerData({});
        }

        setPlayerDataVisible(true);
    }

    const exportData = () => {

        if(exportFormat) {

            setExportProcessing(true);
            new DirectFetcher().fetch(
                `/subscription-fee/payments/export/${String(exportFormat).toLowerCase()}`,
                'GET',
                null,
                true,
                null,
                true,
                false,
                'file'
            ).then(res => {

                if (res !== false) {

                    toast.info('I pagamenti sono stati esportati')
                }

                setExportProcessing(false);
                setExportDialogVisible(false);
            })
        }
    }

    const closeExportDialog = () => {
        setExportDialogVisible(false)
    }

    const handleTeamFilter = (team, data = chartsData) => {

        const teamTotalChart = data.total_chart && data.total_chart.teams ? data.total_chart.teams : [];
        const teamPaymentsTypeChart = data.total_chart && data.payment_types_chart.teams ? data.payment_types_chart.teams : [];

        if(team && team.value) {
            if(teamTotalChart.length > 0) {
                teamTotalChart.map(teamChart => {

                    if(teamChart.name === team.value) {
                        setTotalChartData(teamChart);
                    }
                })
            }

            if(teamPaymentsTypeChart.length > 0) {
                teamPaymentsTypeChart.map(teamChart => {

                    if(teamChart.name === team.value) {
                        setPaymentChartsData(teamChart);
                    }
                })
            }

            setSelectedTeam(team.value);
        }else{
            setTotalChartData(data.total_chart)
            setPaymentChartsData(data.payment_types_chart);
            setSelectedTeam(null);
        }
    }

    useEffect(() => {

        setPlayerListData(data);

    }, [data]);

    useEffect(() => {

        setChartsData(chartsDataBase)

    }, [chartsDataBase]);

    useEffect(() => {

        if(Object.keys(chartsData).length > 0) {
            if(selectedTeam) {
                handleTeamFilter({value: selectedTeam}, chartsData);
            }else{
                handleTeamFilter(null, chartsData);
            }
        }

    }, [chartsData]);

    useEffect(() => {

        if(playerDataVisible) {
            document.getElementsByTagName('body')[0].classList.add('no-overflow');
        }else{
            document.getElementsByTagName('body')[0].classList.remove('no-overflow');
            setSelectedPlayerData({});
        }

    }, [playerDataVisible]);

    useEffect(() => {
        if(teams.length > 0) {

            const teamList = [];

            teams.map(team => {
                teamList.push({
                    key: team.team_id,
                    value: team.name
                });
            })

            setDropdownTeams(teamList);
        }
    }, [teams]);

    useEffect(() => {

        if(routeData.appReady) {

            setLoading(true);

            new DirectFetcher().fetch(
                '/subscription-fee/payments',
                'GET',
                null,
                true
            ).then(res => {

                if(res !== false) {

                    setData(res);
                }


                setLoading(false);
            })

            new DirectFetcher().fetch(
                '/teams',
                'GET',
                null,
                true
            ).then(res => {

                if(res !== false) {

                    setTeams(res);
                }
            })

            new DirectFetcher().fetch(
                '/subscription-fee/charts',
                'GET',
                null,
                true
            ).then(res => {

                if(res !== false) {

                    setChartsDataBase(res);
                }


                setLoading(false);
            })
        }
    }, [routeData.appReady]);

    return(
        <React.Fragment>
            <div className={'wg-pageHeader'}>
                <div className={'wg-pageTitle'}>
                    Entrate
                </div>

                <div className={'wg-pageActions'}>

                    <Button
                        type={'secondary'}
                        icon={'dots_horizontal'}
                        label={'Altro'}
                        hasChildren={true}
                    >
                        <ContextMenu width={230}>
                            <ul>
                                <li>
                                    <Link to={'#'} onClick={() => setExportDialogVisible(true)}>
                                        <i className={'wi-download'} aria-hidden={"true"}/> Esporta Pagamenti
                                    </Link>
                                </li>
                            </ul>
                        </ContextMenu>
                    </Button>

                </div>
            </div>

            <div className={'wg-widgetsContainer'}>
                <Section
                    title={`Entrate previste ${selectedTeam ? '('+selectedTeam+')' : ''}`}
                >
                    <div className={'wg-widgetChartContainer'}>
                        <div className={'wg-widgetChartContent'}>
                            <div className={'wg-widgetChartContentItem'}>
                                <span className={'wg-widgetChartContentItemDescription'}>Entrate</span>
                                <span
                                    className={'wg-widgetChartContentItemAmount'}>{
                                    // totalChartData ? totalChartData.paid : 0
                                    totalChartData ? new Intl.NumberFormat('it-IT').format(totalChartData.paid) : 0
                                } €
                                </span>
                            </div>
                            <div className={'wg-widgetChartContentItem'}>
                                <span className={'wg-widgetChartContentItemDescription'}>Obbiettivo</span>
                                <span className={'wg-widgetChartContentItemAmount'}>{totalChartData ? new Intl.NumberFormat('it-IT').format(totalChartData.to_pay) : 0} €</span>
                            </div>
                        </div>

                        <LineChart
                            type={'progress'}
                            data={{
                                amount: totalChartData ? totalChartData.paid : null,
                                total: totalChartData ? totalChartData.to_pay : null
                            }}
                        />

                        <div className={'wg-widgetChartContentFooter'}>
                            Da ricevere: {totalChartData ? new Intl.NumberFormat('it-IT').format(totalChartData.left_to_pay) : 0} €
                        </div>
                    </div>
                </Section>

                <Section
                    title={'Dettagli entrate'}
                >
                    <div className={'wg-widgetChartContainer'}>
                        <div className={'wg-widgetChartContent'}>
                            <div className={'wg-widgetChartContentItem'}>
                                <span className={'wg-widgetChartContentItemDescription'}>Totale transazioni</span>
                                <span className={'wg-widgetChartContentItemAmount'}>{paymentChartsData && paymentChartsData.payments_count ? paymentChartsData.payments_count : '-'}</span>
                            </div>

                            <div className={'wg-widgetChartContentItem'} style={{display: 'none'}}>
                                <span className={'wg-widgetChartContentItemDescription'}>Expected Transactions</span>
                                <span className={'wg-widgetChartContentItemAmount'}>324</span>
                            </div>
                        </div>

                        <LineChart
                            type={'line'}
                            data={paymentChartsData ? paymentChartsData : []}
                        />

                        <div className={'wg-widgetChartContentFooter'}>
                            <div className={'wg-widgetChartContentFooterLegend'}>

                                {
                                    paymentChartsData && paymentChartsData.types ?
                                        Object.keys(paymentChartsData.types).length > 0 ?
                                            Object.keys(paymentChartsData.types).map((type, index) => {

                                                const item = paymentChartsData.types[type];

                                                if(type.toLowerCase() !== 'altro') {
                                                    return (
                                                        <div className={`wg-widgetChartContentFooterLegendItem wg-widgetChartContentFooterLegendItem_${index}`} key={`wg_widgetLegend_${type}`}>
                                                            {type} ({item.count})
                                                        </div>
                                                    )
                                                }
                                            })
                                            : null
                                        : null
                                }

                            </div>
                        </div>
                    </div>
                </Section>
            </div>

            <Section
                title={'Giocatori'}
                counter={playerListData ? playerListData.length : false}
            >
                <DataGrid
                    columns={[
                        {
                            name: 'photo',
                            label: '',
                            type: 'avatar'
                        },
                        {
                            name: 'name',
                            label: 'Nome',
                            type: 'group',
                            groupKeys: {
                                title: ['surname', 'name'],
                                description: ['identifier']
                            }
                        },
                        {
                            name: 'birth_date',
                            label: 'Data di nascita',
                            type: 'date'
                        },
                        {
                            name: 'city',
                            label: 'Residenza ',
                            type: 'group',
                            groupKeys: {
                                title: ['city'],
                                description: ['province', 'nationality']
                            }
                        },
                        {
                            name: 'subscription_fee_amount',
                            label: 'Tassa d\'iscrizione',
                            type: 'badge',
                            validation: {
                                type: 'amount',
                                progress: 'subscription_fee_amount_paid',
                                total: 'subscription_fee_amount'
                            }
                        },
                        {
                            name: 'team_names',
                            label: 'Squadre',
                            type: 'text'
                        }
                    ]}
                    filters={
                        {
                            columnsToFilter: ['name', 'surname', 'birth_date', 'team_names'],
                            filterByDropdown: {
                                key: 'team_names',
                                values: dropdownTeams,
                                callback: handleTeamFilter
                            },
                            filterBySwitch: {
                                key: 'subscription_fee_amount',
                                label: 'Vedi solo da pagare',
                                argument: 'state',
                                validation: {
                                    type: 'amount',
                                    progress: 'subscription_fee_amount_paid',
                                    total: 'subscription_fee_amount'
                                }
                            }
                        }
                    }
                    refreshData={refreshData}
                    sortByKey={'surname'}
                    data={playerListData}
                    loading={loading}
                    idKey={'player_id'}
                    skeletonKeys={6}
                    noData={{
                        title: 'No players found',
                        description: 'It seems there are no players in this football club'
                    }}
                    handleRowClick={togglePlayerDetail}
                />
            </Section>

            {exportDialogVisible ?
                <Dialog
                    title={'Esporta Giocatori'}
                    primaryButton={{
                        label: 'Esporta',
                        disabled: exportProcessing || !exportFormat,
                        loading: exportProcessing,
                        handleClick: exportData
                    }}
                    secondaryButton={{
                        label: 'Annulla',
                        handleClick: () => setExportDialogVisible(false)
                    }}
                    dialogVisible={exportDialogVisible}
                    setDialogVisible={closeExportDialog}
                    width={300}
                >
                    <Dropdown
                        label={'Scegli il formato'}
                        items={[
                            {
                                key: 'pdf',
                                value: 'PDF'
                            },
                            {
                                key: 'excel',
                                value: 'Excel'
                            }
                        ]}
                        resetSelection={false}
                        filterable={false}
                        defaultValue={exportFormat}
                        placeholder={'Scegli'}
                        handleChange={selectedItem => setExportFormat(selectedItem.value)}
                    />
                </Dialog>
                :
                null
            }

            <PlayerData
                data={selectedPlayerData}
                listData={playerListData}
                setListData={setPlayerListData}
                updateChartsData={setChartsData}
                selectedTeam={selectedTeam}
                visible={playerDataVisible}
                setVisible={setPlayerDataVisible}
            />
        </React.Fragment>
    )
}