import React, {useState} from 'react'
import {Textfield} from "../../../../components/common/Fields/Textfield/Textfield";
import FieldValidation from "../../../../components/utility/Validation/Validation";

export const PlayerParents = (props) => {

    const {
        data: playerData = [],
        setData: setPlayerData,
        savePlayerData,
        fieldsErrors,
        setFieldsErrors
    } = props;

    const handleChange = event => {

        if(event) {

            const {name, value} = event.target;

            let validation = '';

            if(value && event.target.getAttribute('data-validation')) {
                validation = FieldValidation.field(event.target.getAttribute('data-validation'), value);
            }else{
                validation = {value: value, error: null};
            }

            setFieldsErrors(oldFieldErrors => ({
                ...oldFieldErrors,
                [name]: validation.error
            }))

            setPlayerData(playerDataOld => ({
                ...playerDataOld,
                [name]: value
            }))
        }
    }

    return (
        <React.Fragment>
            <div className={'wg-detailPanelDataFieldsContainer'}>

                <div className={'wg-detailPanelDataFieldsContainerTitle'}>Informazioni Personali Mamma</div>

                <div className={'wg-detailPanelDataFields'}>
                    <div className={'wg-detailPanelDataFieldsRow'}>
                        <Textfield
                            id={'mother_name'}
                            fill={true}
                            label={'Nome Mamma'}
                            placeholder={'Nome della mamma'}
                            defaultValue={playerData.mother_name}
                            error={fieldsErrors.mother_name}
                            maxLength={64}
                            validationType={'text'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                        <Textfield
                            id={'mother_surname'}
                            fill={true}
                            label={'Cognome Mamma'}
                            placeholder={'Cognome della mamma'}
                            defaultValue={playerData.mother_surname}
                            error={fieldsErrors.mother_surname}
                            maxLength={64}
                            validationType={'text'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                    </div>
                    <div className={'wg-detailPanelDataFieldsRow'}>
                        <Textfield
                            type={'email'}
                            id={'mother_email'}
                            fill={true}
                            label={'E-mail mamma'}
                            placeholder={'E-mail della mamma'}
                            defaultValue={playerData.mother_email}
                            error={fieldsErrors.mother_email}
                            maxLength={64}
                            validationType={'email'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                        <Textfield
                            type={'tel'}
                            id={'mother_phone_number'}
                            fill={true}
                            label={'Numero di telefono mamma'}
                            placeholder={'Numero di telefono della mamma'}
                            defaultValue={playerData.mother_phone_number}
                            error={fieldsErrors.mother_phone_number}
                            maxLength={10}
                            validationType={'phone'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                    </div>
                </div>
            </div>

            <div className={'wg-detailPanelDataFieldsContainer'}>

                <div className={'wg-detailPanelDataFieldsContainerTitle'}>Informazioni Personali Papà</div>

                <div className={'wg-detailPanelDataFields'}>
                    <div className={'wg-detailPanelDataFieldsRow'}>
                        <Textfield
                            id={'father_name'}
                            fill={true}
                            label={'Nome Papà'}
                            placeholder={'Nome del papà'}
                            defaultValue={playerData.father_name}
                            error={fieldsErrors.father_name}
                            maxLength={64}
                            validationType={'text'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                        <Textfield
                            id={'father_surname'}
                            fill={true}
                            label={'Cognome Papà'}
                            placeholder={'Cognome del papà'}
                            defaultValue={playerData.father_surname}
                            error={fieldsErrors.father_surname}
                            maxLength={64}
                            validationType={'text'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                    </div>
                    <div className={'wg-detailPanelDataFieldsRow'}>
                        <Textfield
                            type={'email'}
                            id={'father_email'}
                            fill={true}
                            label={'E-mail papà'}
                            placeholder={'E-mail del papà'}
                            defaultValue={playerData.father_email}
                            error={fieldsErrors.father_email}
                            maxLength={128}
                            validationType={'email'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                        <Textfield
                            id={'father_phone_number'}
                            fill={true}
                            label={'Numero di telefono papà'}
                            placeholder={'Numero di telefono del papà'}
                            defaultValue={playerData.father_phone_number}
                            error={fieldsErrors.father_phone_number}
                            maxLength={10}
                            validationType={'phone'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                    </div>
                </div>
            </div>

            <div className={'wg-detailPanelDataFieldsContainer'}>

                <div className={'wg-detailPanelDataFieldsContainerTitle'}>Informazioni Personali Tutore Legale</div>

                <div className={'wg-detailPanelDataFields'}>
                    <div className={'wg-detailPanelDataFieldsRow'}>
                        <Textfield
                            id={'legal_guardian_name'}
                            fill={true}
                            label={'Nome tutor legale'}
                            placeholder={'Nome del tutore legale'}
                            defaultValue={playerData.legal_guardian_name}
                            error={fieldsErrors.legal_guardian_name}
                            maxLength={64}
                            validationType={'text'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                        <Textfield
                            id={'legal_guardian_surname'}
                            fill={true}
                            label={'Cognome tutore legale'}
                            placeholder={'Cognome del tutore legale'}
                            defaultValue={playerData.legal_guardian_surname}
                            error={fieldsErrors.legal_guardian_surname}
                            maxLength={64}
                            validationType={'text'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                    </div>
                    <div className={'wg-detailPanelDataFieldsRow'}>
                        <Textfield
                            type={'email'}
                            id={'legal_guardian_email'}
                            fill={true}
                            label={'E-mail tutore legale'}
                            placeholder={'E-mail del tutore legale'}
                            defaultValue={playerData.legal_guardian_email}
                            maxLength={128}
                            error={fieldsErrors.legal_guardian_email}
                            validationType={'email'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                        <Textfield
                            id={'legal_guardian_phone_number'}
                            fill={true}
                            label={'Numero di telefono tutore legale'}
                            placeholder={'Numero di telefono del tutore legale'}
                            defaultValue={playerData.legal_guardian_phone_number}
                            error={fieldsErrors.legal_guardian_phone_number}
                            maxLength={10}
                            validationType={'phone'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}