import React from 'react'
import ReactDOM from 'react-dom/client';

import './assets/style/weasy_ui.css';

import {App} from './routes/App';
import {BrowserRouter} from 'react-router-dom';
import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);