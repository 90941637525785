import React, {useEffect} from 'react';
import {Button} from '../Button/Button';
import {Section} from '../Section/Section';

export const Dialog = props => {

    const {
        title = null,
        dialogVisible = false,
        setDialogVisible = null,
        primaryButton = {
            type: 'primary',
            label: 'Primary button',
            disabled: false,
            visible: true,
            loading: false,
            handleClick: null
        },
        secondaryButton = {
            type: 'secondary',
            label: 'Secondary button',
            disabled: false,
            visible: true,
            handleClick: null
        },
        tertiaryButton = {
            type: 'secondary',
            label: 'Tertiary button',
            disabled: false,
            visible: false,
            handleClick: null
        },
        width,
        children,
    } = props;


    return(
        <React.Fragment>
            <Section
                className={`wg-dialog ${dialogVisible ? 'visible' : 'hidden'}`}
                fillBackground={false}
            >
                <div className={'wg-dialogOverlay'} onClick={setDialogVisible ? setDialogVisible : null}></div>

                <div
                    className={'wg-dialogContent'}
                     style={width ? {width: width} : null}
                >
                    {title ?
                        <div className={'wg-dialogHeader'}>
                            <div className={'wg-dialogTitle'}>{title}</div>
                        </div>
                        :
                        null
                    }

                    <div className={'wg-dialogBody'}>
                        {children}
                    </div>

                    {primaryButton || secondaryButton ?
                        <div className={'wg-dialogFooter'}>
                            {primaryButton ?
                                <Button
                                    type={primaryButton.type ? primaryButton.type : 'primary'}
                                    label={primaryButton.label}
                                    disabled={primaryButton.disabled}
                                    visible={primaryButton.visible}
                                    loading={primaryButton.loading}
                                    handleClick={primaryButton.handleClick}
                                />
                                :
                                null
                            }

                            {secondaryButton ?
                                <Button
                                    type={secondaryButton.type ? secondaryButton.type : 'secondary'}
                                    label={secondaryButton.label}
                                    disabled={secondaryButton.disabled}
                                    visible={secondaryButton.visible}
                                    handleClick={secondaryButton.handleClick}
                                />
                                :
                                null
                            }

                            {tertiaryButton ?
                                <Button
                                    type={tertiaryButton.type ? tertiaryButton.leftAlign ? `${tertiaryButton.type} leftAlign` : tertiaryButton.type  : 'secondary'}
                                    label={tertiaryButton.label}
                                    disabled={tertiaryButton.disabled}
                                    visible={tertiaryButton.visible}
                                    handleClick={tertiaryButton.handleClick}
                                />
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }
                </div>
            </Section>
        </React.Fragment>
    )
}