import React, {useEffect, useState} from "react";

// Import refs
import {Checkbox} from "../Fields/Checkbox/Checkbox";
import {Skeleton} from "../Loader/Skeleton/Skeleton";
import {Badge} from "../Badge/Badge";
import {Textfield} from "../Fields/Textfield/Textfield";
import {Dropdown} from "../Fields/Dropdown/Dropdown";
import {Switch} from "../Fields/Switch/Switch";

import AvatarDefault from "../../../assets/images/Avatar/Avatar-Default.png"
import {Toolbar} from "../Toolbar/Toolbar";
import {Button} from "../Button/Button";
import {NoData} from "../Messages/NoData/NoData";

export const DataGrid = props => {

    const {
        columns,
        data: gridData = [],
        loading,
        idKey = 'id',
        filters = {},
        refreshData,
        //sortByKey = null,
        skeletonKeys = 5,
        rowsToDelete = [],
        setRowsToDelete,
        deleteRowsCallback,
        deleteRowCallback,
        noData = {
            title: 'Nessun risultato trovato',
            description: false
        },
        handleRowClick = null
    } = props;

    const [data, setData] = useState([]);

    // Selection States
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    // Filter Data
    const [filteredData, setFilteredData] = useState([]);
    const [textFilterValue, setTextFilterValue] = useState(null);
    const [dropdownFilterValue, setDropdownFilterValue] = useState(null);
    const [dropdownFilterSelectedItem, setDropdownFilterSelectedItem] = useState(null);
    const [switchFilterValue, setSwitchFilterValue] = useState(null);

    // Sort Data
    //const [sortAscending, setSortAscending] = useState(true)

    const selectAllRows = event => {

        if(event.target) {
            const checked = event.target.checked;

            if(checked) {
                if(filteredData.length > 0) {

                    const selectedRowsTmp = [];

                    filteredData.map(row => {
                        selectedRowsTmp.push(row[idKey]);
                    });

                    setIsAllChecked(true);
                    setSelectedRows(selectedRowsTmp);
                }
            }else{
                setIsAllChecked(false);
                setSelectedRows([]);
            }
        }
    }

    const selectRow = (event, selectedRowId) => {


        if(event.target) {
            const checked = event.target.checked;

            if(checked) {
                setSelectedRows(oldSelectedRows => [...oldSelectedRows, selectedRowId]);
            }else{
                setSelectedRows(oldSelectedRows => {
                    return [...oldSelectedRows.filter(rowId => rowId !== selectedRowId)]
                });
            }
        }
    }

    const isRowChecked = idToCheck => {

        let isChecked = false;

        if(idToCheck) {
            if(selectedRows.length > 0) {
                selectedRows.map(selectedRow => {
                    if(idToCheck === selectedRow) {
                        isChecked = true;
                    }
                });
            }
        }

        return isChecked;
    }

    const deselectAllRows = () => {
        setSelectedRows([]);
        setIsAllChecked(false);
    }

    const filterDataByText = (value) => {

        if(value) {

            let valueFormatted = value.replaceAll(' ', '');
            setTextFilterValue(valueFormatted.toLowerCase());
        }else{
            setTextFilterValue(null);
        }
    }

    const filterDataByDropdown = (item , callback) => {

        if(item.value) {

            let valueFormatted = item.value.replaceAll(' ', '');
            setDropdownFilterValue(valueFormatted.toLowerCase());
            setDropdownFilterSelectedItem(item.value);
        }else{
            setDropdownFilterValue(null);
            setDropdownFilterSelectedItem(null);
        }

        if(callback) {
            callback(item);
        }
    }

    const filterDataBySwitch = event => {

        const {checked} = event.target;

        if(checked) {
            setSwitchFilterValue(true);
        }else{
            setSwitchFilterValue(false);
        }
    }

    const sortData = (data, refresh = true) => {

    }

    const rowClick = (e, row) => {

        if(!window.getSelection().toString() && e.target.type !== 'checkbox') {
            if(row && handleRowClick) {
                handleRowClick(row);
            }
        }
    }

    const deleteRows = () => {

        if(selectedRows.length > 0) {
            setRowsToDelete(selectedRows);
            setSelectedRows([]);

            if(deleteRowsCallback) {

                const rowIdsToDelete = [];

                selectedRows.map(rowId => {

                    rowIdsToDelete.push(rowId);
                })

                deleteRowsCallback(rowIdsToDelete);
            }
        }
    }

    useEffect(() => {

        const filterBySwitchLocal = (row) => {
            if(filters.filterBySwitch.validation) {
                if(filters.filterBySwitch.validation.type) {

                    if(filters.filterBySwitch.validation.type === 'date') {

                        const daysRangeToCheck = filters.filterBySwitch['validation']['range'] ? filters.filterBySwitch['validation']['range'] : 30;

                        if(row[filters.filterBySwitch['key']]) {
                            const refDateSplit = row[filters.filterBySwitch['key']].split('/', 3);

                            const refDate = new Date(`${refDateSplit[1]}/${refDateSplit[0]}/${refDateSplit[2]}`).getTime();
                            const todayDate = new Date().getTime();
                            const checkDate = todayDate + (daysRangeToCheck * 24 * 60 * 60 * 1000);

                            if(refDate) {
                                if(refDate < checkDate) {
                                    return row;
                                }
                            }
                        }
                    }

                    if(filters.filterBySwitch.validation.type === 'amount') {

                        if(row[filters.filterBySwitch['key']]) {

                            if(Number(row[filters.filterBySwitch.validation.progress]) < Number(row[filters.filterBySwitch.validation.total])) {
                                return row;
                            }
                        }
                    }
                }
            }
        }
        if(textFilterValue || dropdownFilterValue || switchFilterValue) {

            let filteredDataTmp = [];

            if(filters.columnsToFilter && filters.columnsToFilter.length > 0) {

                data.map(row => {

                    let values = '';
                    let switchValues = '';

                    filters.columnsToFilter.map(column => {

                        values = String(values) + (row[column] ? String(row[column]).toLowerCase() : '');
                        if(row[column]) {

                            if(typeof row[column] === 'object') {

                                if(row[column].length > 0) {

                                    row[column].map(value => {

                                        values = values + String(value).toLowerCase();
                                        values = values.replaceAll(',', '');
                                        values = values.replaceAll(' ', '');
                                    })
                                }
                            }else{
                                values = values.replaceAll(',', '');
                                values = values.replaceAll(' ', '');
                            }
                        }

                        if(column === filters.filterBySwitch.key) {
                            switchValues = row[column];
                        }
                    })

                    // Filters cases by active filter values

                    if(textFilterValue && dropdownFilterValue && switchFilterValue) {
                        if(values.includes(textFilterValue) && values.includes(dropdownFilterValue) && filterBySwitchLocal(row)) {
                            filteredDataTmp.push(row);
                        }
                    }

                    if(textFilterValue && dropdownFilterValue && !switchFilterValue) {
                        if(values.includes(textFilterValue) && values.includes(dropdownFilterValue)) {
                            filteredDataTmp.push(row);
                        }
                    }

                    if(!textFilterValue && dropdownFilterValue && switchFilterValue) {
                        if(values.includes(dropdownFilterValue) && filterBySwitchLocal(row)) {
                            filteredDataTmp.push(row);
                        }
                    }

                    if(textFilterValue && !dropdownFilterValue && switchFilterValue) {
                        if(values.includes(textFilterValue) && filterBySwitchLocal(row)) {
                            filteredDataTmp.push(row);
                        }
                    }

                    if(textFilterValue && !dropdownFilterValue && !switchFilterValue) {
                        if(values.includes(textFilterValue)) {
                            filteredDataTmp.push(row);
                        }
                    }

                    if(!textFilterValue && !switchFilterValue && dropdownFilterValue) {
                        if(values.includes(dropdownFilterValue)) {
                            filteredDataTmp.push(row);
                        }
                    }

                    if(!textFilterValue && !dropdownFilterValue && switchFilterValue) {
                        if(filterBySwitchLocal(row)) {
                            filteredDataTmp.push(row);
                        }
                    }
                })

                setTimeout(() => {
                    setFilteredData(filteredDataTmp);
                }, 10);
            }
        }else{
            setFilteredData(data);
        }

    }, [textFilterValue, dropdownFilterValue, switchFilterValue, refreshData]);

    useEffect(() => {
        setData(gridData)
        setFilteredData(gridData)

        checkRowsToRender();
    }, [gridData]);

    useEffect(() => {

        if(rowsToDelete.length > 0) {

            rowsToDelete.map(playerIdToDelete => {
                setFilteredData(filteredData.filter(player => player.player_id !== playerIdToDelete));
                setData(data.filter(player => player.player_id !== playerIdToDelete))
            })

            setRowsToDelete([]);
        }
    }, [rowsToDelete]);

    useEffect(() => {

        window.addEventListener('scroll', checkRowsToRender);
    }, []);

     const checkRowsToRender = () => {

         const { scrollTop, clientHeight } = document.documentElement;

         const allGridRowsDOM = Array.from(document.getElementsByClassName('wg-dataGridRow'));

         if(allGridRowsDOM.length > 0) {

             allGridRowsDOM.map(row => {

                 const children = Array.from(row.children);

                 if(children.length > 0) {
                     if(row.offsetTop - (row.clientHeight * 5) > scrollTop + clientHeight || row.offsetTop + (row.clientHeight * 5) < scrollTop) {

                         children.map(child => {
                             child.classList.add('noLoaded')
                         });
                     }else{

                         children.map(child => {
                             child.classList.remove('noLoaded');
                         });
                     }

                 }
             })
         }
     }

    return(
        <React.Fragment>
            <div className={"wg-dataGrid"}>

                {data.length > 0 && filters ?
                    <div className={'wg-gridFilterArea'}>
                        {
                            filters.columnsToFilter ?
                                <Textfield
                                    label={false}
                                    placeholder={"Filtra..."}
                                    defaultValue={textFilterValue}
                                    disabled={data.length <= 0}
                                    handleChange={(e) => filterDataByText(e.target.value)}
                                />
                            :
                                null
                        }
                        {
                            filters.filterByDropdown ?
                                <Dropdown
                                    id={'team_filter'}
                                    label={false}
                                    placeholder={'Seleziona Squadra'}
                                    itemKey={filters.filterByDropdown.key}
                                    items={filters.filterByDropdown.values}
                                    itemAll={true}
                                    defaultValue={dropdownFilterSelectedItem}
                                    disabled={data.length <= 0}
                                    handleChange={item => filterDataByDropdown(item,  filters.filterByDropdown.callback)}
                                />
                            :
                                null
                        }
                        {
                            filters.filterBySwitch ?
                                <Switch
                                    id={'wg-certificateExpirationSwitch'}
                                    label={filters.filterBySwitch.label}
                                    disabled={data.length <= 0}
                                    handleChange={filterDataBySwitch}
                                />
                                :
                                null
                        }
                    </div>
                :
                    null
                }

                <div className={'wg-dataGridContentContainer'}>
                    {
                        !loading ?
                            data.length > 0 ?
                                columns && columns.length > 0 ?
                                    <div className={'wg-dataGridHeader'}>
                                        <div className={"wg-dataGridRow"} key={'wg-gridHeaderKey'}>
                                            {
                                                columns.map((column, columnIndex) => {

                                                    if(column.type) {

                                                        if(column.type === 'checkbox') {
                                                            return (
                                                                <div className={"wg-dataGridCell checkbox"} key={`wg-gridHeaderCell_${column.name}_${columnIndex}`}>
                                                                    <div className={"wg-dataGridCellText"}>
                                                                        {data.length > 0 ?
                                                                            <Checkbox
                                                                                label={null}
                                                                                checked={isAllChecked}
                                                                                handleChange={e => selectAllRows(e)} />
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        if(column.type === 'avatar') {
                                                            return (
                                                                <div className={"wg-dataGridCell avatar"} key={`wg-gridHeaderCell_${column.name}_${columnIndex}`}>
                                                                    <div className={"wg-dataGridCellAvatar hidden"} />
                                                                </div>
                                                            )
                                                        }

                                                        if(column.type === 'text' || column.type === 'date' || column.type === 'badge' || column.type === 'array') {
                                                            return (
                                                                <div className={"wg-dataGridCell"} key={`wg-gridHeaderCell_${column.name}_${columnIndex}`}>
                                                                    <div className={"wg-dataGridCellText"}>{column.label}</div>
                                                                </div>
                                                            )
                                                        }

                                                        if(column.type === 'group') {
                                                            return (
                                                                <div className={"wg-dataGridCell"} key={`wg-gridHeaderCell_${column.name}_${columnIndex}`}>
                                                                    <div className={"wg-dataGridCellText"}>{column.label}</div>
                                                                </div>
                                                            )
                                                        }


                                                    }
                                                })
                                            }

                                            {
                                                deleteRowCallback ?
                                                    <div className={"wg-dataGridCell deleteRow"}>
                                                    </div>
                                                    : null
                                            }
                                        </div>

                                    </div>
                                    :
                                    <div>No columns defined</div>
                                :
                                null
                            :
                            columns && columns.length > 0 ?
                                <div className={"wg-dataGridHeader"}>
                                    <div className={'wg-dataGridSkeleton'}>
                                        {
                                            <div className={"wg-dataGridRow"}>
                                                {
                                                    columns.map((column, columnIndex) => {
                                                        if (column.type) {

                                                            if (column.type === 'checkbox') {
                                                                return (
                                                                    <div
                                                                        className={"wg-dataGridCell checkbox"}
                                                                        key={`wg-gridSkeletonCell_${column.name}_${columnIndex}`}>
                                                                        <div className={"wg-dataGridCellText"}>
                                                                            <Skeleton />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            if (column.type === 'avatar') {
                                                                return (
                                                                    <div
                                                                        className={"wg-dataGridCell avatar"}
                                                                        key={`wg-gridSkeletonCell_${column.name}_${columnIndex}`}>
                                                                        <div className={"wg-dataGridCellAvatar"}>
                                                                            <Skeleton type={'avatar'} />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            if (column.type === 'text' || column.type === 'date' || column.type === 'badge' || column.type === 'array') {
                                                                return (
                                                                    <div className={"wg-dataGridCell"}
                                                                         key={`wg-gridSkeletonCell_${column.name}_${columnIndex}`}>
                                                                        <div
                                                                            className={'wg-dataGridCellText'}>
                                                                            <Skeleton/>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            if (column.type === 'group') {
                                                                return (
                                                                    <div className={"wg-dataGridCell"}
                                                                         key={`wg-gridSkeletonCell_${column.name}_${columnIndex}`}>
                                                                        <div
                                                                            className={'wg-dataGridCellText'}>
                                                                            <Skeleton/>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }


                                                        }
                                                    })
                                                }
                                            </div>
                                        }

                                    </div>
                                </div>
                                : <div>No columns defined</div>
                    }

                    {/*   Body   */}

                    {
                        !loading ?
                            <div className={"wg-dataGridBody"}>

                                {filteredData && filteredData.length > 0 ?
                                    <div className={"wg-dataGridContent"}>

                                        {filteredData.map((row, rowIndex) => {

                                            return (

                                                <div
                                                    className={'wg-dataGridRow'}
                                                    id={`wg-dataGridRow_${rowIndex}`}
                                                    key={`wg-gridBodyRow_${rowIndex}`}
                                                    onClick={e => rowClick(e, row)}
                                                >

                                                    {
                                                        columns.map((column, columnIndex) => {

                                                            const rowKeys = row ? Object.keys(row) : null;

                                                            if(rowKeys && rowKeys.length > 0) {
                                                                if (column.type === 'text') {
                                                                    return (
                                                                        <div className={"wg-dataGridCell"}
                                                                             key={`wg_gridBodyCell_${column.name}_${rowIndex}_${columnIndex}`}>
                                                                            {row[column.name] ? String(row[column.name]).toLowerCase() : '-'}
                                                                        </div>
                                                                    )
                                                                }

                                                                if (column.type === 'checkbox') {
                                                                    return (
                                                                        <div className={"wg-dataGridCell checkbox"}
                                                                             key={`wg_gridBodyCell_${column.name}_${rowIndex}_${columnIndex}`}>
                                                                            <Checkbox
                                                                                label={null}
                                                                                id={row[idKey] ? row[idKey] : `wg-checkbox_${rowIndex}`}
                                                                                checked={() => isRowChecked(row[idKey])}
                                                                                handleChange={(event) => selectRow(event, row[idKey])}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                                if (column.type === 'avatar') {

                                                                    return rowKeys.map(cell => {
                                                                        if (cell === column.name) {
                                                                            return (
                                                                                <div className={"wg-dataGridCell avatar"}
                                                                                     key={`wg_gridBodyCell_${column.name}_${rowIndex}_${columnIndex}`}>
                                                                                    <div className={"wg-dataGridCellAvatar"}>
                                                                                        <img
                                                                                            src={row[cell] ? row[cell] : AvatarDefault}
                                                                                            alt={"Avatar"}/>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })
                                                                }

                                                                if (column.type === 'date') {
                                                                    return rowKeys.map(cell => {

                                                                        if (cell === column.name) {
                                                                            return (
                                                                                <div className={"wg-dataGridCell date"}
                                                                                     key={`wg_gridBodyCell_${column.name}_${rowIndex}_${columnIndex}`}>
                                                                                    <div
                                                                                        className={"wg-dataGridCellText"}>{String(row[cell]).toUpperCase()}</div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })
                                                                }

                                                                if (column.type === 'badge') {

                                                                    return rowKeys.map(cell => {

                                                                        if (cell === column.name) {

                                                                            let status = 'neutral';
                                                                            let cellLabel = '';
                                                                            let message = '';

                                                                            if (column.validation) {
                                                                                if (column.validation.type) {

                                                                                    if (column.validation.type === 'date') {

                                                                                        const daysRangeToCheck = column.validation.range ? column.validation.range : 30;

                                                                                        let refDateSplit = ''
                                                                                        if(row[cell]) {
                                                                                            refDateSplit = row[cell].split('/', 3);
                                                                                            const refDate = new Date(`${refDateSplit[1]}/${refDateSplit[0]}/${refDateSplit[2]}`).getTime();

                                                                                            const todayRef = new Date();
                                                                                            const todayDate = new Date(`${todayRef.getMonth() + 1}/${todayRef.getDate()}/${todayRef.getFullYear()}`).getTime();
                                                                                            const checkDate = todayDate + (daysRangeToCheck * 24 * 60 * 60 * 1000);

                                                                                            if (refDate) {
                                                                                                if (refDate < checkDate && refDate > todayDate || refDate === todayDate) {
                                                                                                    status = 'warning';
                                                                                                    message = 'Certificato medico in scadenza'
                                                                                                } else if (refDate > checkDate) {
                                                                                                    status = 'positive';
                                                                                                } else {
                                                                                                    status = 'negative';
                                                                                                    message = 'Certificato medico scaduto'
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        cellLabel = row[cell];
                                                                                    }


                                                                                    if(column.validation.type === 'amount') {

                                                                                        const progress = row[column.validation.progress];
                                                                                        const total = row[column.validation.total];

                                                                                        if(total > 0) {
                                                                                            if(progress === total) {
                                                                                                status = 'positive'
                                                                                            }
                                                                                            if(progress > total) {
                                                                                                status = 'warning'
                                                                                                message = 'Pagato di più del dovuto'
                                                                                            }
                                                                                        }

                                                                                        cellLabel = `${progress} / ${total}`
                                                                                    }
                                                                                }
                                                                            }

                                                                            return (
                                                                                <div className={"wg-dataGridCell badge"}
                                                                                     key={`wg_gridBodyCell_${column.name}_${rowIndex}_${columnIndex}`}>
                                                                                    <div className={"wg-dataGridCellBadge"}>
                                                                                        {
                                                                                            row[cell] ?
                                                                                                <Badge size={'small'} label={cellLabel}
                                                                                                       status={status} message={message}
                                                                                                />
                                                                                                :
                                                                                                '-'
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })
                                                                }

                                                                if (column.type === 'array') {
                                                                    return rowKeys.map(cell => {


                                                                        if (cell === column.name) {
                                                                            return (
                                                                                <div className={"wg-dataGridCell array"}
                                                                                     key={`wg_gridBodyCell_${column.name}_${rowIndex}_${columnIndex}`}>
                                                                                    <div className={"wg-dataGridCellText"}>
                                                                                        {
                                                                                            row[cell] && row[cell].length > 0 ?
                                                                                                row[cell].map(label => {
                                                                                                    return String(label).toLowerCase()
                                                                                                })
                                                                                                :
                                                                                                '-'
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })
                                                                }

                                                                if (column.type === 'group') {

                                                                    return rowKeys.map(cell => {

                                                                        if (cell === column.name) {

                                                                            if(row[cell]) {
                                                                                return (
                                                                                    <div className={'wg-dataGridCell'}
                                                                                         key={`wg_gridBodyCell_${column.name}_${rowIndex}_${columnIndex}`}>
                                                                                        <div className={'wg-cellGroup'}>
                                                                                            <div className={'wg-dataGridCellText'}>
                                                                                                {
                                                                                                    column.groupKeys.title.map(key => {
                                                                                                        return `${String(row[key]).toLowerCase()} `
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                            <div
                                                                                                className={'wg-dataGridCellText description'}>
                                                                                                {
                                                                                                    column.groupKeys.description.map((key, keyIndex) => {
                                                                                                        if (row[key]) {

                                                                                                            if (column.groupKeys.description.length > 1) {
                                                                                                                if (keyIndex === 0) {
                                                                                                                    return (`${String(row[key]).toLowerCase()}, `)
                                                                                                                }
                                                                                                            }

                                                                                                            return (`${String(row[key]).toLowerCase()}`)
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }else{
                                                                                return(
                                                                                    <div className={'wg-dataGridCell'}
                                                                                         key={`wg_gridBodyCell_${column.name}_${rowIndex}_${columnIndex}`}>
                                                                                        -
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        })
                                                    }

                                                    {
                                                        deleteRowCallback ?
                                                            <div className={"wg-dataGridCell deleteRow"}
                                                                 key={`wg_gridBodyCellDeleteButton_${rowIndex}`}>
                                                                <Button
                                                                    type={'icon'}
                                                                    icon={'delete'}
                                                                    label={false}
                                                                    handleClick={() => deleteRowCallback(row)}
                                                                />
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            );
                                        })}
                                    </div>

                                    :
                                    data.length > 0 ?
                                        <NoData />
                                        :

                                        noData ?
                                            <NoData />
                                            : null


                                }

                            </div>
                            :
                            columns && columns.length > 0 ?
                                <div className={"wg-dataGridBody"}>
                                    <div className={'wg-dataGridSkeleton'}>

                                        {
                                            [...Array(skeletonKeys)].map((skeletonKey, skeletonKeyIndex) => {
                                                return (
                                                    <div className={"wg-dataGridRow"}
                                                         key={`wg_gridSkeletonCell_${skeletonKey}_${skeletonKeyIndex}`}>
                                                        {
                                                            columns.map((column, columnIndex) => {
                                                                if (column.type) {

                                                                    if (column.type === 'checkbox') {
                                                                        return (
                                                                            <div
                                                                                className={"wg-dataGridCell checkbox"}
                                                                                key={`wg-gridSkeletonCell_${column.name}_${columnIndex}`}>
                                                                                <div
                                                                                    className={"wg-dataGridCellText"}>
                                                                                    <Skeleton/>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    if (column.type === 'avatar') {
                                                                        return (
                                                                            <div
                                                                                className={"wg-dataGridCell avatar"}
                                                                                key={`wg-gridSkeletonCell_${column.name}_${columnIndex}`}>
                                                                                <div
                                                                                    className={"wg-dataGridCellAvatar"}>
                                                                                    <Skeleton type={'avatar'}/>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    if (column.type === 'text' || column.type === 'date' || column.type === 'badge' || column.type === 'array') {
                                                                        return (
                                                                            <div className={"wg-dataGridCell"}
                                                                                 key={`wg-gridSkeletonCell_${column.name}_${columnIndex}`}>
                                                                                <div
                                                                                    className={'wg-dataGridCellText'}>
                                                                                    <Skeleton/>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    if (column.type === 'group') {
                                                                        return (
                                                                            <div className={"wg-dataGridCell"}
                                                                                 key={`wg-gridSkeletonCell_${column.name}_${columnIndex}`}>
                                                                                <div
                                                                                    className={'wg-dataGridCellText'}>
                                                                                    <Skeleton/>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }


                                                                }
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                                : <div>No columns defined</div>
                    }
                </div>

                <Toolbar
                    selectedRowsIds={selectedRows}
                    visible={selectedRows.length > 0}
                    actions={[
                        {
                            label: 'Deseleziona',
                            icon: 'duplicate',
                            handleClick: deselectAllRows
                        },
                        // {
                        //
                        //     label: 'Esporta',
                        //     icon: 'download',
                        //     handleClick: () => alert('Export selected')
                        // },
                        {

                            label: 'Elimina',
                            icon: 'delete',
                            handleClick: deleteRows
                        }
                    ]}
                />
            </div>
        </React.Fragment>
    )
}