import React, {useEffect, useState} from 'react'

import DirectFetcher from "../../../../../components/services/Fetcher/DirectFetcher";
import {toast} from "react-toastify";
import {useNavigate} from 'react-router-dom'

export const ChangeEmail = () => {

    const SearchParams = new URLSearchParams(window.location.search.substring(1));

    const [initialized, setInitialized] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        if(!initialized) {
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if(initialized) {
            if(SearchParams.has('token')) {
                new DirectFetcher().fetch(
                    '/user/confirm-change-email',
                    'POST',
                    {token: SearchParams.get('token')},
                    null,
                    null,
                    false
                ).then(errors => {

                    if(errors !== false) {
                        toast.success('La tua e-mail è stata aggiornata con successo');
                    }else{
                        toast.error('Non siamo riusciti a verificare la nuova e-mail, riprovare');
                    }

                    navigateToSettings();
                })
            }
        }
    }, [initialized]);

    const navigateToSettings = event => navigate('/settings', { replace: true });

    return (
        <React.Fragment>
            Aggiornamento e-mail in corso, attendere...
        </React.Fragment>
    )
}