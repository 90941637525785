import React from 'react'

export const Badge = (props) => {

    const {
        status = 'neutral',
        size = 'default',
        icon = null,
        label = 'Badge',
        message = ''
    } = props;

    return(
        <div className={`wg-badge ${status} ${size}`}>
            {icon ? <i className={`wi-${icon}`} aria-hidden={"true"} /> : null }
            {label ? label : null }
            {message ? <span className={'wg-badgeTooltip'}>{message}</span> : null}
        </div>
    )
}