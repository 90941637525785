import React, {useEffect} from 'react';

// Import Navbar refs
import NavbarItems from './Navbar.json';
import {NavLink} from 'react-router-dom';
import {act} from "react-dom/test-utils";

export const Navbar = props => {

    const {
        menuCollapsed,
        setMenuCollapsed,
        layoutMode
    } = props;

    const toggleSubMenu = event => {

        const navbarDOM = document.getElementById('wg-navbar');

        if(navbarDOM) {

            if(event.currentTarget) {
                const menuParent = event.currentTarget.parentElement;

                const openedSubMenus = Array.from(navbarDOM.querySelectorAll('.open'));

                if(openedSubMenus.length > 0) {
                    openedSubMenus.map(subMenu => {

                        if(subMenu !== menuParent) {
                            subMenu.classList.remove('open');
                        }
                    })
                }

                menuParent.classList.toggle('open');
            }
        }
    }

    const navigateToRoute = e => {

        const navbarDOM = document.getElementById('wg-navbar');

        if(navbarDOM) {

            /*
                NOTE: wg-subItemParent is a class provided only to menu items with children
             */
            if(!e.currentTarget.classList.contains('wg-subItemParent')) {

                const activeSubMenus = Array.from(navbarDOM.querySelectorAll('.open'));

                if(activeSubMenus.length > 0) {

                    setTimeout(() => {
                        activeSubMenus.map(subMenu => {

                            if(!subMenu.querySelector('.active')) {
                                subMenu.classList.remove('open');
                            }
                        })
                    }, 1)
                }

                handleNavbarClick();
            }
        }
    }

    const handleNavbarClick = () => {

        // Check Breakpoints.less for small tablet
        if(document.documentElement.clientWidth <= 768) {
            const navbarDOM = document.getElementById('wg-navbar');
            const appContentDOM = document.getElementById('wg-appContent');

            if(navbarDOM.classList.contains('wg-navbarCollapsed')) {
                navbarDOM.classList.remove('wg-navbarCollapsed');
            }

            if(appContentDOM.classList.contains('collapsed')) {
                appContentDOM.classList.remove('collapsed');
            }

            setMenuCollapsed(!menuCollapsed);
        }
    }

    useEffect(() => {

        const navbarDOM = document.getElementById('wg-navbar');

        if(navbarDOM) {

            const activeElement = navbarDOM.querySelector('.active');

            if(activeElement) {
                const activeElementSubMenu = activeElement.parentElement.parentElement.parentElement;
                if(activeElementSubMenu.classList.contains('wg-subMenu')) {
                    activeElementSubMenu.classList.add('open');
                }
            }
        }

    }, []);

    useEffect(() => {
        if(menuCollapsed) {
            if(document.querySelectorAll('.open').length > 0) {
                document.getElementById('wg-navbarFakeOverlay').classList.add('visible');
            }
        }
    }, [menuCollapsed])

    return(
        <React.Fragment>
            <div className={`wg-navbar ${menuCollapsed ? 'wg-navbarCollapsed' : ''} ${layoutMode ? layoutMode : ''}`} id={'wg-navbar'}>
                <div className={'wg-navbarFakeOverlay'} id={'wg-navbarFakeOverlay'} onClick={() => toggleSubMenu() } />
                {
                    NavbarItems.length > 0 ?
                        <ul>
                            {
                                NavbarItems.map(item => {
                                    if(item) {
                                        return(
                                            <li
                                                key={item.key}
                                                className={`${item.subItems ? 'wg-subItemParent' : ''}`}
                                                onClick={e => navigateToRoute(e)}
                                            >

                                                {item.subItems && item.subItems.length > 0 ?
                                                    <ul className={'wg-subMenu'}>
                                                        <div className={'wg-menuItem'}
                                                             onClick={(e) => toggleSubMenu(e)}
                                                        >
                                                            {item.icon ? <i className={`wi-${item.icon}`} />  : null}
                                                            <span className={'wg-menuItemLabel'}>{item.label}</span>
                                                            {item.subItems ? <i className={'wg-subItemArrow wi-chevron-right'} aria-hidden={true} /> : null}
                                                            <div className={'wg-subItemsIndicator'} />
                                                        </div>
                                                        <div className={'wg-subItems'}>
                                                            {item.label ? <span className={'wg-subMenuTitle'}>{item.label}</span> : null}
                                                            {
                                                                item.subItems.map(subItem => {
                                                                    return(
                                                                        <li key={subItem.key}>
                                                                            <NavLink
                                                                                to={`/${subItem.route}`}
                                                                                className={`wg-subItem ${subItem.active ? 'active' : ''}`}
                                                                                onClick={e => navigateToRoute(e)}
                                                                            >
                                                                                <span className={'wg-menuItemLabel'}>{subItem.label}</span>
                                                                            </NavLink>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </ul>
                                                    :

                                                    <NavLink
                                                        to={`/${item.route}`}
                                                        onClick={handleNavbarClick}
                                                        className={`${item.active ? 'active' : ''}`}
                                                    >
                                                        {item.icon ? <i className={`wi-${item.icon}`} />  : null}
                                                        <span className={'wg-menuItemLabel'}>{item.label}</span>
                                                        {item.subItems ? <i className={'wi-chevron-down'} aria-hidden={true} /> : null}
                                                    </NavLink>
                                                }
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    :
                        'Errore nel caricamento del menù'
                }
            </div>
        </React.Fragment>
    )
}