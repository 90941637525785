import React, {useEffect, useState} from 'react';

// Import refs
import {Section} from "../../../../components/common/Section/Section";
import {Dropdown} from "../../../../components/common/Fields/Dropdown/Dropdown";
import {Button} from "../../../../components/common/Button/Button";
import useDirectFetcher from "../../../../components/services/Fetcher/DirectFetcher";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Skeleton} from "../../../../components/common/Loader/Skeleton/Skeleton";

export const Preferences = (props) => {

    const {
        data,
        setUserSettings,
        loading
    } = props;

    const [userPreferences, setUserPreferences] = useState({});
    const [savePreferencesProcessing, setSavePreferencesProcessing] = useState(false);

    const { t, i18n } = useTranslation();

    const changeData = (item) => {

        if(item) {
            setUserPreferences(userPreferencesTmp => ({
                ...userPreferencesTmp,
                [item.key]: {
                    value: item.value,
                    error: null
                }
            }))
        }

        if(item.key === 'language') {
            if(String(item.value).toLowerCase() === 'italiano') {
                i18n.changeLanguage('it');
            }else{
                i18n.changeLanguage('en')
            }
        }
    }

    const savePreferencesData = () => {

        const userFormData = new FormData();

        userFormData.append("user", JSON.stringify({
            surname: userPreferences.surname.value,
            name: userPreferences.name.value,
            birth_date: userPreferences.birth_date.value,
            language: userPreferences.language.value,
            theme: userPreferences.theme.value
        }));

        setSavePreferencesProcessing(true);

        new useDirectFetcher().fetch(
            '/user/update',
            'POST',
            userFormData,
            null,
            null,
            true,
            true
        ).then(res => {

            if(res !== false) {


                setUserSettings(userSettingsTmp => ({
                    ...userSettingsTmp,
                    language: {
                        value: userPreferences.language.value,
                        error: null
                    }
                }));

                setUserSettings(userSettingsTmp => ({
                    ...userSettingsTmp,
                    theme: {
                        value: userPreferences.theme.value,
                        error: null
                    }
                }));

                toast.success('Le tue preferenze sono state aggiornate con successo');
            }

            setSavePreferencesProcessing(false);
        });
    }

    useEffect(() => {
        if(Object.keys(data).length > 0) {
            setUserPreferences(data);
        }
    }, [data]);

    return(
        <React.Fragment>
            <Section
                title={'Theme & Language'}
                description={'Choose your favourite theme between light or dark and change application language'}
            >
                <div className={'wg-preferences'}>
                    <div className={'wg-fieldRow'}>
                        {loading ?
                            <React.Fragment>
                                <Skeleton type={'input'} />
                                <Skeleton type={'input'} />
                            </React.Fragment>
                        :
                            <React.Fragment>
                                <Dropdown
                                    id={'theme'}
                                    label={'Theme'}
                                    itemKey={'theme'}
                                    items={['Chiaro', 'Scuro']}
                                    fill={true}
                                    disabled={savePreferencesProcessing}
                                    defaultValue={userPreferences.theme ? userPreferences.theme.value : 'Light'}
                                    handleChange={changeData}
                                />
                                <Dropdown
                                    id={'language'}
                                    label={t('TEST.LANGUAGE_LABEL')}
                                    itemKey={'language'}
                                    items={['Italiano', 'English']}
                                    fill={true}
                                    disabled={savePreferencesProcessing}
                                    defaultValue={userPreferences.language ? data.language.value : 'Italiano'}
                                    handleChange={changeData}
                                />
                            </React.Fragment>
                        }
                    </div>

                    <div className={'wg-preferencesActions'}>
                        <Button
                            label={'Salva preferenze'}
                            loading={savePreferencesProcessing}
                            handleClick={savePreferencesData}
                        />
                    </div>
                </div>
            </Section>
        </React.Fragment>
    );
}