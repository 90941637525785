import React, {useEffect} from 'react'

// Import refs
import {Button} from "../Button/Button";

export const Toolbar = (props) => {

    const {
        selectedRowsIds = [],
        visible,
        actions = []
    } = props;

    useEffect(() => {

        if(visible) {
            document.getElementById('wg-toolbarContainer').classList.add('visible');
        }else{
            document.getElementById('wg-toolbarContainer').classList.remove('visible');
        }
    }, [visible]);

    return(
        <React.Fragment>

            <div className={'wg-toolbarContainer'} id={'wg-toolbarContainer'}>
                <div className={'wg-toolbar'}>
                    <span className={'wg-toolbarSelectedRow'}>
                        {`${selectedRowsIds.length} rig${selectedRowsIds.length > 1 ? 'he' : 'a'} selezionat${selectedRowsIds.length > 1 ? 'e' : 'a'}`}
                    </span>

                    {actions.length > 0 ?
                        <div className={'wg-toolbarActions'}>
                            {actions.map((action, index) => {

                                return (
                                    <Button
                                        type={'reverse'}
                                        label={action.label}
                                        icon={action.icon}
                                        key={`wg-toolbarButton_${index}`}
                                        handleClick={action.handleClick}
                                    />
                                )
                            })}
                        </div>
                    :
                        null
                    }
                </div>
            </div>
        </React.Fragment>
    )
}