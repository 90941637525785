import React, {useEffect, useState} from 'react'
import AvatarDefault from "../../../assets/images/Avatar/Avatar-Default.png";
import {Tab} from "../../../components/common/Tab/Tab";
import {Textfield} from "../../../components/common/Fields/Textfield/Textfield";
import {DataGrid} from "../../../components/common/DataGrid/DataGrid";
import {Button} from "../../../components/common/Button/Button";
import {Dialog} from "../../../components/common/Dialog/Dialog";
import {useFetcher} from "../../../components/services/Fetcher/Fetcher";
import {Checkbox} from "../../../components/common/Fields/Checkbox/Checkbox";
import DirectFetcher from "../../../components/services/Fetcher/DirectFetcher";
import {toast} from "react-toastify";

export const TeamData = props => {

    const {
        data: teamData = null,
        visible = false,
        setVisible,
        listData,
        setListData,
        deleteTeam: deleteTeamCallback
    } = props;

    const [allPlayers, allPlayersLoading] = useFetcher('/players', 'POST', null, true)
    const [allPlayersFilteredList, setAllPlayersFilteredList] = useState([]);

    // Team Data
    const [newTeamData, setNewTeamData] = useState(teamData);
    const [teamPlayers, setTeamPlayers] = useState([]);
    const [teamColorsList, setTeamColorsList] = useState([
        '#8C0FEF',
        '#210FEF',
        '#0FE2EF',
        '#EF0F37',
        '#EF880F',
        '#31A209',
        '#0FEF76',
        '#EFCE0F',
        '#121212'
    ])

    const decorationColor = '#123456';

    const [activeTab, setActiveTab] = useState(0);

    // Dialogs
    const [showDeleteTeamDialog, setShowDeleteTeamDialog] = useState(false);


    // Add Player Datas
    const [addPlayerFilterValue, setAddPlayerFilterValue] = useState(null);
    const [addPlayerDialogVisible, setAddPlayerDialogVisible] = useState(false);

    const [selectedPlayers, setSelectedPlayers] = useState([]);

    const [saveTeamProcessing, setSaveTeamProcessing] = useState(false);


    const [isEdited, setIsEdited] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);

    const closeTeamData = () => {

        if(isEdited) {
            setShowCancelDialog(true);

            document.getElementById('wg-teamData').classList.toggle('visible');
        }else{
            setShowCancelDialog(false);
            setVisible(false);
            setActiveTab(0);
            setNewTeamData({});
            setSelectedPlayers([]);
            setTeamPlayers([]);
        }
    }

    const handleCloseDialog = (close) => {

        setShowCancelDialog(false);
        document.getElementById('wg-teamData').classList.toggle('visible');

        if(close) {
            setVisible(false);
            setActiveTab(0);
            setNewTeamData({});
            setSelectedPlayers([]);
            setTeamPlayers([]);
        }else{
            setVisible(true);
        }

    }

    const toggleAddPlayerDialog = visible => {

        document.getElementById('wg-teamData').classList.toggle('visible');

        if(visible) {
            setAddPlayerDialogVisible(true);
        }else{
            setAddPlayerDialogVisible(false);
            setSelectedPlayers([]);
            setAddPlayerFilterValue(null)
        }
    }

    const closeAddPlayerDialog = () => {

        toggleAddPlayerDialog(false);
    }

    const selectPlayer = (event, selectedPlayer) => {

        if(event.target) {
            const checked = event.target.checked;

            if(checked) {
                setSelectedPlayers(oldSelectedRows => [...oldSelectedRows, selectedPlayer]);
            }else{
                setSelectedPlayers(oldSelectedRows => {
                    return [...oldSelectedRows.filter(player => player.player_id !== selectedPlayer.player_id)]
                });
            }
        }
    }

    const isPlayerChecked = idToCheck => {

        let isChecked = false;

        if(idToCheck) {
            if(selectedPlayers.length > 0) {
                selectedPlayers.map(selectedRow => {
                    if(idToCheck === selectedRow.player_id) {
                        isChecked = true;
                    }
                });
            }
        }

        return isChecked;
    }

    const addPlayersToTeam = () => {

        const oldTeamPlayers = teamPlayers.length > 0 ? teamPlayers : [];

        if(selectedPlayers.map(player => {
            oldTeamPlayers.push(player);
        }))

        setTeamPlayers(oldTeamPlayers);


        toggleAddPlayerDialog(false);
        setSelectedPlayers([]);
    }

    const removePlayerFromTeam = playerToRemove => {

        if(playerToRemove) {

            const newTeamPlayers = [];

             teamPlayers.map(player => {
                if(player.player_id !== playerToRemove.player_id) {
                    return newTeamPlayers.push(player);
                }
            })

            setTeamPlayers(newTeamPlayers);
        }
    }

    const handleDeleteTeamDialog = (open) => {

        setShowDeleteTeamDialog(open);
        document.getElementById('wg-teamData').classList.toggle('visible');

    }

    const deleteTeam = () => {

        new DirectFetcher().fetch(
            `/teams/delete/${newTeamData.team_id}`,
            'DELETE'
        ).then(res => {

            handleDeleteTeamDialog(false);

            if(res !== false) {
                toast.success(`La squadra ${newTeamData.name} è stata eliminata con successo`)
                closeTeamData();
            }
        })

        deleteTeamCallback(newTeamData.team_id);
    }

    const saveTeamData = () => {

        if(!saveTeamProcessing && newTeamData.name) {
            const newTeamPlayers = []

            if(teamPlayers.length > 0) {
                teamPlayers.map(player => {
                    newTeamPlayers.push(player.player_id);
                })
            }

            const newTeamDataToSave = {
                name: newTeamData.name,
                color: newTeamData.color,
                players: newTeamPlayers,
                subscription_fee_amount: newTeamData.subscription_fee_amount
            }

            if(newTeamData.team_id) {
                newTeamDataToSave.team_id = newTeamData.team_id;
            }

            new DirectFetcher().fetch(
                '/teams/save',
                'POST',
                newTeamDataToSave,
                true
            ).then(res => {

                if(res !== false) {

                    if(res.team_id) {
                        setNewTeamData(teamDataTmp => ({
                            ...teamDataTmp,
                            team_id: res.team_id
                        }))
                    }

                    if(newTeamData.team_id) {
                        toast.success(`La squadra ${newTeamData.name} è stata aggiornata con successo`)

                        const listDataTmp = listData;

                        if(listDataTmp.length > 0) {

                            listDataTmp.map((teamToUpdate, index) => {

                                if(teamToUpdate.team_id === newTeamData.team_id) {
                                    listDataTmp[index] = {
                                        "team_id": newTeamData.team_id,
                                        "name": newTeamData.name,
                                        "color": newTeamData.color,
                                        "subscription_fee_amount": newTeamData.subscription_fee_amount,
                                        "players_count": teamPlayers.length > 0 ? teamPlayers.length : '0'
                                    }
                                }
                            })

                            setListData(listDataTmp);
                        }
                    }else{
                        toast.success(`La squadra ${newTeamData.name} è stata aggiunta con successo`);

                        setListData(oldListData => [...oldListData, {
                            "team_id": res.team_id,
                            "name": newTeamData.name,
                            "color": newTeamData.color,
                            "subscription_fee_amount": newTeamData.subscription_fee_amount,
                            "players_count": teamPlayers.length
                        }]);
                    }

                    handleCloseDialog(true);
                    handleDeleteTeamDialog(false);
                }
            })
        }
    }

    useEffect(() => {

        if(addPlayerFilterValue && addPlayerFilterValue.length >= 3) {

            let filterValue = addPlayerFilterValue.toLowerCase();
            filterValue = filterValue.replace(' ', '');

            const tmpFilteredList = [];

            if(allPlayers.length > 0) {
                allPlayers.map(player => {

                    const stringToFilter = player.surname.toLowerCase() + player.name.toLowerCase();

                    if(stringToFilter.includes(filterValue)) {
                        tmpFilteredList.push(player);
                    }
                })

                setAllPlayersFilteredList(tmpFilteredList);

            }
        }else{
            setAllPlayersFilteredList(allPlayers);
        }
    }, [addPlayerFilterValue]);

    useEffect(() => {

        if(allPlayers && allPlayers.length > 0) {
            setAllPlayersFilteredList(allPlayers)
        }
    }, [allPlayers]);

    useEffect(() => {

        setNewTeamData(teamData);

        if(visible) {
            if(teamData.team_id) {

                new DirectFetcher().fetch(
                    '/teams/players',
                    'POST',
                    null,
                    null,
                    teamData.team_id
                ).then(res => {

                    if(res !== false) {
                        setTeamPlayers(res);
                    }
                })
            }else{

                document.getElementById('name').focus();
            }
        }

    }, [visible]);

    useEffect(() => {

        let isDifferent = false;

        Object.keys(newTeamData).map(field => {

            if(newTeamData[field] !== teamData[field]) {
                isDifferent = true;
            }
        })

        setIsEdited(isDifferent);

    }, [newTeamData]);

    return (
        <React.Fragment>
            <div className={`wg-detailPanelDataOverlay ${visible ? 'visible' : ''}`}
                 onClick={closeTeamData}
            />
            <div className={`wg-detailPanelData ${visible ? 'visible' : ''}`} id={'wg-teamData'}>

                <div className={'wg-detailPanelDataHeader'}>
                    <div className={'wg-teamDetailJersey'}>
                        <svg width="40" height="48" viewBox="0 0 282 333" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M55.8004 310.178L54.415 319.972C54.415 319.972 84.4315 332.285 140.678 332.285C196.924 332.285 227.587 319.972 227.587 319.972L226.202 310.178H55.8004Z"
                                fill={decorationColor}/>
                            <path
                                d="M268.553 73.8463C263.29 41.4586 245.563 37.3063 245.563 37.3063L178.994 10.1781L169.946 5.74896L141.232 3.44214L111.964 5.84123L102.916 10.2703L36.3472 37.3985C36.3472 37.3985 18.7125 41.5508 13.3575 73.9386L2.83203 114.723L49.2732 129.671L54.6282 112.14C54.6282 112.14 74.1095 166.95 55.8285 310.065C55.8285 310.065 85.0042 322.153 140.678 322.153C196.352 322.153 226.174 310.065 226.174 310.065C207.893 166.95 227.374 112.14 227.374 112.14L232.729 129.671L279.171 114.723L268.553 73.8463Z"
                                fill={newTeamData.color ? newTeamData.color : '#121212 '}/>
                            <path
                                d="M178.767 20.1266L172.534 5.77238L141.276 3.44214L109.468 5.77238L103.235 20.1266L131.926 50.4197H150.076L178.767 20.1266Z"
                                fill={newTeamData.color}/>
                            <path
                                d="M47.1499 135.236C46.6924 136.598 45.2285 137.325 43.9475 136.871L2.77289 123.706C1.40041 123.252 0.66841 121.799 1.12591 120.528L2.86439 114.899L48.8884 129.607L47.1499 135.236Z"
                                fill={decorationColor}/>
                            <path
                                d="M234.853 135.236C235.31 136.598 236.774 137.325 238.055 136.871L279.23 123.706C280.602 123.252 281.334 121.799 280.877 120.528L279.138 114.899L233.114 129.607L234.853 135.236Z"
                                fill={decorationColor}/>
                            <path
                                d="M174.876 8.81309C174.876 8.81309 161.106 7.04584 140.171 7.04584C119.236 7.04584 105 8.81309 105 8.81309L110.583 2.58122C110.583 2.58122 120.631 1 140.171 1C159.803 1 169.387 2.58122 169.387 2.58122L174.876 8.81309Z"
                                fill={decorationColor}/>
                            <path
                                d="M169.387 2.48821C169.387 2.48821 184.181 14.2078 176.458 26.3925C168.735 38.5772 143.893 58.11 143.893 58.11L140.171 48.5296C140.078 48.5296 182.413 23.6022 169.387 2.48821Z"
                                fill={decorationColor}/>
                            <path
                                d="M111.631 2.52101C111.631 2.52101 96.8537 14.166 104.52 26.2732C112.185 38.3803 136.845 57.7887 136.845 57.7887L141.001 48.2694C141.001 48.2694 98.7009 23.5006 111.631 2.52101Z"
                                fill={decorationColor}/>
                            <path d="M144.686 66.0789H137.317V51.5091L141.213 48.5774H144.686V66.0789Z"
                                  fill={decorationColor}/>
                        </svg>

                    </div>
                    <div className={'wg-detailPanelDataMetaData'}>
                        <div className={'wg-detailPanelDataMetaData'}>
                            {newTeamData && newTeamData.name ? `${newTeamData.name} ` : 'Nome Squadra '}
                        </div>
                        <div className={'wg-playerId'}>
                            {teamPlayers.length ? `${teamPlayers.length} giocator${teamPlayers.length === 1 ? 'e' : 'i'}` : '0 giocatori'}
                        </div>
                    </div>

                    <div className={'wg-mobileCloseButton'}>
                        <Button
                            type={'icon'}
                            icon={'close'}
                            label={false}
                            handleClick={closeTeamData}
                        />
                    </div>
                </div>

                <div className={'wg-detailPanelDataTab'}>
                    <Tab
                        items={['Dati squadra', 'Giocatori']}
                        active={activeTab}
                        handleClick={setActiveTab}
                    />
                </div>

                <div className={'wg-detailPanelDataContent'}>
                    {activeTab === 1 ?
                        <React.Fragment>

                            <div className={'wg-teamPlayersList'}>
                                <DataGrid
                                    data={teamPlayers}
                                    columns={[
                                        {
                                            name: 'photo',
                                            label: '',
                                            type: 'avatar'
                                        },
                                        {
                                            name: 'name',
                                            label: 'Nome',
                                            type: 'group',
                                            groupKeys: {
                                                title: ['surname', 'name'],
                                                description: ['identifier']
                                            }
                                        },
                                        {
                                            name: 'birth_date',
                                            label: 'Data di Nascita',
                                            type: 'date'
                                        },
                                    ]}
                                    deleteRowCallback={removePlayerFromTeam}
                                    skeletonKeys={5}
                                    noData={false}
                                />

                                <Button
                                    type={'link'}
                                    label={'Aggiungi giocatore'}
                                    handleClick={() => toggleAddPlayerDialog(true)}
                                />

                            </div>
                        </React.Fragment>
                    :
                        <React.Fragment>

                            <div className={'wg-detailPanelDataFieldsContainer'}>

                                <div className={'wg-detailPanelDataFields'}>
                                    <div className={'wg-detailPanelDataFieldsRow'}>
                                        <Textfield
                                            id={'name'}
                                            label={'Nome squadra'}
                                            required={'true'}
                                            defaultValue={newTeamData.name}
                                            fill={true}
                                            handleChange={event => setNewTeamData(oldTeamData => ({...oldTeamData, name: event.target.value}))}
                                            handleKeyDown={saveTeamData}
                                        />

                                        <Textfield
                                            label={'Quota d\'iscrizione'}
                                            fill={true}
                                            defaultValue={newTeamData.subscription_fee_amount}
                                            validationType={'amount'}
                                            handleChange={event => setNewTeamData(oldTeamData => ({...oldTeamData, subscription_fee_amount: event.target.value}))}
                                            handleKeyDown={saveTeamData}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={'wg-teamColors'}>

                                <span className={'wg-colorTitle'}>Colore della squadra</span>

                                <div className={'wg-colorList'}>
                                    {teamColorsList.map((color, index) => {

                                        return(
                                            <React.Fragment key={`color_frag_${index}`}>
                                                <div
                                                    className={`wg-teamColor ${color === newTeamData.color ? 'selected' : ''}`}
                                                    key={`color_${index}`}
                                                    style={{backgroundColor: color}}
                                                    onClick={() => setNewTeamData(oldTeamData => ({...oldTeamData, color: color}))}
                                                >
                                                    <div
                                                        className={'wg-activeBullet'}
                                                        style={{backgroundColor: color}}
                                                    >

                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>

                <div className={'wg-detailPanelDataFooter'}>
                    {teamData.team_id ?
                        <div className={'wg-leftButton'}>
                            <Button
                                type={'danger'}
                                label={'Elimina'}
                                handleClick={() => handleDeleteTeamDialog(true)}
                            />
                        </div>
                        :
                        null
                    }
                    <Button
                        type={'secondary'}
                        label={'Annulla'}
                        handleClick={closeTeamData}
                    />
                    <Button
                        label={'Salva squadra'}
                        disabled={!newTeamData.name}
                        loading={saveTeamProcessing}
                        handleClick={saveTeamData}
                    />
                </div>
            </div>

            {
                addPlayerDialogVisible ?
                    <Dialog
                        title={`Aggiungi giocatori a ${newTeamData.name ? newTeamData.name : 'Nuova Squadra'}`}
                        primaryButton={{
                            label: 'Conferma',
                            handleClick: addPlayersToTeam
                        }}
                        secondaryButton={{
                            label: 'Annulla',
                            handleClick: closeAddPlayerDialog
                        }}
                        width={500}
                        dialogVisible={addPlayerDialogVisible}
                        setDialogVisible={closeAddPlayerDialog}
                    >

                        <Textfield
                            disabled={allPlayersLoading}
                            defaultValue={addPlayerFilterValue}
                            label={'Cerca giocatore'}
                            placeholder={'Filtra per nome e cognome'}
                            handleChange={event => setAddPlayerFilterValue(event.currentTarget.value)}
                        />

                        <div className={'wg-searchPlayerList'}>
                            {allPlayersFilteredList.length > 0 ?
                                allPlayersFilteredList.map((player, index) => {

                                    let alreadyAdded = false;

                                    if(teamPlayers.length > 0) {
                                        teamPlayers.map(teamPlayer => {

                                            if(player.player_id === teamPlayer.player_id) {
                                                alreadyAdded = true;
                                            }
                                        })
                                    }

                                    if(!alreadyAdded) {
                                        return (
                                            <div className={'wg-searchPlayerItem'} key={`player_${player.name}_${index}`}>
                                                <div className={'wg-searchPlayerItemContent'}>
                                                    <div className={'wg-searchPlayerItemInfoGroup'}>
                                                        <Checkbox
                                                            label={null}
                                                            id={player.player_id ? player.player_id : `wg-checkbox_${index}`}
                                                            checked={() => isPlayerChecked(player.player_id)}
                                                            handleChange={(event) => selectPlayer(event, player)}
                                                        />
                                                        <div className={'wg-searchPlayerItemAvatar'}>
                                                            <img src={player.photo ? player.photo : AvatarDefault} alt={'Player Avatar'} />
                                                        </div>
                                                        <div className={'wg-searchPlayerItemMeta'}>
                                                            <span>{player.surname} {player.name}</span>
                                                            <span>{player.identifier ? player.identifier : '-'}</span>
                                                        </div>
                                                    </div>

                                                    <div className={'wg-searchPlayerItemMeta'}>
                                                        <span>{player.birth_date}</span>
                                                        <span style={{opacity: 0}}>-</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            : null }
                        </div>
                    </Dialog>
                    : null
            }

            {showCancelDialog ?
                <Dialog
                    title={'Sei sicuro?'}
                    primaryButton={{
                        label: 'Riprendi modifica',
                        handleClick: () => handleCloseDialog(false)
                    }}
                    secondaryButton={{
                        label: 'Esci',
                        handleClick: () => handleCloseDialog(true)
                    }}
                    dialogVisible={showCancelDialog}
                    setDialogVisible={() => setVisible(false)}
                >
                    Se selezioni 'esci' perderai tutte le modifiche effettuate {newTeamData.name ? 'a' : ''}
                    <span
                        style={{
                            textTransform: "capitalize",
                            fontWeight: 600
                        }}
                    >
                        {` ${newTeamData.name ? newTeamData.name : ''}`}
                    </span>
                </Dialog>

                :
                null
            }

            {showDeleteTeamDialog ?
                <Dialog
                    title={`Eliminare ${newTeamData.name ? newTeamData.name : ''}?`}
                    primaryButton={{
                        type: 'danger',
                        label: 'Elimina',
                        handleClick: deleteTeam
                    }}
                    secondaryButton={{
                        label: 'Annulla',
                        handleClick: () => handleDeleteTeamDialog(false)
                    }}
                    dialogVisible={showDeleteTeamDialog}
                    setDialogVisible={() => setShowDeleteTeamDialog(false)}
                >
                    Quest'azione è irreversibile. Tutti i dati della squadra
                    <span
                        style={{
                            textTransform: "capitalize",
                            fontWeight: 600
                        }}
                    >
                        {` ${newTeamData.name ? newTeamData.name : ''} `}
                    </span>

                    andranno persi in modo definitivo
                </Dialog>

                :
                null
            }
        </React.Fragment>
        )
}