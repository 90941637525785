import React from 'react'
import {Button} from "../Button/Button";

export const Tooltip = props => {

    const {
        message = 'ciaone'
    } = props;
    return(
        <div className={'wg-tooltip'}>
            <span>
                {message}
            </span>

        </div>
    )
}