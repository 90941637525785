import React, {useState} from 'react'

export const ContextMenu = (props) => {

    const {
        children,
        width,
        open = true
    } = props;

    const [openMenu, setOpenMenu] = useState(open);

    const toggleContextMenu = event => {

        if(event && event.currentTarget) {
            setOpenMenu(!openMenu);
        }
    }

    if(openMenu) {
        return(
            <React.Fragment>
                <div className={'wg-contextMenu'}
                     style={width ? {width: width} : null}
                >
                    {children}
                </div>

                <div className={'wg-contextMenuFakeOverlay'} id={'wg-contextMenuFakeOverlay'}
                     onClick={e => toggleContextMenu(e)}/>
            </React.Fragment>
        );
    }else{
        return null;
    }
}