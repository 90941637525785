class Cookies {
    static setCookie(name, value, expiration, path = '/') {
        // Set cookie ( expire in 1 week )
        const cookieTime = new Date();
        cookieTime.setTime(cookieTime.getTime() + expiration);

        document.cookie = name + "=" + value + "; expires=" + cookieTime.toUTCString() + ";path=" + path;
    }

    static getCookie(name) {
        name = name + "=";
        const ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }

    static deleteCookie(name) {
        Cookies.setCookie(name, null, null, "");
    }

}

export default Cookies;