import React, {useEffect, useState} from 'react'
import AvatarDefault from "../../../assets/images/Avatar/Avatar-Default.png";
import {Tab} from "../../../components/common/Tab/Tab";
import {Textfield} from "../../../components/common/Fields/Textfield/Textfield";
import {DataGrid} from "../../../components/common/DataGrid/DataGrid";
import {Button} from "../../../components/common/Button/Button";
import {Dialog} from "../../../components/common/Dialog/Dialog";
import {useFetcher} from "../../../components/services/Fetcher/Fetcher";
import {Checkbox} from "../../../components/common/Fields/Checkbox/Checkbox";
import DirectFetcher from "../../../components/services/Fetcher/DirectFetcher";
import {toast} from "react-toastify";
import FieldValidation from "../../../components/utility/Validation/Validation";
import {Dropdown} from "../../../components/common/Fields/Dropdown/Dropdown";
import {Switch} from "../../../components/common/Fields/Switch/Switch";
import {Badge} from "../../../components/common/Badge/Badge";
import {Tooltip} from "../../../components/common/Tooltip/Tooltip";

export const StaffData = props => {

    const {
        data:  userData,
        visible = false,
        setVisible,
        listData,
        setListData,
        deleteUser: deleteUserCallback,
        userRoles,
        userPermissionRoles,
        teams
    } = props;

    // User userData
    const [newUserData, setNewUserData] = useState(userData);
    const [activeUser, setActiveUser] = useState(userData.user_status);

    // Dialogs
    const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);

    const [saveUserEnabled, setSaveUserEnabled] = useState(false);
    const [saveUserProcessing, setSaveUserProcessing] = useState(false);

    const [isEdited, setIsEdited] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);

    const [userTeamIds, setUserTeamIds] = useState([]);
    const [fieldsErrors, setFieldsErrors] = useState({
        confirmation_pending: null,
        identifier: null,
        name: null,
        photo: null,
        role: null,
        role_description: null,
        staff_member_id: null,
        surname: null,
        teams: null,
        user_email: null,
        user_linked: null,
        user_role: null,
        user_role_description: null,
        user_status: null
    })

    const [selectedTeamsValue, setSelectedTeamsValue] = useState([]);

    const closeUserData = () => {

        if(isEdited) {

            setShowCancelDialog(true);
            document.getElementById('wg-userData').classList.toggle('visible');
        }else{

            setShowCancelDialog(false);
            setVisible(false);
            setNewUserData({});
        }
    }

    const closeUserDataWithoutCheck = () => {
        setShowCancelDialog(false);
        setVisible(false);
        setNewUserData({});
    }

    const handleCloseDialog = (close) => {

        setShowCancelDialog(false);
        document.getElementById('wg-userData').classList.toggle('visible');

        if(close) {
            setVisible(false);
            setNewUserData({});
        }else{
            setVisible(true);
        }

    }

    const handleDeleteUserDialog = (open) => {

        setShowDeleteUserDialog(open);
        document.getElementById('wg-userData').classList.toggle('visible');

    }

    const deleteUser = () => {

        new DirectFetcher().fetch(
            `/staff/delete/${newUserData.staff_member_id}`,
            'DELETE',
            null,
            true
        ).then(res => {

            handleDeleteUserDialog(false);

            if(res !== false) {
                toast.success(`L'utente ${newUserData.name} è stato eliminato con successo`)
                closeUserDataWithoutCheck();
            }
        })

        deleteUserCallback(newUserData.staff_member_id);
    }

    const linkStaffMemberWithUser = () => {
        setNewUserData(oldUserData => ({
            ...oldUserData,
            user_linked: !oldUserData.user_linked
        }))
    }

    const saveUserData = () => {

        if(!saveUserProcessing && saveUserEnabled) {

            const userFormData = new FormData();

            const userDataTmp = newUserData;

            userDataTmp.teams = userTeamIds;

            userFormData.append('photo', null);
            userFormData.append('member', JSON.stringify(userDataTmp));

            if(newUserData.staff_member_id) {
                new DirectFetcher().fetch(
                    `/staff/update/${newUserData.staff_member_id}`,
                    'POST',
                    userFormData,
                    true,
                    null,
                    true,
                    true
                ).then(res => {
                    if(res !== false) {

                        if(!res.message) {
                            toast.success(`Il membro ${newUserData.name} ${newUserData.surname} è stato aggiornato con successo ${newUserData.confirmation_pending ? 'ed ha un invito attivo ' : ''}`);

                            const listDataToUpdate = listData;

                            if(listDataToUpdate.length > 0) {
                                listDataToUpdate.map((staffMember, index) => {

                                    if(staffMember.staff_member_id === newUserData.staff_member_id) {
                                        listDataToUpdate[index] = newUserData;

                                        // if(res.photo) {
                                        //     body.photo = res.photo;
                                        //     listDataToUpdate[index]['photo'] = res.photo;
                                        // }

                                    }
                                })
                            }

                            setListData(listDataToUpdate);

                            closeUserDataWithoutCheck();
                        }
                    }
                })
            }else{
                new DirectFetcher().fetch(
                    '/staff/add',
                    'POST',
                    userFormData,
                    true,
                    null,
                    true,
                    true
                ).then(res => {
                    if(res !== false) {
                        if(!res.message) {
                            toast.success(`Il membro ${newUserData.surname} ${newUserData.name} è stato aggiunto ${res.confirmation_pending ? 'ed invitato' : ''} con successo`);

                            setNewUserData(oldUserData => ({
                                ...oldUserData,
                                staff_member_id: res.staff_member_id,
                                confirmation_pending: res.confirmation_pending
                            }))

                            const updatedData = newUserData;
                            updatedData.staff_member_id = res.staff_member_id;
                            updatedData.confirmation_pending =  res.confirmation_pending;

                            setListData(oldListData => [...oldListData, updatedData]);

                            closeUserDataWithoutCheck();
                        }
                    }
                })
            }
        }
    }

    const submitLinkRequest = () => {

        if(newUserData.staff_member_id) {
           new DirectFetcher().fetch(
               `/staff/resend-invitation/${newUserData.staff_member_id}`,
               'GET',
               null,
               true
           ).then(res => {

               if(res !== false) {
                   toast.info('La richiesta è stata inviata');
               }
           })
        }
    }

    const handleChange = event => {

        if(event) {

            const {name, value} = event.target;

            let validation = '';

            if(value && event.target.getAttribute('data-validation')) {
                validation = FieldValidation.field(event.target.getAttribute('data-validation'), value);
            }else{
                validation = {value: value, error: null};
            }

            setFieldsErrors(oldFieldErrors => ({
                ...oldFieldErrors,
                [name]: validation.error
            }))

            setNewUserData(playerDataOld => ({
                ...playerDataOld,
                [name]: value
            }))
        }

    }

    const handleChangeDropdown = event => {

        if(event.key === 'role') {

            if(userRoles.length > 0) {
                userRoles.map(role => {

                    if(role.value === event.value) {

                        setNewUserData(playerDataOld => ({
                            ...playerDataOld,
                            role: role.key,
                            role_description: role.value
                        }))
                    }
                })
            }
        }else if(event.key === 'user_role') {

            if(userPermissionRoles.length > 0) {
                userPermissionRoles.map(role => {

                    if(role.value === event.value) {

                        setNewUserData(playerDataOld => ({
                            ...playerDataOld,
                            user_role: role.key,
                            user_role_description: role.value
                        }))
                    }
                })
            }
        }else if(event.key === 'teams') {

            if(teams.length > 0) {

                const userTeamIdsTmp = [];

                teams.map((team, index) => {

                    const teamToCheck = team.value.toLowerCase().replaceAll(' ', '');
                    const teamsString = event.value.toLowerCase().replaceAll(',', '').replaceAll(' ', '');

                    if(teamsString.includes(teamToCheck)) {
                        userTeamIdsTmp.push(team.key)
                    }
                })

                setUserTeamIds(userTeamIdsTmp);
            }

        }else{
            setNewUserData(playerDataOld => ({
                ...playerDataOld,
                [event.key]: event.value
            }))
        }
    }

    useEffect(() => {

        setNewUserData(userData);

        if(visible) {
            if(!userData.staff_member_id) {
                document.getElementById('surname').focus();
            }
        }

    }, [visible]);

    useEffect(() => {

        let isDifferent = false;

        Object.keys(newUserData).map(field => {

            if(newUserData[field] !== userData[field]) {
                isDifferent = true;
            }
        })

        let valid = true;

        Object.keys(fieldsErrors).map(field => {
            if(fieldsErrors[field]) {
                valid = false;
            }
        })


        if(!newUserData.name || !newUserData.surname || !newUserData.role_description) {
            valid = false;
        }

        if(newUserData.user_linked) {
            if(!newUserData.user_email || !newUserData.user_role_description) {
                valid = false
            }
        }

        setSaveUserEnabled(valid);

        setIsEdited(isDifferent);

    }, [newUserData]);

    useEffect(() => {

        if(userData.teams && userData.teams.length > 0) {
            setSelectedTeamsValue(userData.teams.map(option => option.team_name).join(', '));
            setUserTeamIds(userData.teams.map(option => option.team_id));
        }
    }, [userData]);

    return (
        <React.Fragment>
            <div className={`wg-detailPanelDataOverlay ${visible ? 'visible' : ''}`}
                 onClick={closeUserData}
            />
            <div className={`wg-detailPanelData ${visible ? 'visible' : ''}`} id={'wg-userData'}>

                <div className={'wg-detailPanelDataHeader'}>
                    <div className={'wg-playerAvatar'}>
                        <img src={newUserData.photo ? newUserData.photo : AvatarDefault}
                             alt={'Player avatar'}/>
                    </div>
                    <div className={'wg-detailPanelDataMetaData'}>
                        <div className={'wg-detailPanelDataMetaData'}>
                            {newUserData && newUserData.surname ? `${newUserData.surname} ` : 'Surname '}
                            {newUserData && newUserData.name ? `${newUserData.name} ` : 'Name '}
                        </div>
                        <div className={'wg-playerId'}>
                            {newUserData && newUserData.identifier ? `${newUserData.identifier} ` : '-'}
                        </div>
                    </div>

                    <div className={'wg-mobileCloseButton'}>
                        <Button
                            type={'icon'}
                            icon={'close'}
                            label={false}
                            handleClick={closeUserData}
                        />
                    </div>
                </div>

                <div className={'wg-detailPanelDataContent'}>
                    <div className={'wg-detailPanelDataFieldsContainer'}>

                        <div className={'wg-detailPanelDataFieldsContainerTitle'}>Informazioni Personali</div>

                        <div className={'wg-detailPanelDataFields'}>
                            <div className={'wg-detailPanelDataFieldsRow'}>
                                <Textfield
                                    id={'surname'}
                                    fill={true}
                                    required={true}
                                    label={'Cognome'}
                                    placeholder={'Cognome del collaboratore'}
                                    defaultValue={newUserData.surname}
                                    error={fieldsErrors.surname}
                                    maxLength={64}
                                    validationType={'text'}
                                    handleChange={e => handleChange(e)}
                                    handleKeyDown={saveUserData}
                                />
                                <Textfield
                                    id={'name'}
                                    fill={true}
                                    required={true}
                                    label={'Nome'}
                                    placeholder={'Nome del collaboratore'}
                                    defaultValue={newUserData.name}
                                    error={fieldsErrors.name}
                                    maxLength={64}
                                    validationType={'text'}
                                    handleChange={e => handleChange(e)}
                                    handleKeyDown={saveUserData}
                                />
                            </div>
                            <div className={'wg-detailPanelDataFieldsRow'}>
                                <Textfield
                                    id={'identifier'}
                                    fill={true}
                                    label={'Matricola'}
                                    placeholder={'Matricola del collaboratore'}
                                    defaultValue={newUserData.identifier}
                                    error={fieldsErrors.identifier}
                                    maxLength={16}
                                    validationType={'numeric'}
                                    handleChange={e => handleChange(e)}
                                    handleKeyDown={saveUserData}
                                />
                                <Dropdown
                                    id={'role'}
                                    fill={true}
                                    required={true}
                                    label={'Ruolo'}
                                    defaultValue={newUserData.role_description}
                                    items={userRoles}
                                    itemKey={'role'}
                                    error={fieldsErrors.role}
                                    handleChange={e => handleChangeDropdown(e)}
                                    handleKeyDown={saveUserData}
                                />
                            </div>
                            <div className={'wg-detailPanelDataFieldsRow'}>
                                <Dropdown
                                    id={'teams'}
                                    fill={true}
                                    label={'Squadre'}
                                    defaultValue={selectedTeamsValue}
                                    itemKey={'teams'}
                                    items={teams}
                                    itemAll={true}
                                    selectable={true}
                                    error={fieldsErrors.teams}
                                    handleChange={e => handleChangeDropdown(e)}
                                    handleKeyDown={saveUserData}
                                />
                                <div style={{width: '100%'}}/>
                            </div>

                            <div className={'wg-detailPanelDataFieldsRow keepRow'}>
                                <Switch
                                    id={'link_collaborator_to_user'}
                                    label={'Collega collaboratore ad utente'}
                                    fill={true}
                                    checked={newUserData.user_linked}
                                    handleChange={linkStaffMemberWithUser}
                                />

                                <Button
                                    type={'icon'}
                                    size={'small'}
                                    label={false}
                                    icon={'question_mark'}
                                    hasChildren={true}
                                >
                                    <Tooltip
                                        message={"E' possibile collegare un collaboratore ad un utente tramite indirizzo e-mail. Quando salvi i dati del collabotore per la prima volta, viene inviata una mail all'indirizzo fornito con le indicazioni per completare l'associazione"}
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>

                    {newUserData.user_linked ?
                        <div className={'wg-detailPanelDataFieldsContainer'}>

                            <div className={'wg-detailPanelDataFieldsContainerTitle'}>Informazioni utente</div>

                            <div className={'wg-detailPanelDataFields'}>
                                <div className={'wg-detailPanelDataFieldsRow'}>
                                    <Textfield
                                        id={'user_email'}
                                        fill={true}
                                        required={true}
                                        label={'E-mail utente'}
                                        placeholder={'Inserisci e-mail'}
                                        defaultValue={newUserData.user_email}
                                        error={fieldsErrors.user_email}
                                        maxLength={128}
                                        validationType={'email'}
                                        handleChange={e => handleChange(e)}
                                        handleKeyDown={saveUserData}
                                    />

                                    <Dropdown
                                        id={'user_role_description'}
                                        fill={true}
                                        required={true}
                                        label={'Permessi'}
                                        defaultValue={newUserData.user_role_description}
                                        items={userPermissionRoles}
                                        itemKey={'user_role'}
                                        error={fieldsErrors.user_role}
                                        handleChange={e => handleChangeDropdown(e)}
                                        handleKeyDown={saveUserData}
                                    />
                                </div>

                                <div className={'wg-detailPanelDataFieldsRow fillSpace'}>
                                    {newUserData.confirmation_pending ?
                                        <React.Fragment>
                                            <Badge
                                                label={'In attesa di approvazione'}
                                                status={'warning'}
                                            />
                                            <Button
                                                type={'link'}
                                                label={'Invita di nuovo'}
                                                handleClick={submitLinkRequest}
                                            />
                                        </React.Fragment>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </div>

                <div className={'wg-detailPanelDataFooter'}>
                    {newUserData.staff_member_id ?
                        <div className={'wg-leftButton'}>
                            <Button
                                type={'danger'}
                                label={'Elimina'}
                                handleClick={() => handleDeleteUserDialog(true)}
                            />
                        </div>
                        :
                        null
                    }
                    <Button
                        type={'secondary'}
                        label={'Annulla'}
                        handleClick={closeUserData}
                    />
                    <Button
                        label={'Salva collaboratore'}
                        disabled={!saveUserEnabled}
                        loading={saveUserProcessing}
                        handleClick={saveUserData}
                    />
                </div>
            </div>

            {showCancelDialog ?
                <Dialog
                    title={'Sei sicuro?'}
                    primaryButton={{
                        label: 'Riprendi modifica',
                        handleClick: () => handleCloseDialog(false)
                    }}
                    secondaryButton={{
                        label: 'Esci',
                        handleClick: () => handleCloseDialog(true)
                    }}
                    dialogVisible={showCancelDialog}
                    setDialogVisible={() => setVisible(false)}
                >
                    Se selezioni 'esci' perderai tutte le modifiche effettuate {newUserData.name || newUserData.surname ? 'a' : ''}
                    <span
                        style={{
                            textTransform: "capitalize",
                            fontWeight: 600
                        }}
                    >
                        {` ${newUserData.surname ? newUserData.surname : ''} ${newUserData.name ? newUserData.name : ''}`}
                    </span>
                </Dialog>

                :
                null
            }

            {showDeleteUserDialog ?
                <Dialog
                    title={`Eliminare ${newUserData.name ? newUserData.name : ''}?`}
                    primaryButton={{
                        type: 'danger',
                        label: 'Elimina',
                        handleClick: deleteUser
                    }}
                    secondaryButton={{
                        label: 'Annulla',
                        handleClick: () => handleDeleteUserDialog(false)
                    }}
                    dialogVisible={showDeleteUserDialog}
                    setDialogVisible={() => setShowDeleteUserDialog(false)}
                >
                    Quest'azione è irreversibile. Tutti i dati dell'utente
                    <span
                        style={{
                            textTransform: "capitalize",
                            fontWeight: 600
                        }}
                    >
                        {` ${newUserData.name ? newUserData.name : ''} `}
                    </span>

                    andranno persi in modo definitivo
                </Dialog>

                :
                null
            }
        </React.Fragment>
    )
}