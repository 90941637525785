import React from 'react'
import {Badge} from "../Badge/Badge";

export const Section = props => {

    const {
        children,
        className = "",
        headerComponent = null,
        fillBackground = true,
        title = null,
        description = null,
        width= null,
        counter = null
    } = props;

    return(

        <React.Fragment>
            <div className={`wg-section ${className} ${fillBackground ? "background" : ""}`}
                 style={width ? {width: width} : null}
            >
                {title || counter ?
                    <div className={"wg-sectionHeader"}>
                        <div className={'wg-sectionHeaderTitle'}>
                            <span className={'title'}>{title}</span>
                            {
                                counter ?
                                    <Badge size={'xsmall'} label={counter} />
                                    :
                                    null
                            }
                            <span className={'description'}>{description}</span>
                        </div>

                        {
                            <div className={'wg-sectionHeaderComponent'}>
                                {headerComponent}
                            </div>
                        }
                    </div>
                :
                    null
                }
                <div className={"wg-sectionBody"}>
                    {children}
                </div>
            </div>
        </React.Fragment>
    )
}