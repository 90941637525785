import React, {useEffect, useState} from 'react';

// Import refs
import {Section} from '../../../../components/common/Section/Section';
import {Button} from '../../../../components/common/Button/Button';
import {Textfield} from '../../../../components/common/Fields/Textfield/Textfield';
import {Skeleton} from '../../../../components/common/Loader/Skeleton/Skeleton';
import useDirectFetcher from "../../../../components/services/Fetcher/DirectFetcher";

// Import resources
import AvatarDefault from '../../../../assets/images/Avatar/Avatar-Default.png';
import {toast} from "react-toastify";
import FieldValidation from "../../../../components/utility/Validation/Validation";

export const Profile = (props) => {

    const {
        data,
        setUserSettings,
        loading
    } = props;

    let fieldData = {
        value: null,
        error: null
    }

    const [userData, setUserData] = useState({
        name: fieldData,
        surname: fieldData,
        role: fieldData,
        role_description: fieldData,
        birth_date: fieldData,
        language: fieldData,
        theme: fieldData,
        email: fieldData,
    });
    const [userProfileAvatar, setUserProfileAvatar] = useState(AvatarDefault);
    const [userAvatarBlob, setUserAvatarBlob] = useState(null);
    const [userAvatarToDelete, setUserAvatarToDelete] = useState(false);

    const [saveProfileEnabled, setSaveProfileEnabled] = useState(false);
    const [saveProfileProcessing, setSaveProfileProcessing] = useState(false);

    const handleChange = event => {

        if(event) {

            const {name, value} = event.target;

            let validation = userData[name];

            if(name === "birth_date" && value) {
                validation = FieldValidation.field("date", value);
            }else{
                validation = {value: value, error: null}
            }

            setUserData(userData => ({
                ...userData,
                [name]: {
                    value: value,
                    error: validation.error
                }
            }));


        }
    }

    const uploadNewAvatar = (event) => {

        if(event) {
            if(event && event.target) {

                let srcEncoded;
                const item = event.target.files[0];
                const itemTest = window.URL.createObjectURL(item);
                const resize_width = 120;

                const reader = new FileReader();
                reader.readAsDataURL(item);
                reader.name = item.name;
                reader.size = item.size;
                reader.onload = function(e) {
                    const img = new Image();

                    img.src = itemTest;
                    img.name = item.name;
                    img.size = item.size;
                    img.onload = function(el) {

                        const elem = document.createElement('canvas');
                        const scaleFactor = resize_width / el.target.width;
                        elem.width = resize_width;
                        elem.height = el.target.height * scaleFactor;

                        const ctx = elem.getContext('2d');
                        ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

                        srcEncoded = ctx.canvas.toDataURL(el.target, 'image/jpeg', 0);

                        document.querySelector("#wg-userAvatarEditImg").src = srcEncoded;

                        const dataURLToBlob = function(dataURL) {
                            const BASE64_MARKER = ';base64,';
                            if (dataURL.indexOf(BASE64_MARKER) == -1) {
                                const parts = dataURL.split(',');
                                const contentType = parts[0].split(':')[1];
                                const raw = parts[1];

                                return new Blob([raw], {type: contentType});
                            }

                            const parts = dataURL.split(BASE64_MARKER);
                            const contentType = parts[0].split(':')[1];
                            const raw = window.atob(parts[1]);
                            const rawLength = raw.length;

                            const uInt8Array = new Uint8Array(rawLength);

                            for (let i = 0; i < rawLength; ++i) {
                                uInt8Array[i] = raw.charCodeAt(i);
                            }

                            return new Blob([uInt8Array], {type: contentType});
                        }

                        srcEncoded = dataURLToBlob(srcEncoded);
                        setUserAvatarBlob(srcEncoded);
                        setUserAvatarToDelete(false);
                        setUserProfileAvatar(itemTest)

                        event.target.value = '';
                    }
                }
            }
        }
    }

    const deleteAvatar = () => {

        setUserData(userData => ({
            ...userData,
            avatar: {
                value: AvatarDefault,
                error: null
            }
        }));

        setUserProfileAvatar(AvatarDefault);
        setUserAvatarBlob(null);
        setUserAvatarToDelete(true);
    }

    const updateHeader = () => {

        const nameDOM = document.getElementById('wg-userData_name');
        const surnameDOM = document.getElementById('wg-userData_surname');
        const avatarDOM = document.getElementById('wg-userData_avatar');

        if(nameDOM) {
            nameDOM.innerText = userData.name.value
        }

        if(surnameDOM) {
            surnameDOM.innerText = userData.surname.value
        }

        if(avatarDOM) {
            if(userData.avatar) {
                if(userData.avatar.value) {
                    avatarDOM.src = userData.avatar.value;
                }else{
                    avatarDOM.src = AvatarDefault;
                }
            }else{
                avatarDOM.src = AvatarDefault;
            }
        }
    }

    const saveProfileData = () => {

        if(saveProfileEnabled) {
            setSaveProfileProcessing(true);

            const userFormData = new FormData();

            if(userAvatarBlob) {
                userFormData.append('avatar', userAvatarBlob);
            }

            if(userData) {
                userFormData.append("user", JSON.stringify({
                    surname: userData.surname.value,
                    name: userData.name.value,
                    birth_date: userData.birth_date.value,
                    language: userData.language.value,
                    theme: userData.theme.value
                }));
            }

            if(userAvatarToDelete) {
                userFormData.append('deleteAvatar', true);
            }

            if(saveProfileEnabled) {

                new useDirectFetcher().fetch(
                    '/user/update',
                    'POST',
                    userFormData,
                    null,
                    null,
                    true,
                    true
                ).then(res => {
                    if(res !== false) {
                        const userDataTmp = {
                            ...userData
                        }

                        if(res.avatar) {
                            userDataTmp.avatar.value = res.avatar
                        }

                        setUserData(userDataTmp);
                        setUserSettings(userDataTmp);
                        updateHeader();

                        toast.success('Il tuo profilo è stato aggiornato con successo');
                    }

                    setSaveProfileProcessing(false)
                })
            }
        }
    }

    useEffect(() => {
        if(Object.keys(data).length > 0) {
            setUserData(data);

            if(data.avatar) {
                if(data.avatar.value) {
                    setUserProfileAvatar(data.avatar.value);
                }
            }
        }
    }, [data]);

    useEffect(() => {
        if(userData.name.value && userData.surname.value && userData.role_description.value) {
            if(!userData.name.error && !userData.surname.error && !userData.role_description.error && !userData.birth_date.error) {
                setSaveProfileEnabled(true);
            }else{
                setSaveProfileEnabled(false);
            }
        }else{
            setSaveProfileEnabled(false);
        }
    }, [userData]);

    return(
        <React.Fragment>
            <Section
                title={'Informazioni personali'}
                description={'Queste sono le tue informazioni personali che permettono alla società di riconoscerti'}
            >
                <div className={'wg-profilePersonalInformation'}>
                    <div className={'wg-avatarEdit'}>

                        {
                            loading ?
                            <Skeleton type={'avatar'} width={96} height={96} />
                                :
                                <div className={'wg-avatarContainer'}>
                                    <img src={userProfileAvatar}
                                         id={'wg-userAvatarEditImg'}
                                         alt={'User Avatar'}
                                         onClick={() => document.getElementById('wg-playerAvatarUploader').click()}
                                    />
                                </div>
                        }

                        {
                            !loading ?
                                <div className={'wg-avatarEditActions'}>
                                    <input
                                        type={'file'}
                                        id={'wg-playerAvatarUploader'}
                                        accept={'.jpg, .jpeg, .png'}
                                        hidden={true}
                                        disabled={saveProfileProcessing}
                                        onChange={(event) => uploadNewAvatar(event) }
                                    />
                                    <Button
                                        type={'secondary'}
                                        label={'Carica nuovo avatar'}
                                        disabled={saveProfileProcessing}
                                        handleClick={() => document.getElementById('wg-playerAvatarUploader').click()}
                                    />
                                    <Button
                                        type={'secondary'}
                                        label={'Elimina'}
                                        disabled={saveProfileProcessing}
                                        handleClick={deleteAvatar}
                                    />
                                </div>
                            :
                                null

                        }
                    </div>

                    <div className={'wg-fieldEdit'}>

                        { loading ?
                            <React.Fragment>
                                <div className={'wg-fieldRow'}>
                                    <Skeleton type={'input'} />
                                    <Skeleton type={'input'} />
                                </div>
                                <div className={'wg-fieldRow'}>
                                    <Skeleton type={'input'} />
                                    <Skeleton type={'input'} />
                                </div>
                                <div className={'wg-fieldRow'}>
                                    <Skeleton type={'input'} />
                                    <Skeleton type={'input'} />
                                </div>
                            </React.Fragment>
                        :
                            <React.Fragment>
                                <div className={'wg-fieldRow'}>
                                    <Textfield
                                        id={'name'}
                                        label={'Nome'}
                                        defaultValue={userData.name.value}
                                        placeholder={'Il tuo nome'}
                                        maxLength={32}
                                        required={true}
                                        fill={true}
                                        error={userData.name.error}
                                        disabled={saveProfileProcessing}
                                        handleChange={e => handleChange(e)}
                                        handleKeyDown={saveProfileData}
                                    />
                                    <Textfield
                                        id={'surname'}
                                        label={'Cognome'}
                                        defaultValue={userData.surname.value}
                                        placeholder={'Il tuo cognome'}
                                        maxLength={32}
                                        required={true}
                                        fill={true}
                                        error={userData.surname.error}
                                        disabled={saveProfileProcessing}
                                        handleChange={e => handleChange(e)}
                                        handleKeyDown={saveProfileData}
                                    />
                                </div>
                                <div className={'wg-fieldRow'}>
                                    <Textfield
                                        id={'role_description'}
                                        label={'Ruolo'}
                                        defaultValue={userData.role_description.value}
                                        placeholder={'Il tuo ruolo'}
                                        maxLength={0}
                                        required={true}
                                        fill={true}
                                        disabled={true}
                                        error={userData.role_description.error}
                                        handleChange={e => handleChange(e)}
                                        handleKeyDown={saveProfileData}
                                    />
                                    <Textfield
                                        id={'birth_date'}
                                        label={'Data di nascita'}
                                        defaultValue={userData.birth_date.value}
                                        placeholder={'dd/mm/yyyy'}
                                        maxLength={10}
                                        fieldType={'date'}
                                        fill={true}
                                        disabled={saveProfileProcessing}
                                        error={userData.birth_date.error}
                                        validationType={'date'}
                                        handleChange={e => handleChange(e)}
                                        handleKeyDown={saveProfileData}
                                    />
                                </div>
                            </React.Fragment>
                        }

                    </div>

                    <div className={'wg-profilePersonalInformationActions'}>
                        <Button
                            label={'Salva profilo'}
                            disabled={!saveProfileEnabled}
                            loading={saveProfileProcessing}
                            handleClick={saveProfileData}
                        />
                    </div>
                </div>
            </Section>
        </React.Fragment>
    );
}