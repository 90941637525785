import React, {useEffect, useState} from 'react';
import {Navigate, Outlet, useNavigate} from 'react-router-dom';

// Import components refs
import {Header} from '../common/Header/Header';
import {Navbar} from '../common/Navbar/Navbar';
import CookieManager from '../utility/Cookies/Cookies';

export const PrivateRoutes = props => {

    const {
        appReady,
        layoutMode,
        setLayoutMode,
        theme,
        setTheme
    } = props;

    const navigate = new useNavigate();

    const [userRole, setUserRole] = useState(null);

    useEffect(() => {

        if(!CookieManager.getCookie('LOGGEDIN') && !CookieManager.getCookie('REMEMBER_ME_TOKEN')) {
            navigate('/login');
        }

        if(localStorage.getItem('layout')) {
            setLayoutMode(localStorage.getItem('layout'));
        }

        window.scrollTo(0,0);
    }, [navigate]);

    useEffect(() => {

        if(localStorage.getItem('layoutMode')) {
            setLayoutMode(localStorage.getItem('layoutMode'));
        }

        if(localStorage.getItem('theme')) {
            setTheme(localStorage.getItem('theme'));
        }
    }, []);


    const [menuCollapsed, setMenuCollapsed] = useState(false);

    return(
        <React.Fragment>
            <Header
                appReady={appReady}
                menuCollapsed={menuCollapsed}
                setMenuCollapsed={setMenuCollapsed}
                setUserRole={setUserRole}
                layoutMode={layoutMode}
                setLayoutMode={setLayoutMode}
                theme={theme}
                setTheme={setTheme}
            />
            <Navbar
                menuCollapsed={menuCollapsed}
                setMenuCollapsed={setMenuCollapsed}
                layoutMode={layoutMode}
            />
            <div className={`wg-appContent ${menuCollapsed ? 'collapsed' : ''}`} id={'wg-appContent'}>
                <Outlet context={[{
                    appReady: appReady,
                    userRole: userRole
                }]} />
            </div>
        </React.Fragment>
    )
}