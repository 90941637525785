import React from 'react'

export const Card = props => {

    const {
        handleClick,
        children
    } = props;

    return(
        <div className={'wg-card'} onClick={handleClick}>
            {children}
        </div>
    );
}