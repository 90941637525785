import React, {useEffect, useState} from "react"

export const Button = props => {

    const {
        children,
        type = "solid",
        label = "Button",
        size= 'standard',
        icon = null,
        disabled = false,
        loading = false,
        visible = true,
        tabIndex= null,
        hasChildren = false,
        handleClick
    } = props;

    const [hasChildrenVisible, setHasChildrenVisible] = useState(false);

    const handleKeyDown = event => {

        if(event && !disabled) {
            if(event.key) {
                if(event.key === "Enter") {
                    handleClick(event);
                }
            }
        }
    }

    const onClick = () => {

        if(handleClick) {
            handleClick();
        }

        if(hasChildren) {
            setHasChildrenVisible(!hasChildrenVisible);
        }
    }

    return(
        <React.Fragment>
            {visible ?
                <div className={`wg-button ${disabled ? "disabled" : ""} ${loading ? "loading" : ""} ${size} ${type} ${hasChildrenVisible ? 'pressed' : ""}`}
                     tabIndex={tabIndex}
                     onKeyUp={handleKeyDown ? e => handleKeyDown(e) : null}
                     onClick={onClick}
                     style={type === 'link' ? {textTransform: "inherit"} : null}
                >
                    {loading ? <i className={'wg-loadingIcon wi-soccer_ball'} aria-hidden={"true"} /> : null}
                    {icon ?
                        <i className={`wi-${icon}`} aria-hidden={true} >
                            <span className={'path1'} />
                            <span className={'path2'} />
                            <span className={'path3'} />
                            <span className={'path4'} />
                            <span className={'path5'} />
                            <span className={'path6'} />
                            <span className={'path7'} />
                            <span className={'path8'} />
                            <span className={'path9'} />
                            <span className={'path10'} />
                        </i>
                    :
                        null
                    }
                    {label}

                    {
                        hasChildren ?
                            hasChildrenVisible ?
                                children
                                :
                                null
                            :
                        children
                    }
                </div>
            :
                null
            }
        </React.Fragment>
    )
}