import React, {useEffect, useState} from 'react'

import AvatarDefault from "../../../../assets/images/Avatar/Avatar-Default.png";
import {Button} from "../../../../components/common/Button/Button";
import {LineChart} from "../../../../components/common/Charts/LineChart/LineChart";
import {Textfield} from "../../../../components/common/Fields/Textfield/Textfield";
import {useFetcher} from "../../../../components/services/Fetcher/Fetcher";
import DirectFetcher from "../../../../components/services/Fetcher/DirectFetcher";
import {Dialog} from "../../../../components/common/Dialog/Dialog";
import {Dropdown} from "../../../../components/common/Fields/Dropdown/Dropdown";
import FieldValidation from "../../../../components/utility/Validation/Validation";
import {toast} from "react-toastify";


export const PlayerData = (props) => {

    const {
        data: playerData = null,
        listData,
        visible = false,
        setListData,
        updateChartsData,
        setVisible
    } = props;

    const fieldData = {
        value: '',
        error: ''
    }

    const [newPlayerData, setNewPlayerData] = useState({});
    const [newFeeData, setNewFeeData] = useState({
        date: fieldData,
        amount: fieldData,
        type: fieldData,
        description: fieldData
    })
    const [feeList, setFeeList] = useState([]);
    const [feeDialogVisible, setFeeDialogVisible] = useState(false);
    const [saveFeeButtonEnabled, setSaveFeeButtonEnabled] = useState(false);
    const [deleteFeeButtonVisible, setDeleteFeeButtonVisible] = useState(false);

    const [originalFeeValue, setOriginalFeeValue] = useState(0);
    const [totalPayed, setTotalPayed] = useState(0);


    const showAddFeeDialog = (fee, feeIndex) => {

        setFeeDialogVisible(true);
        document.getElementById('wg-playerData').classList.remove('visible');

        if(fee) {

            const feeData = {
                date: {error: null, value: fee.date},
                amount: {error: null, value: fee.amount},
                type: {error: null, value: fee.type},
                description: {error: null, value: fee.description},
            }

            if(fee.payment_id) {
                feeData.payment_id = {error: null, value: fee.payment_id}
            }else{
                feeData.payment_index = {error: null, value: feeIndex}
            }

            setDeleteFeeButtonVisible(true);
            setNewFeeData(feeData);
            setOriginalFeeValue(feeData.amount);
        }else{
            setDeleteFeeButtonVisible(false);
        }
    }
    const closeFeeDialog = () => {

        setFeeDialogVisible(false);
        setNewFeeData({
            date: '',
            amount: '',
            type: '',
            description: ''
        });

        document.getElementById('wg-playerData').classList.add('visible');
    }

    const deleteFee = () => {

        const feeData = {
            date: newFeeData.date.value,
            amount: newFeeData.amount.value,
            type: newFeeData.type.value,
            description: newFeeData.description.value ? newFeeData.description.value : ''
        }

        let key;

        if(newFeeData.payment_id) {
            feeData.payment_id = newFeeData.payment_id.value
            key = 'payment_id'
        }

        if(newFeeData.payment_index) {
            feeData.payment_index = newFeeData.payment_index.value
            key = 'payment_index'
        }

        const feeListToUpdate = [];

        if(feeList.length > 0) {
            feeList.map((fee, feeIndex) => {

                if(fee[key] !== newFeeData[key].value) {
                    feeListToUpdate.push(fee)
                }
            });
        }

        let total;

        feeListToUpdate.map(fee => {
            total = total + Number(fee.amount);
        })

        setTotalPayed(total ? total : 0);

        setFeeList(feeListToUpdate);
        closeFeeDialog();
    }

    const addNewFeeToList = () => {

        const feeData = {
            date: newFeeData.date.value,
            amount: newFeeData.amount.value,
            type: newFeeData.type.value,
            description: newFeeData.description.value ? newFeeData.description.value : ''
        }

        if(newFeeData.payment_id) {
            feeData.payment_id = newFeeData.payment_id.value

            const feeListToUpdate = feeList;

            if(feeListToUpdate.length > 0) {
                feeListToUpdate.map((fee, feeIndex) => {
                    if(fee.payment_id === newFeeData.payment_id.value) {
                        feeListToUpdate[feeIndex] = feeData;
                    }
                })

                setFeeList(feeListToUpdate);
            }
        }else if(newFeeData.payment_index) {

            const feeListToUpdate = feeList;

            if(feeListToUpdate.length > 0) {
                feeListToUpdate.map((fee, feeIndex) => {

                    if(fee.payment_index) {
                        if(feeIndex === newFeeData.payment_index.value) {
                            feeListToUpdate[feeIndex] = feeData;
                        }
                    }
                })

                setFeeList(feeListToUpdate);
            }
        }else{

            feeData.payment_index = feeList.length;
            setFeeList([feeData, ...feeList]);
        }

        let total = 0;

        feeList.map(fee => {
            total = total + Number(fee.amount);
        })

        setTotalPayed(total);

        closeFeeDialog();
    }

    const handleChange = event => {

        if(event) {

            const {name, value} = event.target;

            let validation = '';

            if(value && event.target.getAttribute('data-validation')) {
                validation = FieldValidation.field(event.target.getAttribute('data-validation'), value);
            }else{
                validation = {value: value, error: null};
            }

            // if(name === 'amount') {
            //
            //     const formattedValue = value.replace(/,/g, '');
            //
            //     // Importo che sto inserendo ora
            //     const currentFee = Number(formattedValue);
            //
            //     // Somma di tutte le rate
            //     let totalPayedTmp = playerData.subscription_fee_amount_paid;
            //
            //     // QUanto devo pagare
            //     const toPay = playerData.subscription_fee_amount;
            //
            //     if(newFeeData.payment_id.value) {
            //         totalPayedTmp -= Number(originalFeeValue.value);
            //     }
            //
            //     // if(currentFee + totalPayedTmp > toPay) {
            //     //     validation = {value: value, error: 'La rata supera il totale da pagare'}
            //     // }
            // }

            setNewFeeData(oldFeeData => ({
                ...oldFeeData,
                [name]: validation
            }))
        }
    }

    const handleChangeTotalAmount = event => {

        if(event) {

            const {name, value} = event.target;

            setNewPlayerData(oldPlayerData => ({
                ...oldPlayerData,
                [name]: value
            }))
        }
    }

    const handleChangeDropdown = event => {

        if(event.value) {
            setNewFeeData(playerDataOld => ({
                ...playerDataOld,
                [event.key]: {
                    error: null,
                    value: event.value
                }
            }))
        }
    }

    const savePlayerData = () => {

        const feeListToUpdate = feeList;

        if(feeListToUpdate.length > 0) {
            feeListToUpdate.map(fee => {
                Object.keys(fee).map(key => {
                     fee[key] = String(fee[key]);
                })

                if(fee.payment_index) {
                    delete fee.payment_index;
                }
            })
        }

        new DirectFetcher().fetch(
            `/subscription-fee/payments/players/${newPlayerData.player_id}`,
            'PUT',
            {
                subscription_fee_amount: String(newPlayerData.subscription_fee_amount),
                payments: feeList
            },
            true
        ).then(res => {

            if(res !== false) {

                const listDataToUpdate = listData;

                if(listDataToUpdate.length > 0) {

                    let totalPaid = 0;

                    listDataToUpdate.map((player, index) => {

                        if(player.player_id === newPlayerData.player_id) {

                            listDataToUpdate[index].subscription_fee_amount = newPlayerData.subscription_fee_amount;

                            if(feeList.length > 0) {
                                feeList.map(fee => {
                                    totalPaid = totalPaid + Number(fee.amount);
                                })
                            }

                            listDataToUpdate[index].subscription_fee_amount_paid = totalPaid;
                        }
                    })

                    setListData(listDataToUpdate);
                    setVisible(false);
                }
                toast.success(`Il giocatore ${newPlayerData.surname} ${newPlayerData.name} è stato aggiornato con successo`);

                new DirectFetcher().fetch(
                    '/subscription-fee/charts',
                    'GET',
                    null,
                    true
                ).then(res => {

                    if(res !== false) {
                        updateChartsData(res);
                    }
                })
            }
        })
    }

    useEffect(() => {

        if(playerData) {
            setNewPlayerData(playerData)
        }

    }, [playerData]);

    useEffect(() => {

       if(visible) {
           new DirectFetcher().fetch(
               `/subscription-fee/payments/players/${playerData.player_id}`,
               'GET',
               null,
               true
           ).then(res => {

               if(res !== false) {

                   if(res.payments) {
                       setFeeList(res.payments);
                   }
               }
           })
       }
    }, [newPlayerData.player_id]);

    useEffect(() => {

        if(newFeeData.date.value && newFeeData.amount.value && newFeeData.type.value && !newFeeData.date.error && !newFeeData.amount.error && !newFeeData.type.error) {
            setSaveFeeButtonEnabled(true);
        }else{
            setSaveFeeButtonEnabled(false);
        }
    }, [newFeeData]);

    useEffect(() => {

        if(feeList.length > 0) {

            let total = 0;

            feeList.map(fee => {
                total = total + Number(fee.amount);
            })

            setTotalPayed(total);
        }
    }, [feeList]);

    useEffect(() => {

        if(!visible) {
            setTotalPayed(0)
            setFeeList([]);
        }

    }, [visible]);

    return(
        <React.Fragment>
            <div className={`wg-detailPanelDataOverlay ${visible ? 'visible' : ''}`}
                 onClick={() => setVisible(false)}
            />
            <div className={`wg-detailPanelData wg-detailPanelDataPayments ${visible ? 'visible' : ''}`} id={'wg-playerData'}>

                <div className={'wg-detailPanelDataHeader'}>
                    <div className={'wg-playerAvatar'}>
                        <img src={newPlayerData.photo ? newPlayerData.photo : AvatarDefault}
                             alt={'Player avatar'}/>
                    </div>
                    <div className={'wg-detailPanelDataMetaData'}>
                        <div className={'wg-detailPanelDataMetaData'}>
                            {newPlayerData && newPlayerData.surname ? `${newPlayerData.surname} ` : 'Surname '}
                            {newPlayerData && newPlayerData.name ? `${newPlayerData.name} ` : 'Name '}
                        </div>
                        <div className={'wg-playerId'}>
                            {newPlayerData && newPlayerData.identifier ? `${newPlayerData.identifier} ` : '-'}
                        </div>
                    </div>

                    <div className={'wg-mobileCloseButton'}>
                        <Button
                            type={'icon'}
                            icon={'close'}
                            label={false}
                            handleClick={() => setVisible(false)}
                        />
                    </div>
                </div>

                <div className={'wg-detailPanelDataContent'}>
                    <div className={'wg-widgetChartContainer'}>
                        <div className={'wg-widgetChartContent'}>
                            <div className={'wg-widgetChartContentItem'}>
                                <span className={'wg-widgetChartContentItemDescription'}>Pagato</span>
                                <span className={'wg-widgetChartContentItemAmount'}>{`${totalPayed} €`}</span>
                            </div>
                            <div className={'wg-widgetChartContentItem'}>
                                <span className={'wg-widgetChartContentItemDescription'}>Obbiettivo</span>
                                <span className={'wg-widgetChartContentItemAmount'}>{`${newPlayerData.subscription_fee_amount} €`}</span>
                            </div>
                        </div>

                        <LineChart
                            type={'progress'}
                            data={{
                                amount: totalPayed,
                                total: newPlayerData.subscription_fee_amount
                            }}
                        />

                        <div className={'wg-widgetChartContentFooter'}>
                            {`Da ricevere: ${newPlayerData.subscription_fee_amount - totalPayed > 0 ? newPlayerData.subscription_fee_amount - totalPayed : 0} €`}
                        </div>
                    </div>

                    <div className={'wg-fields'}>
                        <Textfield
                            id={'subscription_fee_amount'}
                            label={'Quota da pagare'}
                            required={true}
                            fill={true}
                            placeholder={'Inserisci quota'}
                            defaultValue={newPlayerData.subscription_fee_amount}
                            error={''}
                            maxLength={24}
                            validationType={'amount'}
                            handleChange={e => handleChangeTotalAmount(e)}
                            handleKeyDown={savePlayerData}
                        />
                    </div>

                    <div className={'wg-feeList'}>

                        {feeList.map((fee, feeIndex) => {

                            return(
                                <div className={'wg-fee'}
                                     onClick={() => showAddFeeDialog(fee, fee.payment_index ? fee.payment_index : feeIndex)}
                                     key={`wg-fee_${feeIndex}`}

                                >
                                    <div className={'wg-feeRow'}>
                                        <span className={'type'}>{`#${feeIndex + 1} - ${fee.type}`}</span>
                                        <span className={'amount'}>{`${fee.amount} €`}</span>
                                    </div>
                                    <div className={'wg-feeRow'}>
                                        <span className={'description'}>{fee.description ? fee.description : '-'}</span>
                                        <span className={'date'}>{fee.date}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <Button
                        type={'link'}
                        label={'Aggiungi rata'}
                        handleClick={showAddFeeDialog}
                    />
                </div>

                <div className={'wg-detailPanelDataFooter'}>
                    <Button
                        type={'secondary'}
                        label={'Annulla'}
                        handleClick={() => setVisible(false)}
                    />
                    <Button
                        label={'Salva rate'}
                        disabled={!newPlayerData.subscription_fee_amount}
                        loading={false}
                        handleClick={savePlayerData}
                    />
                </div>
            </div>

            {feeDialogVisible ?
                <Dialog
                    title={'Aggiungi nuova rata'}
                    primaryButton={{
                        type: 'primary',
                        label: 'Conferma',
                        disabled: !saveFeeButtonEnabled,
                        handleClick: addNewFeeToList,
                        handleKeyDown: addNewFeeToList
                    }}
                    secondaryButton={{
                        label: 'Annulla',
                        handleClick: closeFeeDialog
                    }}
                    tertiaryButton={{
                        type: 'danger',
                        label: 'Elimina',
                        leftAlign: true,
                        visible: deleteFeeButtonVisible,
                        handleClick: deleteFee
                    }}
                    dialogVisible={feeDialogVisible}
                    setDialogVisible={closeFeeDialog}
                    width={500}
                >
                    <div className={'wg-fieldsRow'}>
                        <Textfield
                            id={'date'}
                            fill={true}
                            required={true}
                            label={'Data di pagamento'}
                            placeholder={'gg/mm/aaaa'}
                            defaultValue={newFeeData.date.value}
                            error={newFeeData.date.error}
                            maxLength={10}
                            validationType={'date'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={addNewFeeToList}
                        />
                        <Textfield
                            id={'amount'}
                            fill={true}
                            required={true}
                            label={'Importo'}
                            placeholder={'Importo della rata'}
                            defaultValue={newFeeData.amount.value}
                            error={newFeeData.amount.error}
                            maxLength={24}
                            validationType={'amount'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={addNewFeeToList}
                        />
                    </div>

                    <div className={'wg-fieldsRow'}>
                        <Dropdown
                            id={'type'}
                            fill={true}
                            required={true}
                            label={'Metodo di pagamento'}
                            placeholder={'Seleziona'}
                            defaultValue={newFeeData.type.value}
                            itemKey={'type'}
                            items={[
                                {
                                    key: 'pos',
                                    value: 'POS'
                                },
                                {
                                    key: 'contanti',
                                    value: 'Contanti'
                                },
                                {
                                    key: 'bonifico',
                                    value: 'Bonifico'
                                },
                                {
                                    key: 'satispay',
                                    value: 'Satispay'
                                }
                            ]}
                            resetSelection={true}
                            handleChange={e => handleChangeDropdown(e)}
                        />
                        <Textfield
                            id={'description'}
                            fill={true}
                            label={'Descrizione'}
                            placeholder={'es. Pagamento prima rata'}
                            defaultValue={newFeeData.description.value}
                            error={newFeeData.description.error}
                            maxLength={64}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={addNewFeeToList}
                        />
                    </div>
                </Dialog>

                :
                null
            }
        </React.Fragment>
    );
}