import React, {useEffect, useState} from 'react'

// Import refs
import {Section} from '../../../components/common/Section/Section';
import {Profile} from './Profile/Profile';
import {Security} from './Security/Security';
import {Preferences} from './Preferences/Preferences';
import {useFetcher} from "../../../components/services/Fetcher/Fetcher";
import Cookies from "../../../components/utility/Cookies/Cookies";
import {useTranslation} from "react-i18next";
import DirectFetcher from "../../../components/services/Fetcher/DirectFetcher";

export const Settings = () => {

    const [data, loading= true, error] = useFetcher('/user', 'GET');

    let fieldData = {
        value: null,
        error: null
    }


    const [userSettings, setUserSettings] = useState({
        name: fieldData,
        surname: fieldData,
        role: fieldData,
        role_description: fieldData,
        birth_date: fieldData,
        email: fieldData,
        language: fieldData,
        theme: fieldData
    });

    const [selectedMenu, setSelectedMenu] = useState(0);

    const { t, i18n } = useTranslation();

    const handleLogout = () => {
        const token = Cookies.getCookie('LOGGEDIN');

        if(token) {
            new DirectFetcher().fetch(
                '/user/logout',
                'POST'
            ).then(res => {

                if(res !== false) {
                    localStorage.removeItem('season');
                    localStorage.removeItem('clubName');

                    window.location.href = 'login';
                }
            })
        }
    }

    useEffect(() => {
        if(Object.keys(data).length > 0) {

            let userSettingsTmp = userSettings;

            Object.keys(data).map(dataKey => {
                userSettingsTmp = {
                    ...userSettingsTmp,
                    [dataKey]: {
                        value: data[dataKey],
                        error: null
                    }
                }
            })

            setUserSettings(userSettingsTmp);
        }
    }, [data]);

    return(
        <React.Fragment>

            <div className={'wg-pageHeader'}>
                <div className={'wg-pageTitle'}>
                    Impostazioni
                </div>
            </div>

            <div className={'wg-settingsContent'}>
                <Section
                    title={false}
                    width={296}
                    className={'wg-settingsContentSection'}
                >
                    <div className={'wg-settingsNavigation'}>
                        <ul>
                            <div className={'wg-settingsNavigationGroup'}>
                                <li className={`${selectedMenu === 0 ? 'active' : ''}`} onClick={() => setSelectedMenu(0)}>
                                    <i className={'wi-user-data'} aria-hidden={'true'} />
                                    Profilo
                                </li>
                                <li className={`${selectedMenu === 1 ? 'active' : ''}`} onClick={() => setSelectedMenu(1)}>
                                    <i className={'wi-locker'} aria-hidden={'true'} />
                                    Sicurezza
                                </li>
                                {/*<li className={`${selectedMenu === 2 ? 'active' : ''}`} onClick={() => setSelectedMenu(2)}>*/}
                                {/*    <i className={'wi-slider'} aria-hidden={'true'} />*/}
                                {/*    Preferenze*/}
                                {/*</li>*/}
                            </div>

                            <div className={'wg-divider'} />

                            <div className={'wg-settingsNavigationGroup'}>
                                <li onClick={handleLogout}>
                                    <i className={'wi-logout'} aria-hidden={'true'} />
                                    Logout
                                </li>
                            </div>
                        </ul>
                    </div>
                </Section>

                <div className={'wg-settingsSelectedContent'}>
                    {
                        selectedMenu === 0 ?
                            <Profile data={userSettings} setUserSettings={setUserSettings} loading={loading} />
                        : selectedMenu === 1 ?
                            <Security data={userSettings} setUserSettings={setUserSettings} loading={loading} />
                        : selectedMenu === 2 ?
                            <Preferences data={userSettings} setUserSettings={setUserSettings} loading={loading} />
                        : null

                    }
                </div>
            </div>
        </React.Fragment>
    );
}