import React, {useEffect, useState} from 'react'

import AvatarDefault from '../../../assets/images/Avatar/Avatar-Default.png'
import {Tab} from "../../../components/common/Tab/Tab";
import {PlayerPersonalInfo} from "./PlayerDataAreas/PlayerPersonalInfo";
import {PlayerHealth} from "./PlayerDataAreas/PlayerHealth";
import {PlayerParents} from "./PlayerDataAreas/PlayerParents";
import {Button} from "../../../components/common/Button/Button";
import DirectFetcher from "../../../components/services/Fetcher/DirectFetcher";
import {toast} from "react-toastify";
import {Dialog} from "../../../components/common/Dialog/Dialog";

export const PlayerData = (props) => {

    const {
        data: playerData = null,
        listData = [],
        visible = false,
        setVisible,
        setListData,
        setRefreshData,
        setPlayersToDelete
    } = props;

    const [activeTab, setActiveTab] = useState(0);
    const [newPlayerData, setNewPlayerData] = useState(playerData);

    const [playerPhoto, setPlayerPhoto] = useState(AvatarDefault);
    const [playerPhotoBlob, setPlayerPhotoBlob] = useState(null);

    const [saveDataEnabled, setSaveDataEnabled] = useState(false);
    const [savePlayerProcessing, setSavePlayerProcessing] = useState(false);

    const [isEdited, setIsEdited] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [showDeletePlayerDialog, setShowDeletePlayerDialog] = useState(false);

    const [fieldsErrors, setFieldsErrors] = useState({
        player_id: null,
        address: null,
        birth_date: null,
        birth_place: null,
        birth_province: null,
        certificate_expiration: null,
        city: null,
        email: null,
        father_name: null,
        father_surname: null,
        father_phone_number: null,
        father_email: null,
        fiscal_code: null,
        gender: null,
        identifier: null,
        legal_guardian_name: null,
        legal_guardian_surname: null,
        legal_guardian_phone_number: null,
        legal_guardian_email: null,
        mother_name: null,
        mother_surname: null,
        mother_phone_number: null,
        mother_email: null,
        name: null,
        nationality: null,
        phone_number: null,
        photo: null,
        postal_code: null,
        province: null,
        surname: null
    })

    const closePlayerData = () => {

        if(isEdited) {
            setShowCancelDialog(true);

            document.getElementById('wg-playerData').classList.toggle('visible');
        }else{
            setShowCancelDialog(false);
            setVisible(false);
            setNewPlayerData({});
        }
    }

    const handleCloseDialog = (close) => {

        setShowCancelDialog(false);
        document.getElementById('wg-playerData').classList.toggle('visible');

        if(close) {
            setVisible(false);
        }else{
            setVisible(true);
        }

    }

    const handleAvatarEdit = () => {

        document.getElementById('wg-playerPhotoUploader').click();

    }

    const uploadNewPhoto = (event) => {

        if(event) {
            if(event && event.target) {

                let srcEncoded;
                const item = event.target.files[0];
                const itemSrc = window.URL.createObjectURL(item);
                const resize_width = 120;

                const reader = new FileReader();
                reader.readAsDataURL(item);
                reader.name = item.name;
                reader.size = item.size;
                reader.onload = function(e) {
                    const img = new Image();

                    img.src = itemSrc;
                    img.name = item.name;
                    img.size = item.size;
                    img.onload = function(el) {

                        const elem = document.createElement('canvas');
                        const scaleFactor = resize_width / el.target.width;
                        elem.width = resize_width;
                        elem.height = el.target.height * scaleFactor;

                        const ctx = elem.getContext('2d');
                        ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

                        srcEncoded = ctx.canvas.toDataURL(el.target, 'image/jpeg', 0);

                        document.querySelector("#wg-playerPhotoUploader").src = srcEncoded;

                        const dataURLToBlob = function(dataURL) {
                            const BASE64_MARKER = ';base64,';
                            if (dataURL.indexOf(BASE64_MARKER) === -1) {
                                const parts = dataURL.split(',');
                                const contentType = parts[0].split(':')[1];
                                const raw = parts[1];

                                return new Blob([raw], {type: contentType});
                            }

                            const parts = dataURL.split(BASE64_MARKER);
                            const contentType = parts[0].split(':')[1];
                            const raw = window.atob(parts[1]);
                            const rawLength = raw.length;

                            const uInt8Array = new Uint8Array(rawLength);

                            for (let i = 0; i < rawLength; ++i) {
                                uInt8Array[i] = raw.charCodeAt(i);
                            }

                            return new Blob([uInt8Array], {type: contentType});
                        }

                        srcEncoded = dataURLToBlob(srcEncoded);
                        setPlayerPhotoBlob(srcEncoded);
                        setPlayerPhoto(itemSrc)

                        event.target.value = '';

                        if(newPlayerData.name && newPlayerData.surname && newPlayerData.birth_date) {
                            setSaveDataEnabled(true);
                        }
                    }
                }
            }
        }
    }

    const savePlayerData = () => {


        if(newPlayerData.name && newPlayerData.surname && newPlayerData.birth_date) {
            setSavePlayerProcessing(true);

            let url = null;
            let method;

            const playerFormData = new FormData();
            const body = {};

            if(newPlayerData.player_id) {
                // Update player
                method = 'POST';
                url = `/players/update/${newPlayerData.player_id}`
            }else{

                // Create new player
                method = 'POST'
                url = `/players/add`
            }

            Object.keys(newPlayerData).map(key => {
                if(key !== 'player_id' && key !== 'photo') {
                    body[key] = newPlayerData[key];
                }
            })

            delete body.team_names;

            playerFormData.append('photo', playerPhotoBlob);

            playerFormData.append('player', JSON.stringify(body));

            if(method && url) {
                new DirectFetcher().fetch(
                    url,
                    method,
                    playerFormData,
                    !newPlayerData.player_id,
                    null,
                    true,
                    true
                ).then(res => {

                    if(res !== false) {

                        if(res.player_id) {
                            setNewPlayerData(oldPlayerData => (
                                {
                                    ...oldPlayerData,
                                    'player_id': res.player_id,
                                    'photo': res.photo
                                }
                            ))

                            body.player_id = res.player_id;
                            body.photo = res.photo;

                            toast.success(`Il giocatore ${newPlayerData.surname} ${newPlayerData.name} è stato aggiunto con successo`)

                            setListData(oldData => [...oldData, body]);
                        }else{

                            const listDataToUpdate = listData;

                            if(listDataToUpdate.length > 0) {
                                listDataToUpdate.map((player, index) => {

                                    if(player.player_id === newPlayerData.player_id) {
                                        listDataToUpdate[index] = newPlayerData;

                                        if(res.photo) {
                                            body.photo = res.photo;
                                            listDataToUpdate[index]['photo'] = res.photo;
                                        }

                                        setNewPlayerData(oldPlayerData => (
                                            {
                                                ...oldPlayerData,
                                                'photo': body.photo,
                                            }
                                        ))
                                    }
                                })
                            }

                            setListData(listDataToUpdate);

                            toast.success(`Il giocatore ${newPlayerData.surname} ${newPlayerData.name} è stato aggiornato con successo`)
                        }
                    }

                    setVisible(false);
                    setRefreshData(oldRefreshData => oldRefreshData + 1);

                    setSavePlayerProcessing(false);
                })
            }
        }

    }

    const handleDeletePlayerDialog = (open) => {

        setShowDeletePlayerDialog(open);
        document.getElementById('wg-playerData').classList.toggle('visible');

    }

    const deletePlayer = () => {

        new DirectFetcher().fetch(
            `/players/delete/${newPlayerData.player_id}`,
            'DELETE'
        ).then(res => {

            handleDeletePlayerDialog(false);

            if(res !== false) {
                toast.success(`Il giocatore ${newPlayerData.surname} ${newPlayerData.name} è stato eliminato con successo`)
                closePlayerData();
            }
        })

        setPlayersToDelete(oldPlayersToDelete => [...oldPlayersToDelete, newPlayerData.player_id]);
    }

    useEffect(() => {

        if(playerData) {
            setNewPlayerData(playerData)
        }

        let valid = true;

        Object.keys(fieldsErrors).map(field => {
            if(fieldsErrors[field]) {
                valid = false;
            }
        })

        setSaveDataEnabled(valid);

    }, [playerData]);

    useEffect(() => {

        let valid = true;

        Object.keys(fieldsErrors).map(field => {
            if(fieldsErrors[field]) {
                valid = false;
            }
        })

        setSaveDataEnabled(valid);

        if(newPlayerData.photo) {
            setPlayerPhoto(newPlayerData.photo);
        }else{
            setPlayerPhoto(AvatarDefault);
        }

        let isDifferent = false;

        Object.keys(newPlayerData).map(field => {

            if(newPlayerData[field] !== playerData[field]) {
                isDifferent = true;
            }
        })

        setIsEdited(isDifferent);

    }, [newPlayerData]);

    useEffect(() => {

        if(!visible) {
            setActiveTab(0);
            setFieldsErrors({
                player_id: null,
                address: null,
                birth_date: null,
                birth_place: null,
                birth_province: null,
                certificate_expiration: null,
                city: null,
                email: null,
                father_name: null,
                father_surname: null,
                father_phone_number: null,
                father_email: null,
                fiscal_code: null,
                gender: null,
                identifier: null,
                legal_guardian_name: null,
                legal_guardian_surname: null,
                legal_guardian_phone_number: null,
                legal_guardian_email: null,
                mother_name: null,
                mother_surname: null,
                mother_phone_number: null,
                mother_email: null,
                name: null,
                nationality: null,
                phone_number: null,
                photo: null,
                postal_code: null,
                province: null,
                surname: null
            })
            setNewPlayerData(playerData);
        }else{
            if(!playerData.player_id) {
                document.getElementById('surname').focus();
            }

            document.getElementById('wg-playerData').scrollTo(0, 0);
        }
    }, [visible]);

    return(
        <React.Fragment>
            <div className={`wg-detailPanelDataOverlay ${visible ? 'visible' : ''}`}
                 onClick={closePlayerData}
            />
            <div className={`wg-detailPanelData ${visible ? 'visible' : ''}`} id={'wg-playerData'}>

                <div className={'wg-detailPanelDataHeader'}>
                    <div className={'wg-playerAvatar'} onClick={handleAvatarEdit}>

                        <i className={'wi-delete'} aria-hidden={'true'} />

                        <input
                            type={'file'}
                            id={'wg-playerPhotoUploader'}
                            accept={'.jpg, .jpeg, .png'}
                            hidden={true}
                            // disabled={saveProfileProcessing}
                            onChange={(event) => uploadNewPhoto(event)}
                        />
                        <img src={playerPhoto ? playerPhoto : AvatarDefault}
                             alt={'Player avatar'}/>
                    </div>
                    <div className={'wg-detailPanelDataMetaData'}>
                        <div className={'wg-detailPanelDataMetaData'}>
                            {newPlayerData && newPlayerData.surname ? `${newPlayerData.surname} ` : 'Cognome '}
                            {newPlayerData && newPlayerData.name ? `${newPlayerData.name} ` : 'Nome ' +
                                ''}
                        </div>
                        <div className={'wg-playerId'}>
                            {newPlayerData && newPlayerData.identifier ? `${newPlayerData.identifier} ` : '-'}
                        </div>
                    </div>

                    <div className={'wg-mobileCloseButton'}>
                        <Button
                            type={'icon'}
                            icon={'close'}
                            label={false}
                            handleClick={closePlayerData}
                        />
                    </div>
                </div>

                <div className={'wg-detailPanelDataTab'}>
                    <Tab
                        items={['Dati anagrafici', 'Certificato Medico', 'Genitori']}
                        active={activeTab}
                        handleClick={setActiveTab}
                    />
                </div>

                <div className={'wg-detailPanelDataContent'}>
                    {
                        activeTab === 0 ?
                            <PlayerPersonalInfo
                                data={newPlayerData}
                                setData={setNewPlayerData}
                                savePlayerData={savePlayerData}
                                fieldsErrors={fieldsErrors}
                                setFieldsErrors={setFieldsErrors}
                                visible={visible}
                            />
                            : activeTab === 1 ?
                                <PlayerHealth
                                    data={newPlayerData}
                                    setData={setNewPlayerData}
                                    fieldsErrors={fieldsErrors}
                                    setFieldsErrors={setFieldsErrors}
                                    savePlayerData={savePlayerData}
                                />
                                : activeTab === 2 ?
                                    <PlayerParents
                                        data={newPlayerData}
                                        setData={setNewPlayerData}
                                        fieldsErrors={fieldsErrors}
                                        setFieldsErrors={setFieldsErrors}
                                        savePlayerData={savePlayerData}
                                    />
                                    :
                                    null
                    }
                </div>

                <div className={'wg-detailPanelDataFooter'}>
                    {newPlayerData.player_id ?
                        <div className={'wg-leftButton'}>
                            <Button
                                type={'danger'}
                                label={'Elimina'}
                                handleClick={() => handleDeletePlayerDialog(true)}
                            />
                        </div>
                    :
                        null
                    }
                    <Button
                        type={'secondary'}
                        label={'Annulla'}
                        handleClick={closePlayerData}
                    />
                    <Button
                        label={'Salva giocatore'}
                        disabled={!saveDataEnabled || !newPlayerData.name || !newPlayerData.surname || !newPlayerData.birth_date}
                        loading={savePlayerProcessing}
                        handleClick={savePlayerData}
                    />
                </div>
            </div>

            {showCancelDialog ?
                <Dialog
                    title={'Sei sicuro?'}
                    primaryButton={{
                        label: 'Riprendi modifica',
                        handleClick: () => handleCloseDialog(false)
                    }}
                    secondaryButton={{
                        label: 'Esci',
                        handleClick: () => handleCloseDialog(true)
                    }}
                    dialogVisible={showCancelDialog}
                    setDialogVisible={() => setVisible(false)}
                >
                    Se selezioni 'esci' perderai tutte le modifiche effettuate {newPlayerData.name || newPlayerData.surname ? 'a' : ''}
                    <span
                        style={{
                            textTransform: "capitalize",
                            fontWeight: 600
                        }}
                    >
                        {` ${newPlayerData.surname ? newPlayerData.surname : ''} ${newPlayerData.name ? newPlayerData.name : ''}`}
                    </span>
                </Dialog>

                :
                null
            }

            {showDeletePlayerDialog ?
                <Dialog
                    title={`Eliminare ${newPlayerData.surname ? newPlayerData.surname : ''} ${newPlayerData.name ? newPlayerData.name : ''}?`}
                    primaryButton={{
                        type: 'danger',
                        label: 'Elimina',
                        handleClick: deletePlayer
                    }}
                    secondaryButton={{
                        label: 'Annulla',
                        handleClick: () => handleDeletePlayerDialog(false)
                    }}
                    dialogVisible={showDeletePlayerDialog}
                    setDialogVisible={() => setShowDeletePlayerDialog(false)}
                >
                    Quest'azione è irreversibile. Tutti i dati di
                    <span
                        style={{
                            textTransform: "capitalize",
                            fontWeight: 600
                        }}
                    >
                        {` ${newPlayerData.surname ? newPlayerData.surname : ''} ${newPlayerData.name ? newPlayerData.name : ''} `}
                    </span>

                    andranno persi in modo definitivo
                </Dialog>

                :
                null
            }
        </React.Fragment>
    );
}