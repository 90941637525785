import React, {useEffect, useState} from "react"

export const Checkbox = props => {

    const {
        id= null,
        label = 'Label',
        size= 'default',
        disabled = false,
        checked= false,
        tabIndex = null,
        handleChange = null,
        handleKeyDown = null
    } = props;

    const [checkedState, setCheckedState] = useState(false);

    const checkKeyUp = event => {

        if(event) {
            if(event.key) {
                if(event.key === "Enter") {
                    if(handleKeyDown) {
                        handleKeyDown(event);
                    }
                }
            }
        }
    }

    const onChange = (event) => {

        if(event) {
            setCheckedState(event.target.checked);
        }

        if(handleChange) {
            if(event) {
                handleChange(event);
            }else{
                handleChange();
            }
        }
    }

    useEffect(() => {
        setCheckedState(checked);
    }, [checked]);

    return(
        <React.Fragment>
            <div className={`wg-checkbox ${disabled ? "disabled" : ""} ${size}`}>
                <div className={"wg-checkArea"}>
                    <input
                        type={"checkbox"}
                        id={id}
                        checked={checkedState}
                        disabled={disabled}
                        tabIndex={tabIndex}
                        onChange={e => onChange(e)}
                        onKeyUp={e => checkKeyUp(e)}
                    />
                    <i className={"wi-checkmark"} aria-hidden={true} />
                </div>
                {label ? <label htmlFor={id}>{label}</label> : null}
            </div>
        </React.Fragment>
    );
}