import React, {useEffect, useState} from 'react';

// Import refs
import {Section} from "../../../../components/common/Section/Section";
import {Textfield} from "../../../../components/common/Fields/Textfield/Textfield";
import {Link} from "react-router-dom";
import {Badge} from "../../../../components/common/Badge/Badge";
import {Switch} from "../../../../components/common/Fields/Switch/Switch";
import DirectFetcher from "../../../../components/services/Fetcher/DirectFetcher";
import {toast} from "react-toastify";
import {Button} from "../../../../components/common/Button/Button";
import FieldValidation from "../../../../components/utility/Validation/Validation";

export const Security = (props) => {

    const {
        data,
        setUserSettings,
        loading
    } = props;

    const [userData, setUserData] = useState({});

    const [saveEmailEnabled, setSaveEmailEnabled] = useState(false);
    const [twoFactorAuthenticationEnabled, setTwoFactorAuthenticationEnabled] = useState(false);

    const [processingEmailRequest, setProcessingEmailRequest] = useState(false);
    const [processingPasswordRequest, setProcessingPasswordRequest] = useState(false);

    const sendResetCredential = (name) => {

        if(name) {

            let fetchURL = null;
            let body = null;

            if(name === 'email') {
                setProcessingEmailRequest(true);
                fetchURL = '/user/request-change-email';
                body = {
                    new_email: userData.email.value
                };
            }
            if(name === 'password') {
                setProcessingPasswordRequest(true);
                fetchURL = '/user/request-reset-password';
            }

            new DirectFetcher().fetch(
                fetchURL,
                'POST',
                body
            ).then(res => {
                    if(res !== false) {
                        if(name === 'email') {
                            toast.info('Abbiamo inviato una mail al tuo nuovo indirizzo');
                        }

                        if(name === 'password') {
                            toast.info('Controlla la mail per resettare la password');
                        }
                    }

                    setProcessingEmailRequest(false);
                    setProcessingPasswordRequest(false);
                });
        }
    }

    const handleChangeEmail = event => {

        if(event) {

            const {value} = event.target;

            const validation = FieldValidation.field('email', value)

            setUserData(userDataTmp => ({
                ...userDataTmp,
                email: {
                    value: value,
                    error: validation.error
                }
            }));

            if(value && !validation.error) {
                setSaveEmailEnabled(true);
            }else{
                setSaveEmailEnabled(false);
            }
        }
    }

    useEffect(() => {
        if(Object.keys(data).length > 0) {
            setUserData(data);
        }
    }, [data]);

    return(
        <React.Fragment>
            <div className={'wg-security'}>
                <Section
                    title={'Credenziali di accesso'}
                    description={'Gestisci la tua mail o password. Cambia la tua password ogni volta che pensi sia stata compromessa'}
                >
                    <div className={'wg-authenticationCredentials'}>

                        <div className={'wg-authenticationCredentialsField'}>
                            <Textfield
                                id={'email'}
                                label={'E-mail'}
                                defaultValue={userData.email ? userData.email.value : ''}
                                error={userData.email ? userData.email.error : null}
                                placeholder={'Your e-mail'}
                                fill={true}
                                handleChange={e => handleChangeEmail(e)}
                                handleKeyDown={() => sendResetCredential('email')}
                            />
                        </div>
                        <div className={'wg-authenticationCredentialsField'}>
                            <Textfield
                                id={'password'}
                                label={'Password'}
                                defaultValue={'••••••••••••••'}
                                placeholder={'Your Password'}
                                disabled={true}
                                fill={true}
                            />
                            <Link
                                to={'#'}
                                className={`wg-decoration ${processingPasswordRequest ? 'disabled' : ''}`}
                                aria-disabled={processingPasswordRequest}
                                onClick={() => sendResetCredential('password')}
                            >
                                Ripristina password
                            </Link>
                        </div>
                    </div>

                    <div className={'wg-securityCredentialsActions'}>
                        <Button
                            label={'Salva E-mail'}
                            disabled={!saveEmailEnabled}
                            loading={processingEmailRequest}
                            handleClick={() => sendResetCredential('email')}
                        />
                    </div>
                </Section>

                {/*<Section
                    title={'Two-Factor Authentication'}
                    description={'Protect your account by asking an additional authentication method every time you sign-in '}
                    headerComponent={
                        <Badge
                            label={twoFactorAuthenticationEnabled ? 'Enabled' : 'Not available'}
                            size={'xsmall'}
                            state={twoFactorAuthenticationEnabled ? 'positive' : 'neutral'}
                        />
                    }
                >
                    <div className={'wg-twoFactorAuthentication'}>
                        <div className={'wg-twoFactorAuthenticationRow'}>
                            <div className={'wg-twoFactorAuthenticationInfo'}>
                                <div className={'wg-twoFactorAuthenticationInfoGroup'}>
                                    <div className={'wg-twoFactorAuthenticationInfoTitle'}>Authenticator App</div>
                                    <div className={'wg-twoFactorAuthenticationInfoDescription'}>
                                        Use the One Time Password provided by Authentication App (Google Authenticator, Microsoft Authenticator, etc...)
                                    </div>
                                </div>
                            </div>
                            <Switch
                                label={false}
                                disabled={true}
                            />
                        </div>
                        <div className={'wg-twoFactorAuthenticationRow'}>
                            <div className={'wg-twoFactorAuthenticationInfo'}>
                                <div className={'wg-twoFactorAuthenticationInfoGroup'}>
                                    <div className={'wg-twoFactorAuthenticationInfoTitle'}>E-mail Code</div>
                                    <div className={'wg-twoFactorAuthenticationInfoDescription'}>
                                        Check your inbox and use the One Time Password
                                    </div>
                                </div>
                            </div>
                            <Switch
                                label={false}
                                disabled={true}
                            />
                        </div>
                    </div>
                </Section>*/}

                <Section
                    title={'Collegamenti ai Social'}
                    description={'Collega gli account dei tuoi sociak per un\'accesso rapido e sicuro'}
                    headerComponent={
                        <Badge
                            label={twoFactorAuthenticationEnabled ? 'Enabled' : 'Not available'}
                            size={'xsmall'}
                            status={twoFactorAuthenticationEnabled ? 'positive' : 'neutral'}
                        />
                    }
                >
                    <div className={'wg-twoFactorAuthentication'}>
                        <div className={'wg-twoFactorAuthenticationRow'}>
                            <div className={'wg-twoFactorAuthenticationInfo'}>
                                <i className={'wi-google'} aria-hidden={true}>
                                    <span className={'path1'} />
                                    <span className={'path2'} />
                                    <span className={'path3'} />
                                    <span className={'path4'} />
                                </i>

                                <div className={'wg-twoFactorAuthenticationInfoGroup'}>
                                    <div className={'wg-twoFactorAuthenticationInfoTitle'}>Accesso Google</div>
                                    <div className={'wg-twoFactorAuthenticationInfoDescription'}>
                                        Utilizza le tue informazioni Google per effettuare l'accesso
                                    </div>
                                </div>
                            </div>
                            <Switch
                                label={false}
                                disabled={true}
                            />
                        </div>
                    </div>
                </Section>
            </div>
        </React.Fragment>
    );
}