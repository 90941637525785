import React, {useEffect, useState} from 'react'
import {Textfield} from "../../../../components/common/Fields/Textfield/Textfield";
import {Dropdown} from "../../../../components/common/Fields/Dropdown/Dropdown";
import CodiceFiscale from 'codice-fiscale-js';
import CodiciCatastali from "../../../../components/utility/CodiciCatastali/CodiciCatastali";
import FieldValidation from "../../../../components/utility/Validation/Validation";

import { useTranslation } from 'react-i18next';


export const PlayerPersonalInfo = (props) => {

    const {
        data: playerData = {},
        setData: setPlayerData,
        savePlayerData,
        fieldsErrors,
        setFieldsErrors,
        visible
    } = props;

    const [birthProvinces, setBirthProvinces] = useState([]);
    const [birthCities, setBirthCities] = useState([]);
    const [calculateFiscalCodeEnabled, setCalculateFiscalCodeEnabled] = useState(true);
    const [checkFiscalCodeEnabledHandler, setCheckFiscalCodeEnabledHandler] = useState(true);

    const [livingProvinces, setLivingProvinces] = useState([]);
    const [livingCities, setLivingCities] = useState([]);

    const { t, i18n } = useTranslation();

    const handleChange = event => {

        if(event) {

            const {name, value} = event.target;

            let validation = '';

            if(value && event.target.getAttribute('data-validation')) {
                validation = FieldValidation.field(event.target.getAttribute('data-validation'), value);
            }else{
                validation = {value: value, error: null};
            }

            setFieldsErrors(oldFieldErrors => ({
                ...oldFieldErrors,
                [name]: validation.error
            }))

            setPlayerData(playerDataOld => ({
                ...playerDataOld,
                [name]: value
            }))
        }


        setCheckFiscalCodeEnabledHandler(oldHandler => oldHandler + 1);
    }

    const handleChangeDropdown = event => {

        if(event.key === 'nationality') {
            if(event.value !== 'EE') {
                setBirthProvinces(CodiciCatastali.getProvinces(false, true));
            }else{
                setBirthProvinces(CodiciCatastali.getProvinces(true, true));
            }
        }

        if(event.key === 'birth_province') {
            setBirthCities(CodiciCatastali.getCities(event.value));
        }

        if(event.key === 'province') {
            setLivingCities(CodiciCatastali.getCities(event.value))
        }

        setPlayerData(playerDataOld => ({
            ...playerDataOld,
            [event.key]: event.value
        }))

        setCheckFiscalCodeEnabledHandler(oldHandler => oldHandler + 1);
    }

    const calculateFiscalCode = () => {

        const fiscalCode = new CodiceFiscale({
            name: playerData.name,
            surname: playerData.surname,
            gender: playerData.gender.toLowerCase() === 'uomo' ? 'M' : 'F',
            day: playerData.birth_date.slice(0,2),
            month: playerData.birth_date.slice(3,5),
            year: playerData.birth_date.slice(6),
            birthplace: playerData.nationality === 'EE' ? playerData.birth_province : playerData.birth_place,
            birthplaceProvincia: playerData.nationality === 'EE' ? 'EE' : playerData.birth_province
        })

        if(fiscalCode) {
            setPlayerData(playerDataOld => ({
                ...playerDataOld,
                'fiscal_code': fiscalCode.code
            }))
            setFieldsErrors(fieldsErrorsOld => ({
                ...fieldsErrorsOld,
                'fiscal_code': null
            }))
        }


    }

    useEffect(() => {
        if(playerData.nationality !== 'EE') {
            if(playerData.name && playerData.surname && playerData.birth_date && playerData.gender && playerData.nationality && playerData.birth_province && playerData.birth_place) {
                setCalculateFiscalCodeEnabled(true);
            }else{
                setCalculateFiscalCodeEnabled(false);
            }
        }else{
            if(playerData.name && playerData.surname && playerData.birth_date && playerData.gender && playerData.nationality && playerData.birth_province) {
                setCalculateFiscalCodeEnabled(true);
            }else{
                setCalculateFiscalCodeEnabled(false);
            }
        }
    }, [checkFiscalCodeEnabledHandler]);

    useEffect(() => {

        if(playerData.nationality) {
            setBirthProvinces(playerData.nationality === 'EE' ? CodiciCatastali.getProvinces(true, true) : CodiciCatastali.getProvinces(false, true));
        }else{
            setBirthProvinces([]);
        }

        if(playerData.birth_province) {
            setBirthCities(CodiciCatastali.getCities(playerData.birth_province))
        }else{
            setBirthCities([]);
        }

        setLivingProvinces(CodiciCatastali.getProvinces(false));

        setCheckFiscalCodeEnabledHandler(oldHandler => oldHandler + 1);

    }, [playerData]);

    const getBirthYears = () => {
        const max = new Date().getFullYear()
        const min = max - 100

        const years = [];

        for(let i = max; i >= min; i-- ) {
            years.push(i);
        }

        return years;
    }

    return(
        <React.Fragment>
            <div className={'wg-detailPanelDataFieldsContainer'}>

                <div className={'wg-detailPanelDataFieldsContainerTitle'}>Informazioni Personali</div>

                <div className={'wg-detailPanelDataFields'}>
                    <div className={'wg-detailPanelDataFieldsRow'}>
                        <Textfield
                            id={'surname'}
                            fill={true}
                            required={true}
                            label={'Cognome'}
                            placeholder={'Cognome del giocatore'}
                            defaultValue={playerData.surname}
                            error={fieldsErrors.surname}
                            maxLength={64}
                            validationType={'text'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                        <Textfield
                            id={'name'}
                            fill={true}
                            required={true}
                            label={'Nome'}
                            placeholder={'Nome del giocatore'}
                            defaultValue={playerData.name}
                            error={fieldsErrors.name}
                            maxLength={64}
                            validationType={'text'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                    </div>
                    <div className={'wg-detailPanelDataFieldsRow'}>
                        <Textfield
                            id={'birth_date'}
                            fill={true}
                            required={true}
                            label={'Data di nascita'}
                            placeholder={'gg/mm/aaaa'}
                            defaultValue={playerData.birth_date}
                            error={fieldsErrors.birth_date}
                            maxLength={10}
                            validationType={'date'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                        <Dropdown
                            id={'gender'}
                            fill={true}
                            label={'Sesso'}
                            placeholder={'Seleziona'}
                            defaultValue={playerData.gender}
                            itemKey={'gender'}
                            items={[
                                {
                                    key: 'uomo',
                                    value: 'uomo'
                                },
                                {
                                    key: 'donna',
                                    value: 'donna'
                                }
                            ]}
                            handleChange={e => handleChangeDropdown(e)}
                        />
                    </div>
                    <div className={'wg-detailPanelDataFieldsRow'}>
                        <Dropdown
                            id={'nationality'}
                            fill={true}
                            label={'Nazionalità'}
                            placeholder={'Seleziona'}
                            defaultValue={playerData.nationality}
                            itemKey={'nationality'}
                            items={[
                                {
                                  key: 'italiana',
                                  value: 'italiana'
                                },
                                {
                                    key: 'EE',
                                    value: 'EE'
                                }
                            ]}
                            handleChange={e => handleChangeDropdown(e)}
                        />
                        <Dropdown
                            id={'birth_province'}
                            fill={true}
                            label={'Provincia di nascita'}
                            placeholder={'Seleziona'}
                            defaultValue={playerData.birth_province}
                            itemKey={'birth_province'}
                            items={birthProvinces}
                            handleChange={e => handleChangeDropdown(e)}
                        />
                    </div>
                    <div className={'wg-detailPanelDataFieldsRow'}>
                        <Dropdown
                            id={'birth_place'}
                            fill={true}
                            label={'Comune di nascita'}
                            placeholder={'Seleziona'}
                            defaultValue={playerData.birth_place}
                            itemKey={'birth_place'}
                            items={birthCities}
                            disabled={playerData.nationality === 'EE'}
                            handleChange={e => handleChangeDropdown(e)}
                        />
                        <Textfield
                            id={'fiscal_code'}
                            fill={true}
                            label={'Codice Fiscale'}
                            placeholder={'Codice fiscale del giocatore'}
                            defaultValue={playerData.fiscal_code}
                            error={fieldsErrors.fiscal_code}
                            validationType={'fiscal_code'}
                            action={{
                                icon: 'calculator',
                                disabled: !calculateFiscalCodeEnabled,
                                handleClick: calculateFiscalCode
                            }}
                            mxLength={16}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />

                    </div>
                    <div className={'wg-detailPanelDataFieldsRow'}>
                        <Textfield
                            type={'tel'}
                            id={'identifier'}
                            fill={true}
                            label={'Matricola'}
                            placeholder={'Matricola del giocatore'}
                            defaultValue={playerData.identifier}
                            error={fieldsErrors.identifier}
                            maxLength={16}
                            validationType={'numeric'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                        <Textfield
                            fill={true}
                            hidden={true}
                            label={'-'}
                        />
                    </div>
                </div>
            </div>
            <div className={'wg-detailPanelDataFieldsContainer'}>

                <div className={'wg-detailPanelDataFieldsContainerTitle'}>Residenza</div>

                <div className={'wg-detailPanelDataFields'}>
                    <div className={'wg-detailPanelDataFieldsRow'}>
                        <Textfield
                            id={'address'}
                            fill={true}
                            label={'Indirizzo'}
                            placeholder={'Indirizzo del giocatore'}
                            defaultValue={playerData.address}
                            error={fieldsErrors.address}
                            maxLength={128}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                        <Dropdown
                            id={'province'}
                            fill={true}
                            label={'Provincia'}
                            placeholder={'Seleziona'}
                            defaultValue={playerData.province}
                            itemKey={'province'}
                            items={livingProvinces}
                            handleChange={e => handleChangeDropdown(e)}
                        />
                    </div>
                    <div className={'wg-detailPanelDataFieldsRow'}>
                        <Dropdown
                            id={'city'}
                            fill={true}
                            label={'Città'}
                            placeholder={'Seleziona'}
                            defaultValue={playerData.city}
                            itemKey={'city'}
                            items={livingCities}
                            handleChange={e => handleChangeDropdown(e)}
                        />
                        <Textfield
                            type={'tel'}
                            id={'postal_code'}
                            fill={true}
                            label={'Codice Postale'}
                            placeholder={'Codice postale del giocatore'}
                            defaultValue={playerData.postal_code}
                            error={fieldsErrors.postal_code}
                            maxLength={5}
                            validationType={'numeric'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                    </div>
                </div>
            </div>

            <div className={'wg-detailPanelDataFieldsContainer'}>

                <div className={'wg-detailPanelDataFieldsContainerTitle'}>Contatti</div>

                <div className={'wg-detailPanelDataFields'}>
                    <div className={'wg-detailPanelDataFieldsRow'}>
                        <Textfield
                            type={'email'}
                            id={'email'}
                            fill={true}
                            label={'E-mail'}
                            placeholder={'E-mail del giocatore'}
                            defaultValue={playerData.email}
                            error={fieldsErrors.email}
                            maxLength={128}
                            validationType={'email'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                        <Textfield
                            type={'tel'}
                            id={'phone_number'}
                            fill={true}
                            label={'Numero di telefono'}
                            placeholder={'Numero di telefono del giocatore'}
                            defaultValue={playerData.phone_number}
                            error={fieldsErrors.phone_number}
                            maxLength={10}
                            validationType={'phone'}
                            handleChange={e => handleChange(e)}
                            handleKeyDown={savePlayerData}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}