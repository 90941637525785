import React from 'react'

export const Skeleton = (props) => {

    const {
        type = 'text',
        width= null,
        height=null
    } = props;

    return(
        <React.Fragment>
            <div className={`wg-skeletonContent ${type ? 'wg-skeleton_' + type : ''}`}
                 style={{width: width, height: height}}
            >
                <div className={'wg-skeletonShimmerContent'}>
                    <div className={"wg-skeletonShimmer"}/>
                </div>
                <div className={`wg-skeleton wg-skeleton_${String(type).toLowerCase()}`} />
            </div>
        </React.Fragment>
    );
}