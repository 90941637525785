import Cookies from "../../utility/Cookies/Cookies";
import {toast} from "react-toastify";

class useDirectFetcher {

    fetch = (url, method = 'GET', body = null, season = false, teamId = null, needAuth = true, formData= false, type='json') => {

        let blobHeaders = null;

        if(Cookies.getCookie('LOGGEDIN') || !needAuth) {

            let fetchProperties = {
                url: process.env.REACT_APP_API_BASE_PATH + '/WeasyGoal/api/public'+url,
                options: {
                    method: method,
                    headers: {},
                    credentials: 'include'
                }
            }

            if(body) {
                if(formData) {
                    fetchProperties.options.body = body;
                }else{
                    fetchProperties.options.body = JSON.stringify(body);
                }
            }

            if(season) {
                fetchProperties.options.headers['X-Season'] = localStorage.getItem("season");
            }

            if(teamId) {
                fetchProperties.options.headers['X-TeamId'] = teamId;
            }

            if(needAuth) {
                //fetchProperties.options.headers['X-Authorization'] = Cookies.getCookie('LOGGEDIN');
            }

            if(!formData) {
                fetchProperties.options.headers['Content-Type'] = 'application/json';
            }

            return (
                fetch(fetchProperties.url, fetchProperties.options)
                    .then(res => {

                        if(res.status === 401 && window.location.pathname !== '/login' && needAuth) {
                            Cookies.deleteCookie('LOGGEDIN');
                            Cookies.deleteCookie('PHPSESSID');
                            Cookies.deleteCookie('REMEMBER_ME_TOKEN');

                            window.location.href = '/login'
                        }

                        if(type === 'file') {
                            return {
                                headers: res.headers,
                                blob: res.blob()
                            }
                        }

                        return res.json();
                    })
                    .then(data => {

                        if(data.message) {
                            toast.error(data.message);
                            return false;
                        }

                        if(type === 'file') {

                            blobHeaders = data.headers;
                            return data.blob;
                        }

                        return data;
                    })
                    .then(fileData => {

                        if(fileData && type === 'file') {

                            const blob = new Blob([fileData], {type: 'octet/stream'})
                            const objectUrl = window.URL.createObjectURL(blob);
                            const anchor = document.createElement("a");

                            let fileName = blobHeaders.get('Content-Disposition');
                            const fileNameIndex= fileName.indexOf('filename');

                            fileName = fileName.substring(fileNameIndex + "filename=".length);

                            anchor.href = objectUrl;
                            anchor.download = fileName;
                            anchor.click();

                            window.URL.revokeObjectURL(objectUrl);
                        }

                        return fileData;
                    })
                    .catch(err => {
                        toast.error(err.message);
                        return false;
                    }
                )
            )
        }else{
            if(needAuth) {
                Cookies.deleteCookie('LOGGEDIN');
                toast.error('E\' necessario effettuare l\'accesso');
            }

            return null;
        }
    }
}

export default useDirectFetcher;