import React, {useEffect, useState} from 'react'

import {Card} from "../../components/common/Card/Card";
import {NoData} from "../../components/common/Messages/NoData/NoData";
import {Skeleton} from "../../components/common/Loader/Skeleton/Skeleton";
import DirectFetcher from "../../components/services/Fetcher/DirectFetcher";
import {toast} from 'react-toastify'
import {Badge} from "../../components/common/Badge/Badge";
import AvatarDefault from '../../assets/images/Avatar/Avatar-Default.png'
import {StaffData} from "./Data/StaffData";
import {Link, useOutletContext} from 'react-router-dom';
import {Button} from "../../components/common/Button/Button";
import {ContextMenu} from "../../components/common/ContextMenu/ContextMenu";
import {Dialog} from "../../components/common/Dialog/Dialog";
import {Dropdown} from "../../components/common/Fields/Dropdown/Dropdown";

export const Staff = props => {

    const [routeData] = useOutletContext();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [skeletonItems] = useState(new Array(4));
    const [teams, setTeams] = useState([]);

    // User Data
    const [selectedUserData, setSelectedUserData] = useState({});
    const [userDataVisible, setUserDataVisible] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [userPermissionRoles, setUserPermissionRoles] = useState([]);

    // Export Staff
    const [exportDialogVisible, setExportDialogVisible] = useState(false);
    const [exportProcessing, setExportProcessing] = useState(false);
    const [exportFormat, setExportFormat] = useState('PDF');

    const toggleUserDetail = (row) => {

        if(row) {
            setSelectedUserData(row);
        }else{
            setSelectedUserData({
                confirmation_pending: null,
                identifier: null,
                name: null,
                photo: null,
                role: null,
                role_description: null,
                staff_member_id: null,
                surname: null,
                teams: null,
                user_email: null,
                user_linked: null,
                user_role: null,
                user_role_description: null,
                user_status: null
            })
        }
        setUserDataVisible(true);
    }

    const closeExportDialog = () => {
        setExportDialogVisible(false)
    }

    const exportData = () => {

        if(exportFormat) {

            setExportProcessing(true);
            new DirectFetcher().fetch(
                `/staff/export/${String(exportFormat).toLowerCase()}`,
                'GET',
                null,
                true,
                null,
                true,
                false,
                'file'
            ).then(res => {

                if (res !== false) {

                    toast.info('Lo staff è stato esportato')
                }

                setExportProcessing(false);
                setExportDialogVisible(false);
            })
        }
    }

    const deleteUser = userToDeleteId => {

        const userDataTmp = [];

        if(data.length > 0) {

            data.map(user => {
                if(user.staff_member_id !== userToDeleteId) {
                    userDataTmp.push(user);
                }
            })
        }

        setData(userDataTmp);
    }

    useEffect(() => {

        if(userDataVisible) {
            document.getElementsByTagName('body')[0].classList.add('no-overflow');
        }else{
            document.getElementsByTagName('body')[0].classList.remove('no-overflow');
        }
    }, [userDataVisible]);

    useEffect(() => {

        if(routeData.appReady) {

            setLoading(true);

            new DirectFetcher().fetch(
                '/staff',
                'GET',
                null,
                true
            ).then(res => {

                if(res !== false) {
                    setData(res);
                }

                setLoading(false);
            })

            new DirectFetcher().fetch(
                '/teams',
                'GET',
                null,
                true
            ).then(res => {

                if(res !== false) {

                    const teamsTmp = [];

                    if(res.length > 0) {

                        res.map(team => {
                            teamsTmp.push({
                                key: team.team_id,
                                value: team.name
                            });
                        })
                    }

                    setTeams(teamsTmp);
                }
            })

            new DirectFetcher().fetch(
                '/staff/roles',
                'GET'
            ).then(res => {

                if(res !== false) {

                    const rolesTmp = [];

                    if(res.length > 0) {
                        res.map(role => {
                            rolesTmp.push({
                                key: role.key,
                                value: role.value
                            });
                        })
                    }

                    setUserRoles(rolesTmp);
                }
            })

            new DirectFetcher().fetch(
                '/staff/user-roles',
                'GET'
            ).then(res => {

                if(res !== false) {

                    const rolesTmp = [];

                    if(res.length > 0) {
                        res.map(role => {
                            rolesTmp.push({
                                key: role.key,
                                value: role.value
                            });
                        })
                    }

                    setUserPermissionRoles(rolesTmp);
                }
            })
        }
    }, [routeData.appReady]);

    return (
        <React.Fragment>
            <div className={'wg-pageHeader'}>
                <div className={'wg-pageTitle'}>
                    Staff
                </div>
                <div className={'wg-pageActions'}>

                    <Button
                        type={'secondary'}
                        icon={'dots_horizontal'}
                        label={'Altro'}
                        hasChildren={true}
                    >
                        <ContextMenu width={230}>
                            <ul>
                                <li>
                                    <Link to={'#'} onClick={() => setExportDialogVisible(true)}>
                                        <i className={'wi-download'} aria-hidden={"true"} /> Esporta Staff
                                    </Link>
                                </li>
                            </ul>
                        </ContextMenu>
                    </Button>

                    <Button label={'nuovo collaboratore'} handleClick={toggleUserDetail}/>
                </div>
            </div>

            <div className={'wg-userList'}>
                {loading ?
                    <div className={'wg-userList'}>
                        <Card>
                            <div className={'wg-userCard'}>
                                <div className={'wg-userCardAvatar'}>
                                    <div className={'wg-userCardAvatarBackground skeleton'}>
                                        <Skeleton type={'avatar'}/>
                                    </div>
                                </div>
                                <div className={'wg-userCardInfo skeleton'}>
                                <span className={'wg-userCardName'}>
                                    <Skeleton/>
                                </span>
                                    <span className={'wg-userCardPlayers'}>
                                    <Skeleton/>
                                </span>
                                </div>
                            </div>
                        </Card>
                        <Card>
                            <div className={'wg-userCard'}>
                                <div className={'wg-userCardAvatar'}>
                                    <div className={'wg-userCardAvatarBackground skeleton'}>
                                        <Skeleton type={'avatar'}/>
                                    </div>
                                </div>
                                <div className={'wg-userCardInfo skeleton'}>
                                <span className={'wg-userCardName'}>
                                    <Skeleton/>
                                </span>
                                    <span className={'wg-userCardPlayers'}>
                                    <Skeleton />
                                </span>
                                </div>
                            </div>
                        </Card>
                        <Card>
                            <div className={'wg-userCard'}>
                                <div className={'wg-userCardAvatar'}>
                                    <div className={'wg-userCardAvatarBackground skeleton'}>
                                        <Skeleton type={'avatar'} />
                                    </div>
                                </div>
                                <div className={'wg-userCardInfo skeleton'}>
                                <span className={'wg-userCardName'}>
                                    <Skeleton />
                                </span>
                                    <span className={'wg-userCardPlayers'}>
                                    <Skeleton />
                                </span>
                                </div>
                            </div>
                        </Card>
                        <Card>
                            <div className={'wg-userCard'}>
                                <div className={'wg-userCardAvatar'}>
                                    <div className={'wg-userCardAvatarBackground skeleton'}>
                                        <Skeleton type={'avatar'} />
                                    </div>
                                </div>
                                <div className={'wg-userCardInfo skeleton'}>
                                <span className={'wg-userCardName'}>
                                    <Skeleton />
                                </span>
                                    <span className={'wg-userCardPlayers'}>
                                    <Skeleton />
                                </span>
                                </div>
                            </div>
                        </Card>
                    </div>
                    : data && data.length > 0 ?
                        data.map((user, i) => {

                            return (
                                <Card
                                    key={`user_card_${i}`}
                                    handleClick={() => toggleUserDetail(user)}
                                >
                                    {!loading ?
                                        <div className={'wg-userCard'}>
                                            <div className={'wg-userCardAvatar'}>
                                                <img src={user.photo ? user.photo : AvatarDefault} alt={'User Avatar'} />
                                            </div>
                                            <div className={'wg-userCardInfo'}>
                                                <span className={'wg-userCardName'}>{user.surname ? user.surname : ''} {user.name ? user.name : ''}</span>
                                                <span className={'wg-userCardPlayers'}>
                                                    <Badge
                                                        status={'neutral'}
                                                        label={user.user_role_description ? user.user_role_description : user.role_description ? user.role_description : '-'}
                                                        size={'xsmall'}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </Card>
                            );
                        })
                        : <NoData />

                }
            </div>

            <StaffData
                data={selectedUserData}
                listData={data}
                setListData={setData}
                visible={userDataVisible}
                setVisible={setUserDataVisible}
                deleteUser={deleteUser}
                userRoles={userRoles}
                userPermissionRoles={userPermissionRoles}
                teams={teams}
            />

            {exportDialogVisible ?
                <Dialog
                    title={'Esporta Giocatori'}
                    primaryButton={{
                        label: 'Esporta',
                        disabled: exportProcessing || !exportFormat,
                        loading: exportProcessing,
                        handleClick: exportData
                    }}
                    secondaryButton={{
                        label: 'Annulla',
                        handleClick: () => setExportDialogVisible(false)
                    }}
                    dialogVisible={exportDialogVisible}
                    setDialogVisible={closeExportDialog}
                    width={300}
                >
                    <Dropdown
                        id={'exportf'}
                        label={'Scegli il formato'}
                        items={[
                            {
                                key: 'pdf',
                                value: 'PDF'
                            },
                            {
                                key: 'excel',
                                value: 'Excel'
                            }
                        ]}
                        resetSelection={false}
                        filterable={false}
                        defaultValue={exportFormat}
                        placeholder={'Scegli'}
                        handleChange={selectedItem => setExportFormat(selectedItem.value)}
                    />
                </Dialog>
                :
                null
            }
        </React.Fragment>
    );
}