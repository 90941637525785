import React, {useEffect, useState} from 'react'

import DeviceDetector from '../../../utility/DeviceDetector/DeviceDetector'
import {Checkbox} from "../Checkbox/Checkbox";

export const Dropdown = (props) => {

    const {
        id= null,
        size=null,
        disabled= false,
        label= 'Label',
        placeholder = 'Seleziona',
        defaultValue = null,
        itemKey= null,
        items = [],
        resetSelection = true,
        filterable= true,
        required = false,
        selectable = false,
        fill = false,
        tabIndex = null,
        handleChange = null
    } = props;

    const [data, setData] = useState(defaultValue);
    const [open, setOpen] = useState(false);
    const [focus, setFocus] = useState(false);

    const [filteredItems, setFilteredItems] = useState([]);

    // Selection States
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleCloseDropdown = event => {

        if(open) {
            if(event) {
                if(selectable) {
                    if(event.target.id === 'wg-contextMenuFakeOverlay') {
                        setOpen(false);
                    }
                }else{
                    if(event.target.id !== `dropdown_filter_${id}`) {
                        setOpen(false);
                    }
                }
            }else{
                setOpen(false)
            }
        }else{
            setOpen(true)
        }
    }

    const handleClick = (event, item) => {

        if(item) {
            if(selectable) {
                if(event.target) {

                    const checked = event.target.querySelectorAll('input')[0].checked;

                    if(!checked) {
                        setSelectedOptions(oldSelectedOptions => [...oldSelectedOptions, item]);
                    }else{
                        setSelectedOptions(oldSelectedOptions => {
                            return [...oldSelectedOptions.filter(option => option.key !== item.key)]
                        });
                    }
                }

            }else{

                if(handleChange) {
                    handleChange(
                        {
                            key: itemKey,
                            value: item.value
                        }
                    )
                }
            }
        }
    }

    const isOptionChecked = idToCheck => {

        let isChecked = false;

        if(idToCheck) {
            if(selectedOptions.length > 0) {
                selectedOptions.map(selectedOption => {

                    if(idToCheck === selectedOption.key) {
                        isChecked = true;
                    }
                });
            }
        }

        return isChecked;
    }

    const resetData = () => {

        if(selectable) {
            setSelectedOptions([]);
            setOpen(false);
        }else{
            setData('');

            if(handleChange) {
                handleChange({
                    key: itemKey,
                    value: ''
                })
            }
        }
    }

    const filterDropdown = event => {

        if(event && open) {

            const {value} = event.target;

            let newFilteredItems = [];

            if(items.length > 0) {

                if(value) {
                    items.map(item => {

                        if(String(item.value).toLowerCase().includes(String(value).toLowerCase())) {
                            newFilteredItems.push(item);
                        }
                    })
                }else{
                    newFilteredItems = items;
                }

                setFilteredItems(newFilteredItems);
            }


        }
    }

    const openDropdownWithSpacebar = event => {

        if(event) {
            if(event.key === ' ') {
                setOpen(true);
            }
        }

        document.removeEventListener('keypress', openDropdownWithSpacebar)
    }

    useEffect(() => {
        setData(defaultValue)
    }, [defaultValue]);

    useEffect(() => {

        if(selectable) {
            if(selectedOptions.length > 0) {
                setData(selectedOptions.map(option => option.value).join(', '));
            }else{
                setData('')
            }
        }

        if(handleChange) {
            handleChange(
                {
                    key: itemKey,
                    value: selectedOptions.map(option => option.value).join(', ')
                }
            )
        }
    }, [selectedOptions]);

    useEffect(() => {

        if(focus) {
            document.addEventListener('keypress', openDropdownWithSpacebar)
        }else{
            document.removeEventListener('keypress', openDropdownWithSpacebar)
        }
    }, [focus]);

    useEffect(() => {

        if(open) {

            document.getElementById(`dropdown_field_${id}`).classList.add('focus');

            const filterFieldDOM = document.getElementById(`dropdown_filter_${id}`);

            if(filterFieldDOM) {

                if(!DeviceDetector.isMobile()) {
                    filterFieldDOM.focus();
                }

                filterFieldDOM.value = '';
            }

            setFilteredItems(items);

            if(selectable) {
                if(items.length > 0) {

                    const selectedOptionsTmp = [];

                    items.map(item => {


                        if(data.includes(item.value)) {
                            selectedOptionsTmp.push(item);
                        }
                    })

                    setSelectedOptions(selectedOptionsTmp);
                }
            }
        }else{
            document.getElementById(`dropdown_field_${id}`).classList.remove('focus');
        }
    }, [open]);

    return(
        <React.Fragment>
            <div className={`wg-dropdown ${disabled ? 'disabled' : ''} ${size ? size : ''}`}
                 onClick={e => handleCloseDropdown(e)}
                 style={fill ? {width: '100%'} : null}
            >
                {label ? <label htmlFor={id}>{label} {required ? <span className={'wg-required'}>*</span> : null}</label> : null}

                <div className={'wg-field'}>
                    <input
                        type={'text'}
                        id={`dropdown_field_${id}`}
                        name={id}
                        placeholder={placeholder}
                        tabIndex={tabIndex}
                        readOnly={true}
                        value={data ? data : ''}
                    />

                    <i className={'wi-chevron-down'} aria-hidden={'true'} />
                </div>

                {open ?
                    <React.Fragment>
                        <div className={`wg-dropdownList ${open ? 'open' : ''}`}>

                            {
                                filterable ?
                                    <div className={'wg-dropdown_filter'}>
                                        <i className={'wi-search'} aria-hidden={true}/>
                                        <input
                                            type={'text'}
                                            id={`dropdown_filter_${id}`}
                                            name={`dropdown_filter_${id}`}
                                            placeholder={'Filtra...'}
                                            onChange={e => filterDropdown(e)}
                                        />
                                    </div>
                                :
                                    null
                            }

                            {filteredItems && filteredItems.length > 0 ?

                                <ul>
                                    {resetSelection ?
                                        <li className={'wg-item'} onClick={resetData}>
                                            -
                                        </li>
                                        :
                                        null
                                    }
                                    {
                                        filteredItems.map(item => {

                                            return (
                                                <li
                                                    className={`wg-item ${String(item.value).toLowerCase() === String(data).toLowerCase() ? !selectable ? 'selected' : '' : ''}`}
                                                    key={`wg-item_${item.value}`}
                                                    onClick={(e) => handleClick(e, item)}
                                                >
                                                    {selectable ?
                                                        <Checkbox
                                                            name={`check_option_${item.key}_${item.value}`}
                                                            size={'small'}
                                                            label={false}
                                                            checked={() => isOptionChecked(item.key)}
                                                        />
                                                        : null
                                                    }
                                                    {item.value}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                :
                                null
                            }

                        </div>
                        <div className={'wg-contextMenuFakeOverlay'} id={'wg-contextMenuFakeOverlay'}
                             onClick={e => setOpen(false)}
                        />
                    </React.Fragment>
                    :
                    null
                }


            </div>
        </React.Fragment>
    );
}